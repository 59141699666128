<template>
  <Portal to="page-buttons">
    <template v-if="!props.showArchived">
      <v-btn
        color="primary"
        depressed
        size="small"
        class="mr-1"
        :text="t('syncExactOnlineAccounts')"
        @click="startSyncExactOnlineAccounts"
        :loading="isSyncingExactOnlineAccounts"
      />
    </template>
  </Portal>
  <v-row wrap>
    <v-col sm="12">
      <div style="clear: both"></div>
      <v-card flat>
        <v-card-title>
          <v-text-field
            append-icon="search"
            :label="t('search')"
            single-line
            hide-details
            v-model="search"
            variant="underlined"
          />
        </v-card-title>
      </v-card>
      <v-card flat border>
        <v-card-text class="d-block px-0 py-0">
          <v-data-table-server
            v-if="clientsList"
            :headers="headers"
            :items="clientsList"
            :items-length="clientsListTotalCount"
            :loading="clientsListLoading"
            :search="search"
            :items-per-page-text="rowsPerPageText"
            :items-per-page-options="rowsPerPageItems"
            v-model:items-per-page="pagination.rowsPerPage"
            v-model:page="pagination.page"
            @update:options="getClients"
            @update:sort-by="sortEvent"
          >
            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table  custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span class="cursor-pointer">{{ capitalcase(header.title) }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>

            <template v-slot:item="{ item }: {item: any}">
              <tr>
                <td>
                  <router-link class="custom-a" :to="{ name: 'ClientsEdit', params: { id: item.id }}">
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'ClientsEdit', params: { id: item.id }}">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'ClientsEdit', params: { id: item.id }}">
                    <template v-if="item.exact_online_account">
                      {{ item.exact_online_account.custom_description }}
                    </template>
                  </router-link>
                </td>
                <td class="text-right">
                  <v-btn v-if="!props.showArchived" variant="text" :to="{ name: 'ClientsEdit', params: { id: item.id }}" :title="$t('edit')" icon flat>
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn v-if="!props.showArchived" variant="text" v-on:click="deleteClient(item.id)" :title="$t('archive')" icon flat>
                    <v-icon color="red">mdi-archive</v-icon>
                  </v-btn>
                  <v-btn v-else variant="text" v-on:click="restoreClient(item.id)" :title="$t('restore')" icon flat>
                    <v-icon color="red">mdi-restore</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es'
import { datatableOptions } from '@/mixins/datatableOptions'
import { bulkActions } from '@/mixins/bulkActions'
import Say from '@/utils/Say'
import { Portal } from 'portal-vue'


import { defineComponent, ref, watch, computed, onBeforeMount } from 'vue'
import store from '@/store'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { capitalcase } from '@/utils/filters'
import sayErrorResponse from '@/mixins/sayErrorResponse'
import { unarchiveClients, syncExactOnlineAccounts } from '@/api/clients'

const props = defineProps({
  showArchived: {
    type: Boolean,
    default: false,
  },
})

defineComponent({
  Portal
})

const {t} = useI18n()
const route = useRoute()

const search= ref('')
const headers: any = ref([
  { title: 'Id', key: 'id', sortable: true , width: 65 },
  { title: t('name', 1), key: 'name', sortable: false, width:300 },
  { title: t('exactOnlineAccount', 1), key: 'exact_online_account', sortable: false },
  { key: '', sortable: false, width: 100 }
])
const clientsList = computed(() => store.getters.clientsList)
const clientsListLoading = computed(() => store.getters.clientsListLoading)
const clientsListTotalCount = computed(() => store.getters.clientsListTotalCount)
const isSyncingExactOnlineAccounts = ref(false)

const { archive, unarchive, status } = bulkActions()
const { rowsPerPageItems, rowsPerPageText, pagination } = datatableOptions()

watch(pagination, () => {
  debounce(function () {
    getClients()
  }, 300)
}, { deep: true})

// watch the search to call the api instead of using the client side search
watch(search, () => {
  if(search.value) {
    debounce(function () {
      getClients()
    }, 500)
  }
}, { deep: true})

onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', props.showArchived ? t('clientsArchived', 2) : t('client', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('client', 2) }
  ])
  // getClients()
})

const getClients = () => {
  store.dispatch('clients.fetch', Object.assign(
    pagination.value,
    {
      search: search.value,
      status: props.showArchived ? 'archived' : ''
    }
  ))
}

const deleteClient = (id: any) => {
  let confirmAction = confirm(t('clientArchiveConfirm'))
  if (confirmAction) {
    store.dispatch('clients.delete', id).then(() => {
      store.commit('cache.clients.setDirty')
      Say('success', t('clientArchiveSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}

const restoreClient = (id: any) => {
  let confirmAction = confirm(t('restoreClientConfirm'))
  if (confirmAction) {
    unarchiveClients({ ids: [id] }).then(() => {
      getClients()
      Say('success', t('restoreClientSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}

const sortEvent = (value: any) => {

  if(value.length === 0) {
    return
  }

  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false

  getClients()
}

const startSyncExactOnlineAccounts = () => {
  isSyncingExactOnlineAccounts.value = true
  syncExactOnlineAccounts().then(() => {
    isSyncingExactOnlineAccounts.value = false
    Say('success', t('exactOnlineAccountsSynced'))
  }).catch((err) => {
    isSyncingExactOnlineAccounts.value = false
    sayErrorResponse(err)
  })
}
</script>
