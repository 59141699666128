<template>
  <v-row>
    <v-col sm="6" >
      <v-form v-on:submit.prevent="submitForm()" v-model="valid" ref="form">
        <v-card flat :border="true">
          <v-card-text>
            <v-text-field
              :label="t('name', 1)"
              v-model="branch.name"
              required
              variant="underlined"
            ></v-text-field>

            <v-text-field
              :label="t('exactOnlineCostUnit', 1)"
              v-model="branch.cost_unit"
              required
              variant="underlined"
            ></v-text-field>

            <v-text-field
              :label="t('address', 1)"
              v-model="branch.address"
              variant="underlined"
            ></v-text-field>

            <v-text-field
              :label="t('postcode', 1)"
              v-model="branch.postal_code"
              mask="#### AA"
              variant="underlined"
            ></v-text-field>

            <v-text-field
              :label="t('city', 1)"
              v-model="branch.city"
              variant="underlined"
            ></v-text-field>

          </v-card-text>
          <v-card-actions>
            <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading" :disabled="!valid">
              {{ buttonText }}
            </v-btn>
            <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  branch: {
    type: Object,
    required: true
  },
  submit: {
    type: Function,
    required: true
  },
  onInput: {
    type: Function,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: 'Save'
  }
})

const valid= ref(false)
const form = ref()

const {t} = useI18n()

const submitForm = () => {
  props.submit()
}
</script>

<style scoped lang="scss">
table.table {
  tbody {
    tr {
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
</style>
