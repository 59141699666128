<template>
  <v-row>
    <v-col class="py-2" cols="6">
      <v-card flat border class="overflow-scroll">
        <v-card-text class="py-5">
          <template v-if="loading || !workInProgressReport">
            <p class="my-1">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="16"
                width="3"
                class="mr-2"
              ></v-progress-circular>
              <i>{{ t("workInProgressIsBeingLoaded") }}</i>
            </p>
          </template>
          <template v-else>
            <v-row>
              <v-col class="py-2" cols="6">
                <b>{{ t("workInProgressReportId") }}:</b>
              </v-col>
              <v-col class="py-2" cols="6">
                {{ workInProgressReport.id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-2" cols="6">
                <b>{{ t("lastDateIncluded") }}:</b>
              </v-col>
              <v-col class="py-2" cols="6">
                {{ formatDate(workInProgressReport.last_date_included) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-2" cols="6">
                <b>{{ t("status") }}:</b>
              </v-col>
              <v-col class="py-2" cols="6">
                {{ workInProgressReport.status }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-2" cols="6">
                <b>{{ t("generatingStartedAt") }}:</b>
              </v-col>
              <v-col class="py-2" cols="6">
                {{ workInProgressReport.started_at }}
              </v-col>
            </v-row>

            <template v-if="workInProgressReport.completed_at">
              <v-row>
                <v-col class="py-2" cols="6">
                  <b>{{ t("generatingCompletedAt") }}:</b>
                </v-col>
                <v-col class="py-2" cols="6">
                  {{ workInProgressReport.completed_at }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-2" cols="6">
                  <b>{{ t("totalExclVat") }}:</b>
                </v-col>
                <v-col class="py-2" cols="6">
                  &euro;
                  {{ formatCurrency(workInProgressReport.total_excl_vat) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-2" cols="6"> </v-col>
                <v-col class="py-2" cols="6">
                  <span
                    @click="downloadExcelExportOfWorkInProgressReportAction"
                    class="link"
                  >
                    <b>{{ t("downloadExcelExport") }}</b>
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <template v-if="workInProgressReport.failed_at">
                <v-row>
                  <v-col class="py-2" cols="6">
                    <b>{{ t("generatingFailedAt") }}:</b>
                  </v-col>
                  <v-col class="py-2" cols="6">
                    {{ workInProgressReport.failed_at }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    {{ workInProgressReport.failure_message }}
                  </v-col>
                </v-row>
              </template>
            </template>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="py-2" cols="6">
      <div
        v-if="
          !loading && workInProgressReport && workInProgressReport.completed_at
        "
        class="table-legend-wrapper"
      >
        <table class="table-legend">
          <tr>
            <td colspan="2">
              <b>
                {{ t("legendStatuses") }}
              </b>
            </td>
          </tr>
          <tr
            v-for="status in [
              'CHECKOUT_PERIOD_CONCEPT',
              'CHECKOUT_PERIOD_CREATED',
              'PRO_FORMA_CONCEPT',
              'PRO_FORMA_PENDING',
              'PRO_FORMA_APPROVED',
            ]"
          >
            <td>
              {{ t("workInProgressReportLineStatuses." + status + ".status") }}
            </td>
            <td>
              <i>
                {{
                  t(
                    "workInProgressReportLineStatuses." +
                      status +
                      ".description"
                  )
                }}
              </i>
            </td>
          </tr>
        </table>
      </div>
    </v-col>
  </v-row>

  <v-card
    v-if="workInProgressReport && workInProgressReport.completed_at"
    flat
    border
    class="overflow-scroll mt-4"
  >
    <v-data-table-server
      :headers="headers"
      :items="workInProgressReport.lines"
      items-per-page="99999999"
      :items-length="workInProgressReport.lines.length"
      hide-default-footer
      @update:sort-by="sortEvent"
    >
      <template v-slot:headers="{ isSorted, getSortIcon, toggleSort }">
        <tr>
          <template v-for="header in headers" :key="header.key">
            <th
              class="custom_header_table custom-bg"
              :class="{ 'text-right': header.align === 'end' }"
              :style="{ 'text-align: right': header.align === 'end' }"
            >
              <span
                class="d-flex align-center"
                @click="header.sortable ? toggleSort(header) : ''"
              >
                <span :class="{ 'cursor-pointer': header.sortable }">{{
                  header.title
                }}</span>
                <template v-if="isSorted(header)">
                  <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                </template>
              </span>
            </th>
          </template>
        </tr>
      </template>
      <template v-slot:item="{ item }: { item: any }">
        <tr>
          <td>{{ item.branch_name }}</td>
          <td>{{ item.client_name }}</td>
          <td>
            <a @click="gotoProjectTab('CostOverview', item.job_id, $event)" href="#" class="link">{{ item.job_name }}</a>
          </td>
          <td>{{ formatDate(item.checkout_period_start_date) }}</td>
          <td>{{ formatDate(item.checkout_period_end_date) }}</td>
          <td>
            {{
              t("workInProgressReportLineStatuses." + item.status + ".status")
            }}
          </td>
          <td>{{ formatDate(item.status_date) }}</td>
          <td class="text-right">
            &euro; {{ formatCurrency(item.total_excl_vat) }}
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import sayErrorResponse from "@/mixins/sayErrorResponse";
import {
  fetchWorkInProgressReport,
  downloadExcelExportOfWorkInProgressReport,
} from "@/api/workInProgressReports";
import { datatableOptions } from "@/mixins/datatableOptions";
import useProjectTabSelector from '@/composable/useProjectTabSelector';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { projectTabSelector } = useProjectTabSelector()

const loading = ref(false);

const workInProgressReport = ref<any>(null);

const headers: any = computed(() => [
  { title: t("branch"), key: "branch", sortable: false },
  { title: t("client"), key: "client", sortable: false },
  { title: t("job"), key: "job", sortable: false },
  { title: t("dateStart"), key: "date_start", sortable: false },
  { title: t("dateEnd"), key: "date_end", sortable: false },
  { title: t("status"), key: "status", sortable: false },
  { title: t("statusSince"), key: "status_date", sortable: false },
  { title: t("totalExclVat"), key: "total_excl_vat", sortable: false },
]);

onBeforeMount(async () => {
  // UI
  store.commit("app.setPageTitle", t("viewWorkInProgressReport"));
  store.commit("app.setBreadcrumbs", [
    {
      text: t("workInProgressReport", 2),
      routeName: "WorkInProgressReportsList",
    },
    { text: t("viewReport") },
  ]);
});

const { pagination } = datatableOptions();

const getWorkInProgressReport = async () => {
  try {
    const response = await fetchWorkInProgressReport(route.params.id, {
      options: pagination.value,
    });
    if (!response.data.data.status) {
      loading.value = false;
      sayErrorResponse(response);
    } else {
      loading.value = false;
      workInProgressReport.value = response.data.data;
    }
  } catch (error) {
    loading.value = false;
    sayErrorResponse(error);
  }
};

onMounted(async () => {
  getWorkInProgressReport();
});

const sortEvent = (value: any) => {
  if (value.length === 0) {
    return;
  }

  pagination.value.sortBy = value[0].key;
  pagination.value.descending = value[0].order === "desc" ? true : false;

  getWorkInProgressReport();
};

const formatDate = (date: string) => {
  if (!date) {
    return "";
  }
  const [year, month, day] = date.split("-");
  return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
};

const formatCurrency = (value: number) =>
  Number(value).toLocaleString("nl-NL", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const downloadStarted = ref(false);

const downloadExcelExportOfWorkInProgressReportAction = async () => {
  if (downloadStarted.value) {
    return;
  }
  downloadStarted.value = true;
  try {
    const response = await downloadExcelExportOfWorkInProgressReport(
      route.params.id
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      "work-in-progress-report-" +
        route.params.id +
        "-last-date-included-" +
        workInProgressReport.value.last_date_included +
        ".xlsx"
    );
    document.body.appendChild(link);
    link.click();
    downloadStarted.value = false;
  } catch (error) {
    sayErrorResponse(error);
    downloadStarted.value = false;
  }
};

const gotoProjectTab = (tabName: string, jobId: any, e: any) => {
  // use router to navigate to the correct tab
  e.preventDefault();
  projectTabSelector.value = tabName
  router.push({ name: 'JobsView', params: { id: jobId }})
}
</script>

<style scoped>
.text-normal {
  font-weight: normal;
}

.bg-white {
  background-color: #fff;
}

.table-legend-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.table-legend {
  width: 100%;
  border-collapse: collapse;
}
.table-legend td {
  padding: 0.75rem 1rem;
  font-size: 0.9em;
}
.table-legend tr:not(:first-child) td {
  font-size: 0.8em;
}
.table-legend tr:not(:last-child) td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

td {
  white-space: nowrap;
}

.link {
  cursor: pointer;
  color: rgb(30, 136, 229);
  text-decoration: underline;
  font-weight: 500;
}
</style>
