export default {
  dashboard: "Dashboard",
  calenderView: "Kalender",
  mapView: "Kaart",
  searchView: "Zoek",
  mapOnlyShowsProjectLocations:
    "Deze kaart toont alleen projectlocaties (geen Van Amerongen of verhuur leverancier locaties)",
  // Projects
  project: "Project | Projecten",
  projectNumber: "Projectnummer | Projectnummers",
  projectCreate: "Nieuw project",
  projectEdit: "Bewerk project",
  projectLead: "Projectleider",
  projectCreateSuccess: "Het project is gemaakt.",
  projectEditSuccess: "Het project is bijgewerkt.",
  projectEditError:
    "Het project kan niet worden geopend voor bewerking. Probeer het later nog eens.",
  projectDeleteConfirm: "Weet je zeker dat je dit project wilt verwijderen?",
  projectDeleteSuccess: "Het project is verwijderd.",
  projectWarningRentalStart:
    "Het is verplicht een huurstart of een huureinde in te stellen",
  equipmentDuration: "Huurtijd",
  unknownLocation: "Onbekende locatie",
  arrived: "Gearriveerd",
  departed: "Vertrokken",
  ongoing: "Op locatie",
  finished: "Retour",
  retrievedBeforeArriving: "Nooit afgeleverd!",
  clientRef: "Werknr.",
  contact: "Contact | Contacten",

  // Work Orders
  workOrder: "Werkorder | Werkorders",
  workOrderCreate: "Nieuwe werkorder",
  workOrderEdit: "Bewerk werkorder",
  assignedEmployees: "Toegewezen medewerkers",
  fileRemoveConfirm:
    "Weet je zeker dat je dit bestand wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.",
  fileRemoveSuccess: "Het bestand is verwijderd.",
  fileCommentsSuccess: "Notitie is opgeslagen.",
  workOrderResetConfirm:
    "Weet je zeker dat je deze werkorder wilt herstellen naar de beginwaarden?",
  workOrderCreateSuccess: "De werkorder is aangemaakt en kan nu worden bewerkt",
  workOrderEditSuccess: "De werkorder is bijgewerkt",
  workOrderEditError:
    "De werkorder kan niet worden geopend voor bewerking. Probeer het later nog eens.",
  workOrderDeleteConfirm:
    "Weet je zeker dat je deze werkorder wilt verwijderen?",
  workOrderDeleteSuccess: "De werkorder is verwijderd.",
  workOrderDuplicateSuccess: "De werkorder is gedupliceerd.",
  workOrderApprovedSuccess: "De werkorder is goedgekeurd.",
  workOrderLinkedToOtherJobSuccess:
    "De werkorder is gekoppeld aan een ander project.",
  workOrderUpdatedTotalHoursSuccess: "De totale uren zijn bijgewerkt.",
  workOrderStatuses: {
    DRAFT: "Concept",
    PLANNED: "Gepland",
    ONGOING: "Bezig",
    COMPLETED: "Voltooid",
    CLOSED: "Afgerond",
    CANCELLED: "Geannuleerd",
    APPROVED: "Goedgekeurd",
  },
  workOrderStatusesOngoing: {
    STARTED: "Gestart",
    DEPARTED: "Vertrokken",
    ARRIVED: "Aangekomen",
    RETURNING: "Terugkeren",
    RETURNED: "Teruggekeerd",
  },
  workOrderSelectEmployee: "Selecteer een medewerker",
  projectName: "Projectnaam",
  jobName: "Project",
  clientReference: "Klantreferentie",
  useOldInvoicingMethod: "Gebruik de oude factureringsmethode.",
  jobUsesOldInvoicingMethod:
    "Dit project gebruikt de oude factureringsmethode.",
  revisions: "Revisies",
  noRevisions: "Nog geen revisies",
  noFilesUploaded: "Nog geen bestanden geüpload",
  noAdditionalUploadedJobFilesAvailable:
    "Geen aanvullende geüploade project bestanden beschikbaar.",
  addComment: "Voeg opmerking toe",
  clientResponsible: "Uitvoerder",
  locationsVa: "Vestiging",
  relatedWorkOrders: "Gerelateerde werkorders",
  newEmployeeHoursEntry: "Medewerker uren invoer",
  workOrderId: "Werkorder id",
  workOrderForEquipmentMovementBetweenBranchesAndRentalSuppliers:
    "Werkorder voor materiaal verplaatsingen tussen vestigingen en verhuur leveranciers",
  approveWorkOrder: "Werkorder goedkeuren",
  approveWorkOrderText:
    "De uren en verplaatste materialen (inclusief huurperioden) zijn correct.",
  approveWorkOrderEquipmentMovementsText:
    "De verplaatste materialen zijn correct.",
  workOrderIsNoCompletedWarning: "Deze werkorder is nog niet voltooid.",
  workOrderIsNoCompletedWarningBeforeApproval:
    "Deze werkorder is nog niet voltooid. Als je deze goedkeurt, kan de monteur geen wijzigingen meer aanbrengen, inclusief het plaatsen en ophalen van materiaal via deze werkorder.",
  workOrderApproved: "De werkorder is goedgekeurd.",
  canceledWorkOrderCanNotBeApproved:
    "Een geannuleerde werkorder kan niet worden goedgekeurd.",
  correctEquipmentOfWorkOrderForEquipmentMovements:
    "Om het materiaal van de werkorder voor materiaalverplaatsingen te corrigeren, ga naar `Voorraad` in het hoofdmenu om materiaal tussen locaties en verhuur leveranciers te verplaatsen.",
  viewAllEquipmentOfThisJob: "Bekijk al het materiaal van dit project",
  inTheEquipmentTabOfTheJob: "in het materiaal tabblad van het project",
  viewAllWorkordersOfThisJob: "Bekijk alle werkorders van dit project",
  inTheWorkordersTabOfTheJob: "in het werkorders tabblad van het project",
  totalAmountRegistered: "Totaal aantal geregistreerd",
  hoursForInvoice: "Uren voor facturatie",
  notYetFilledIn: "Nog niet ingevuld",
  fillInTotalHoursBeforeApproving:
    "Vul de totale uren voor facturatie in voordat je de werkorder goedkeurt.",
  noHoursWillBeChargedForThisWorkOrder:
    "Er worden geen uren in rekening gebracht voor deze werkorder.",

  // Jobs
  job: "Project | Projecten",
  jobResult: "Werkorder van Job | Werkorders van Job",
  jobCreate: "Nieuw project",
  jobCreated: "Nieuw project aangemaakt",
  jobAfterCreate: "Wil je een werkorder aanmaken voor dit project?",
  jobEdit: "Bewerk project",
  jobResetConfirm:
    "Weet je zeker dat je dit project wilt herstellen naar de beginwaarden?",
  jobCreateSuccess: "Het project is aangemaakt en kan nu worden bewerkt",
  jobEditSuccess: "Het project is bijgewerkt",
  jobDeleteConfirm: "Weet je zeker dat je dit project wilt verwijderen?",
  jobDeleteSuccess: "Het project is verwijderd.",
  jobDuplicateSuccess: "Het project is gedupliceerd.",
  jobStatuses: {
    DRAFT: "Concept",
    PLANNED: "Gepland",
    ONGOING: "Bezig",
    COMPLETED: "Voltooid",
    CLOSED: "Afgerond",
    CANCELLED: "Geannuleerd",
  },
  jobStatusesOngoing: {
    STARTED: "Gestart",
    DEPARTED: "Vertrokken",
    ARRIVED: "Aangekomen",
    RETURNING: "Terugkeren",
    RETURNED: "Teruggekeerd",
  },
  jobSelectEmployee: "Selecteer een medewerker",
  linkToOtherJob: "Koppel aan ander project",
  jobsWithStatusPlannedAndOngoingAndSameClientAndBranch:
    "Projecten met status `Gepland` en `Bezig` van dezelfde opdrachtgever en dezelfde vestiging",
  noJobsFound: "Geen projecten gevonden",

  // Tabs
  JobTabOverview: "Overzicht",
  JobTabWorkorders: "Werkorders",
  JobTabEquipment: "Materiaal",
  JobTabMap: "Kaart",
  JobTabDocuments: "Documenten",
  JobTabNotes: "Notities",
  JobTabInvoices: "Facturen",
  JobTabCostOverview: "Kostenoverzicht",

  // Branches
  branch: "Vestiging | Vestigingen",
  branchCreate: "Nieuwe vestiging",
  branchCreateSuccess: "De vestiging is aangemaakt",
  branchEdit: "Vestiging bewerken",
  branchEditSuccess: "De vestiging is opgeslagen",
  branchDeleteConfirm: "Weet je zeker dat je deze vestiging wilt verwijderen?",
  branchDeleteSuccess: "De vestiging is verwijderd",

  // Clients
  client: "Opdrachtgever | Opdrachtgevers",
  clientCreate: "Nieuwe opdrachtgever",
  clientEdit: "Opdrachtgever bewerken",
  clientEditSuccess: "De opdrachtgever is opgeslagen",
  clientArchiveConfirm:
    "Weet je zeker dat je deze opdrachtgever wilt archiveren?",
  clientArchiveSuccess: "De opdrachtgever is gearchiveerd",
  clientContactDeleteConfirm:
    "Weet je zeker dat je deze contact wilt verwijderen?",
  clientContactDeleteSuccess: "De contact is verwijderd",
  clientsArchived: "Ge-archiveerde opdrachtgevers",
  restoreClientConfirm:
    "Weet je zeker dat je deze opdrachtgever wilt herstellen?",
  restoreClientSuccess: "De opdrachtgever is hersteld",
  exactOnlineAccount: "Exact Online account",
  syncExactOnlineAccounts: "Exact Online accounts synchroniseren",
  exactOnlineAccountsSynced: "Exact Online accounts zijn gesynchroniseerd",
  clientIsNotLinkedToAnExactOnlineAccount:
    "Deze opdrachtgever is niet gekoppeld aan een Exact Online account.",
  clickHereToLinkClientToAnExactOnlineAccount:
    "Klik hier om de opdrachtgever te koppelen aan een Exact Online account",
  clientHasIncompleteAddress: "Deze opdrachtgever heeft een onvolledig adres.",
  clickHereToCompleteTheAddress: "Klik hier om het adres aan te vullen",
  exactOnlineAccountHasNoEmailAddress:
    "Het Exact Online account '{name}' heeft geen e-mailadres.",
  pleaseCompleteItInExactOnline: "Vul het e-mailadres aan in Exact Online",
  whenCompleted: "Zodra dit is aangevuld",
  goToThisPageToSynchronizeChanges:
    "ga naar deze pagina om de wijzigingen te synchroniseren",

  // Employees
  // To discuss workflow in this page, perhaps these will either be removed or have other items added to it
  employee: "Medewerker | Medewerkers",
  employeeArchived: "Ge-archiveerde medewerker | Ge-archiveerde medewerkers",
  employeeCreate: "Nieuwe medewerker",
  employeeCreateSuccess: "De medewerker is aangemaakt",
  employeeEdit: "Medewerker bewerken",
  employeeEditSuccess: "De mederwerker is opgeslagen",
  personalDetails: "Persoonlijke kenmerken",
  employmentDetails: "Medewerker kenmerken",
  userDetails: "Gebruiker kenmerken",
  functionDescription: "Functieomschrijving",
  defaultWorkRole: "Standaard werkrol",
  ownEmployee: "Van Amerongen medewerker",
  employmentStart: "Datum in dienst",
  employmentEnd: "Datum uit dienst",
  isPreviousEmployee: "Deze medewerker is niet langer werkzaam bij dit bedrijf",
  employeeDeleteConfirm:
    "Weet je zeker dat je deze medewerker wilt verwijderen?",
  employeeDeleteSuccess: "De medewerker is verwijderd",
  contractType: "Contractvorm",
  contractEnd: "Einde contract",
  weekOverview: "Weekoverzicht",
  dayWeekStat: "Dag week staat",
  employeeTimeoffDeleteConfirm: "Weet je zeker dat je deze wilt verwijderen?",
  employeeSelect: "Selecteer een werknemer",
  employeeCanBeProjectLeader: "Kan projectleider zijn",
  employeeCanBeWorkOrderResponsible: "Kan werkorderverantwoordelijke zijn",

  deleteEmployeeConfirm: "Weet je zeker dat je deze medewerker wil archiveren?",
  restoreEmployeeConfirm:
    "Weet je zeker dat je deze medewerker wil herstellen?",
  deleteEmployeeSuccess: "De medewerker is gearchiveerd.",
  restoreEmployeeSuccess: "De medewerker is hersteld.",

  // Equipment
  equipment: "Materiaal | Materiaal",
  equipmentAll: "Alle materialen",
  equipmentUnique: "Unieke materialen",
  equipmentBulk: "Bulk materialen",
  equipmentSuggested: "Voorgestelde materialen",
  equipmentMoved: "Verplaatste materialen",
  equipmentChoose: "Selecteer..",
  equipmentCreate: "Nieuw materiaal",
  equipmentDeleteConfirm:
    "Weet je zeker dat je dit materiaal wilt verwijderen?",
  equipmentDeleteSuccess: "Het materiaal is verwijderd.",
  equipmentEditSuccess: "Het materiaal is opgeslagen.",
  equipmentCreateSuccess: "Het materiaal is aangemaakt",
  equipmentImageWarning:
    "Waarschuwing, de huidige afbeelding zal worden overschreven.",
  equipmentKeepTrackOfEquipmentItem: "Voorraad bijhouden",
  equipmentParent: "Hoofditem",
  report: "Rapport | Rapporten",
  noEquipment: "Geen materiaal",
  Material: "Materiaal",
  delivered: "Afgeleverd",
  retrieved: "Opgehaald",
  equipmentRateType: "Wekelijks | 123W | Eenmalig",
  fromLocation: "Vanaf locatie",
  fromLocationRequired: "Vanaf locatie is verplicht",

  // Composite Equipment
  equipmentCompositeShort: "Samengesteld",
  equipmentComposite: "Samengesteld materiaal | Samengesteld materiaal",
  equipmentCompositeAll: "Samengestelde materialen",
  equipmentCompositeWith: "Onderdelen",

  // Users
  user: "Gebruiker | Gebruikers",
  userCreate: "Nieuwe gebruiker",
  userEdit: "Gebruiker bewerken",
  userSelect: "Kies gebruiker",
  userCreateSuccess: "De gebruiker is aangemaakt en kan nu bewerkt worden",
  userEditSuccess: "De gebruiker is opgeslagen",
  userDeleteConfirm: "Weet je zeker dat je deze gebruiker wilt verwijderen?",
  userDeleteSuccess: "De gebruiker is verwijderd",
  associatedUser: "Toegewezen gebruiker",
  passwordHint: "Laat dit veld leeg om het huidig wachtwoord te behouden",
  pincodeHint: "Laat dit veld leeg om de huidige pincode te behouden",

  // Equipment Card
  equipmentCardWarningZeroAmount:
    'Deze rij wordt verwijderd als je opslaat met een aantal van "0".',
  equipmentCardWarningRentalStart:
    "Het is verplicht een huurstart of een huureinde in te vullen.",
  equipmentCardNoEquipment: "Deze werkorder heeft geen materiaal",
  equipmentCardSelect: "Selecteer materiaal",
  equipmentCardSetDateAndTime: "Stel datum en tijd in",
  startDateMustBeBeforeEndDate: "Startdatum moet voor einddatum zijn",
  endDateMustBeAfterStartDate: "Einddatum moet na startdatum zijn",
  equipmentCardWarningZeroAmounts:
    'Er zijn rijen met een aantal van "0". Deze worden verwijderd als je opslaat. Pas de aantallen aan of verwijder de rij(en).',
  equipmentCardSuccess: "De wijzigingen zijn opgeslagen",

  // Roles
  role: "Rol | Rollen",
  roleManage: "Rollen beheren",
  rolePermissionsSaved: "Rol permissies opgeslagen",

  // Work Roles
  workRole: "Werkrol | Werkrollen",

  // Reports
  totalHours: "Totaal uren",
  subtotalHours: "Subtotaal uren",
  workedHours: "Gewerkte uren",
  leaveAndHolidays: "Verlof en vakanties",
  summary: "Samenvatting",
  piketHours: "Piket uren",

  // EquipmentItem
  allEquipmentItems: "Alle voorraad items",
  equipmentItem: "Voorraad item | Voorraad items",
  equipmentItemCreate: "Nieuw voorraad item",
  equipmentItemDeleteConfirm:
    "Weet je zeker dat je dit voorraad item wilt verwijderen?",
  equipmentItemEdit: "Bewerk Voorraad item",
  equipmentItemCreateSuccess: "Voorraad item aangemaakt",
  equipmentItemEditSuccess:
    "Voorraad item opgeslagen | Voorraad item opgeslagen",
  equipmentItemView: "Voorraad item bekijken",
  locations: "Locaties",
  currentLocation: "Huidige locatie",
  since: "sinds",
  uniqueFeature: "Uniek kenmerk",
  dateAcquired: "Aanschafdatum",
  statusDate: "Status datum",
  updateStatus: "Status bijwerken",
  AtLocation: "Op locatie",
  ownEquipment: "Eigen materiaal",
  rentedEquipment: "Extern ingehuurd materiaal",
  rentedFrom: "Ingehuurd van",
  createdBySystem: "Systeem gemaakt",
  printOnlyPossibleForGroupedViewWithLocation:
    "Printen is alleen mogelijk voor gegroepeerde weergave met locatie",
  itemsAreOnlyClickableInViewWithIndividualItems:
    "Items zijn alleen klikbaar in de weergave met individuele items",
  wasEquipmentAtLocation: "Materiaal op locatie",
  isAttachedToJob: "Gekoppeld aan project",

  // Equipment Item Locations
  allEquipmentItemLocations: "Alle voorraad item locaties",
  equipmentItemLocation: "Voorraad item locatie | Voorraad item locaties",
  equipmentItemLocationCreate: "Nieuwe voorraad item locatie",
  equipmentItemLocationDeleteConfirm:
    "Weet je zeker dat je deze voorraad item locatie wilt verwijderen?",
  equipmentItemLocationEdit: "Bewerk voorraad item locatie",
  equipmentItemLocationCreateSuccess: "De voorraad item locatie is aangemaakt",
  equipmentItemLocationEditSuccess: "De voorraad item locatie is opgeslagen",
  addEquipmentItemLocation: "Locatie toevoegen",
  moveToOtherLocation: "Verplaats naar andere locatie",
  vaLocation: "Van Amerongen vestiging",
  placedAt: "Geplaatst op",
  placedByWorkOrder: "Geplaatst via werkorder",
  collectedAt: "Opgehaald op",
  collectedByWorkOrder: "Opgehaald via werkorder",
  rentalSuppliersLocation: "Locatie verhuur leverancier",
  clientLocation: "Klant locatie",
  compositeWith: "Samengesteld met",
  compositeAs: "Samengesteld als",
  isRentalSupplied: "Verhuur geleverd",
  itemsAlreadyAtLocation: "{count} material(en) al op de nieuwe locatie.",
  itemsAlreadyAtLocationAll:
    "Alle materialen staan al op de geselecteerde locatie.",
  itemsAlreadyAtLocationSingle:
    "Het materiaal bevindt zich al op de geselecteerde locatie.",
  itemsAlreadyAtLocationMove: "Verplaats resterende items.",
  inventory: "Voorraad",
  pickupFromLocation: "Ophalen van locatie",
  bringToLocation: "Brengen naar locatie",

  // Rental Suppliers
  rentalSuppliers: "Verhuur leveranciers",
  allRentalSuppliers: "Alle verhuur leveranciers",
  rentalSupplierCreate: "Nieuwe verhuur leverancier",
  rentalSupplier: "Verhuur leverancier | Verhuur leveranciers",
  rentalSupplierEdit: "Bewerk verhuur leverancier",
  rentalSupplierEditSuccess: "De verhuur leverancier is opgeslagen",
  rentalSupplierCreateSuccess: "De verhuur leverancier is aangemaakt",
  rentalSupplierDeleteConfirm:
    "Weet je zeker dat je deze verhuur leverancier wilt verwijderen?",
  rentalSupplierBrannch: "Vestiging verhuur leverancier",

  // Notes
  notes: "Notities",

  // Search
  searchResultsNoResults:
    "Geen resultaten gevonden. Zoek in de linker zijbalk voor eits anders.",

  // Days of week:
  monday: "Maandag",
  tuesday: "Dinsdag",
  wednesday: "Woensdag",
  thursday: "Donderdag",
  friday: "Vrijdag",
  saturday: "Zaterdag",
  sunday: "Zondag",

  //UI Document
  image: "Afbeelding | Afbeeldingen",
  document: "Document | Documenten",
  documentUpload: "Afbeeldingen/Documenten uploaden",
  documentDeleteConfirmation:
    "Weet u zeker dat u dit document wilt verwijderen? | Weet u zeker dat u deze documenten wilt verwijderen?",
  documentDeletedSuccess:
    "Het document is verwijderd. | De documenten zijn verwijderd.",

  //Job -> Notes
  pleaseFillInTitle: "Vul de titel in",
  pleaseFillInContent: "Vul de inhoud in",
  addNote: "Notitie toevoegen",

  jobNoteCreateSuccess: "Het notitie is gemaakt.",
  jobNoteEditSuccess: "Het notitie is bijgewerkt.",
  jobNoteDeleteConfirmation: "Weet u zeker dat u dit notitie wilt verwijderen?",
  jobNoteDeletedSuccess: "Het notitie is verwijderd.",

  JobTabAdditionalCosts: "Extra kosten",
  dialogAddCost: "Kosten toevoegen",
  dialogEditCost: "Kosten bewerken",
  costCreated: "De kosten zijn toegevoegd",
  costUpdated: "De kosten zijn bijgewerkt",
  costDeleted: "De kosten zijn verwijderd",
  pricePerUnitExclVat: "Prijs per eenheid excl. Btw",
  amountExclVat: "Bedrag excl. Btw",
  additionalCostCannotBeEdited:
    "Deze kosten kunnen niet worden bewerkt omdat ze zijn opgenomen in een pro forma.",
  additionalCostCannotBeDeleted:
    "Deze kosten kunnen niet worden verwijderd omdat ze opgenomen in van een pro forma.",
  addAdditionalCost: "Extra kosten toevoegen",
  vatLowExcl: "BTW laag, excl",
  vatHighExcl: "BTW hoog, excl",
  vatReverseCharged: "BTW verlegd",
  noVat: "Geen BTW",

  // Cost Overview
  costOverviewDescription:
    "In dit kostenoverzicht zijn materiaal met een huurperiode (minimaal huurstart) en - indien van toepassing - de bijbehorende beletteringskosten en programmeer/datakosten, uren van goedgekeurde werkorders en extra kosten opgenomen.",
  conceptCheckoutPeriod: "Concept afrekenperiode",
  noDataAvailable: "Geen gegevens beschikbaar",
  proposedProFormaLines: "Voorgestelde pro forma regels",
  total: "Totaal",
  lettering: "Belettering",
  programmingAndData: "Programmering/data",
  additionalCosts: "Extra kosten",
  dateOrPeriod: "Datum / Periode",
  createCheckoutPeriod: "Afrekenperiode aanmaken",
  updateStartDate: "Startdatum bewerken",
  updateEndDate: "Einddatum bewerken",
  today: "vandaag",
  upToAndIncludingAbbreviation: "t/m",
  removeCheckoutPeriod: "Afrekenperiode verwijderen",
  removeCheckoutPeriodExplanation:
    "Hiermee worden alleen de start- en einddatum van de afrekenperiode verwijderd. De bijbehorende kosten van materiaalhuur, belettering, uren en extra kosten blijven behouden.",
  endDateUpdatedSuccessfully: "De einddatum is succesvol bijgewerkt.",
  startDateUpdatedSuccessfully: "De startdatum is succesvol bijgewerkt.",
  checkoutPeriodRemovedSuccessfully:
    "De afrekenperiode is succesvol verwijderd.",
  overviewIsBeingLoaded: "Overzicht wordt geladen...",
  areYouSureYouWantToRemoveTheCheckoutPeriod:
    "Weet je zeker dat je de afrekenperiode wilt verwijderen?",
  tooltipStartDateOfCheckoutPeriodHasCosts:
    "De startdatum van deze afrekenperiode heeft kosten.",
  tooltipFollowingCheckoutAndProForma:
    "Deze afrekenperiode heeft een opvolgende afrekenperiode en heeft één of meer pro forma's.",
  tooltipFollowingCheckoutPeriod:
    "Deze afrekenperiode heeft een opvolgende afrekenperiode.",
  tooltipProForma: "Deze afrekenperiode heeft één of meer pro forma's.",
  tooltipFirstDatesAreEqual:
    "De startdatum van de eerste afrekenperiode is gelijk aan de eerste datum met kosten.",
  tooltipFirstDatesAreEqualAndProForma:
    "De startdatum van de eerste afrekenperiode is gelijk aan de eerste datum met kosten en heeft één of meer pro forma's.",
  tooltipStartAndEndDateCannotBeInTheFuture:
    "De start- en einddatum kunnen niet vandaag zijn of in de toekomst liggen.",
  firstDatesAreNotEqual:
    "De startdatum van de eerste afrekenperiode is niet gelijk aan de eerste datum met kosten",
  lastDatesAreNotEqual:
    "De einddatum van de laatste afrekenperiode is niet gelijk aan de laatste datum met kosten",
  workOrderInProFormaPeriod:
    "This Work order falls within a checkout period with a pro forma and cannot be edited.",
  pleaseEnterAValidEndDate: "Voer een geldige einddatum in",
  pleaseEnterAValidStartDate: "Voer een geldige startdatum in",

  // Invoices
  proForma: "Pro forma",
  proFormas: "Pro forma's",
  createProforma: "Pro forma aanmaken",
  rejectProforma: "Pro forma afkeuren",
  approveProforma: "Pro forma goedkeuren",
  fromDate: "Vanaf datum",
  upToAndIncludingDate: "Tot en met datum",
  proFormaRejectionLabel: "Pro forma afkeuren",
  proFormaApprovalLabel: "Pro forma goedkeuren",
  proFormaCompleteAllFields: "Vul het hele veld in.",
  proFormaCreated: "De pro forma is gemaakt",
  proFormaEdited: "De pro forma is bijgewerkt",
  proFormaDeleted: "De pro forma is verwijderd",
  invoiceDate: "Factuurdatum",
  invoiceTotalExcl: "Totaal excl. Btw",
  checkingForConceptCheckoutPeriods:
    "Controleren of er concept afrekenperioden zijn",
  conceptCheckoutPeriodInsctructions:
    "Deze concept afrekenperiode kan in het kostenoverzicht worden omgezet naar een afrekenperiode",
  futureConceptCheckoutPeriodInsctructions:
    "Deze concept afrekenperiode is vandaag en/of in de toekomst en kan nog niet worden omgezet naar een afrekenperiode",
  noCheckoutPeriodsFound: "Geen (concept) afrekenperioden gevonden",
  proFormaRejectedSuccessfully: "De pro forma is afgekeurd",
  proFormaApprovedSuccessfully: "De pro forma is goedgekeurd",
  yourReference: "Uw referentie",
  jobDescription: "Projectomschrijving",
  vat: "Btw",
  discountPercentage: "Korting percentage",
  discount: "Korting",
  totalExclVat: "Totaal excl. Btw",
  subTotalBeforeDiscount: "Subtotaal voor korting",
  discountOn: "korting op",
  deleteProForma: "Pro forma verwijderen",
  confirmDeleteProForma:
    "Weet je zeker dat je deze pro forma wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.",
  deletedProFormaSuccesfully: "De pro forma is verwijderd",
  finalizeProForma: "Definitief maken en verzenden",
  finalize: "Definitief maken",
  confirmFinalizeProForma:
    "Weet je zeker dat je deze pro forma definitief wilt maken?",
  proFormaWillBeEmailedTo: "De pro forma wordt per e-mail verzonden naar",
  clientResponsibleNotSetOrEmailMissing:
    "Er is geen contactpersoon ingevuld voor de opdrachtgever (uitvoerder), of het ontbreekt aan een e-mailadres. Dit kan worden toegevoegd door het project te bewerken.",
  editJob: "Bewerk project",
  finalizedProFormaSuccesfully:
    "De pro forma is definitief gemaakt en verzonden",
  openPreview: "Voorbeeld openen",
  backToOverview: "Terug naar overzicht",
  bulkProFormLinesOneRequired: "Vul minimaal één veld in.",
  updateProFormaLine: "Regel bijwerken",
  createProFormaLine: "Regel toevoegen",
  deleteProFormaLine: "Regel verwijderen",
  confirmDeleteProFormaLine:
    "Weet je zeker dat je deze regel wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.",
  confirmConvertToSalesInvoice:
    "Weet je zeker dat je deze pro forma wilt omzetten naar een verkoopfactuur? Hiermee wordt een verkoopfactuur aangemaakt in Exact Online en per e-mail verzonden naar de opdrachtgever.",
  confirmApproveProForma:
    "Weet je zeker dat je deze pro forma wilt goedkeuren?",
  confirmRejectProForma:
    "Weet je zeker dat je deze pro forma wilt afkeuren? Hiermee wordt een kopie van de pro forma als concept opgeslagen.",
  createdProFormaLineSuccessfully: "De regel is toegevoegd",
  updatedProFormaLineSuccessfully: "De regel is bijgewerkt",
  updatedProFormaLinesSuccessfully: "De lijnen zijn bijgewerkt",
  deletedProFormaLineSuccessfully: "De regel is verwijderd",
  attachPdfFiles: "PDF-bestanden bijvoegen",
  clientReferencePlaceholder: "Bijv. Inkoopordernummer: 12345",
  addressedTo: "Geadresseerd aan",
  invoiceWillBeEmailedTo: "De factuur wordt per e-mail verzonden naar",
  creditNoteWillBeEmailedTo: "De creditnota wordt per e-mail verzonden naar",
  toCredit: "Crediteren",
  toInvoice: "Factureren",
  creditSalesInvoice: "Verkoopfactuur crediteren",
  confirmCreditSalesInvoice:
    "Weet je zeker dat je deze verkoopfactuur wilt crediteren? Hiermee worden alle regels en het totaal bedrag van de verkoopfactuur gecrediteerd. De creditnota wordt aangemaakt in Exact Online en per e-mail verzonden naar de opdrachtgever.",
  creditSalesInvoiceLabel: "Verkoopfactuur crediteren",
  convertProFormaToSalesInvoice: "Pro forma omzetten naar verkoopfactuur",
  convertToSalesInvoice: "Omzetten naar verkoopfactuur",
  salesInvoiceBasedOnProForma: "Verkoopfactuur gebaseerd op pro forma",
  createSalesInvoice: "Verkoopfactuur aanmaken",
  invoiceToCredit: "Te crediteren factuur",
  exactOnlineVatCode: "Exact Online Btw-code",
  leaveFieldsEmptyToKeepTheCurrentValue: "Laat velden leeg om de huidige waarde te behouden",
  exactOnlineArticle: "Exact Online artikel",
  basedOnSelectedInternalArticles: "Gebaseerd op geselecteerde interne artikelen",

  // ProForma statuses
  proFormaStatuses: {
    CONCEPT: "CONCEPT",
    PENDING: "IN AFWACHTING",
    APPROVED: "GOEDGEKEURD",
    REJECTED: "AFGEKEURD",
    INVOICED: "GEFACTUREERD",
    INVOICING_PROCESSED_EXTERNALLY: "FACTURATIE EXTERN VERWERKT",
    null: "",
  },

  // Email statuses
  emailStatuses: {
    ACCEPTED: "GEACCEPTEERD",
    REJECTED: "AFGEWEZEN",
    SENT: "VERZONDEN",
    PENDING: "IN AFWACHTING",
    FAILED: "MISLUKT",
    PARTIALLY_FAILED: "GEDEELTELIJK MISLUKT",
    DELIVERED: "AFGELEVERD",
    OPENED: "GEOPEND",
    CLICKED: "GEKLIKT",
    COMPLAINED: "SPAMKLACHT",
    null: "",
  },

  // WorkInProgressReportLine statuses
  workInProgressReportLineStatuses: {
    CHECKOUT_PERIOD_CONCEPT: {
      status: "AFREKENPERIODE - CONCEPT",
      description: "Deze afrekenperiode is automatisch vastgesteld op basis van project data.",
    },
    CHECKOUT_PERIOD_CREATED: {
      status: "AFREKENPERIODE - AANGEMAAKT",
      description:
        "Deze afrekenperiode is aangemaakt, maar er is nog geen pro forma aangemaakt.",
    },
    PRO_FORMA_CONCEPT: {
      status: "PRO FORMA - CONCEPT",
      description:
        "Er is een concept pro forma aangemaakt, maar deze is nog niet definitief gemaakt.",
    },
    PRO_FORMA_PENDING: {
      status: "PRO FORMA - IN AFWACHTING",
      description:
        "Deze pro forma is definitief gemaakt, maar nog niet goedgekeurd.",
    },
    PRO_FORMA_APPROVED: {
      status: "PRO FORMA - GOEDGEKEURD",
      description:
        "Deze pro forma is goedgekeurd, maar nog niet omgezet naar een verkoopfactuur.",
    },
  },

  // WorkInProgressReports
  reports: "Rapporten",
  workInProgress: "Onderhanden werk",
  lastDateIncluded: "Laatste datum inbegrepen",
  workInProgressReport: "Onderhanden werk rapport",
  workInProgressReports: "Onderhanden werk rapporten",
  failureMessage: "Foutmelding",
  viewWorkInProgressReport: "Onderhanden werk rapport bekijken",
  viewReport: "Rapport bekijken",
  workInProgressReportId: "Onderhanden werk rapport ID",
  workInProgressIsBeingLoaded:
    "Onderhanden werk wordt geladen... Even geduld a.u.b.",

  // Common words
  id: "Id",
  hours: "Uren",
  hourlyRateExclVat: "Uurtarief excl. BTW",
  toConfirm: "Bevestigen",
  pleaseConfirm: "Bevestiging vereist",
  title: "Titel",
  content: "Inhoud",
  cancel: "Annuleren",
  ok: "Ok",
  set: "Stel in",
  reset: "Herstellen",
  create: "Aanmaken",
  save: "Opslaan",
  edit: "Bewerken",
  delete: "Verwijderen",
  archive: "Archiveren",
  restore: "Herstellen",
  newTab: "Nieuw tabblad",
  see: "Weergeven",
  showMore: "Toon meer",
  manage: "Beheren",
  search: "Zoeken",
  areaInMeters: "Gebied (m²)",
  name: "Naam | Namen",
  username: "Gebruikersnaam",
  email: "Email | Emails",
  emailAddress: "E-mailadres",
  password: "Wachtwoord",
  pincode: "Pincode",
  phone: "Telefoon",
  date: "Datum",
  time: "Tijd",
  start: "Start",
  dateStart: "Startdatum",
  timeStart: "Starttijd",
  timePause: "Pauzetijd",
  end: "Einde",
  dateEnd: "Einddatum",
  timeEnd: "Eindtijd",
  dateAndTime: "Datum en tijdstip",
  description: "Omschrijving",
  company: "Bedrijf",
  leadBy: "Geleid door",
  locationFrom: "Vertreklocatie",
  locationFromHint: "Leave empty for Van Amerongen HQ",
  locationDefault: "Standaardlocatie",
  locationCreate: "Nieuw locatie",
  inTransitFrom: "Op doorreis van",
  atLocation: "Op locatie",
  noLocations: "Geen locaties",
  noEquipmentAtLocation: "Geen materiaal op locatie",
  address: "Adres",
  intersection: "Kruispunt",
  custom: "Aangepast",
  responsible: "Verantwoordelijke",
  settings: "Instellingen",
  logIn: "Inloggen",
  logOut: "Uitloggen",
  filename: "Bestandsnaam",
  size: "Grootte",
  noFiles: "Nog geen bestanden!",
  permission: "Permissie | Permissies",
  openGoogleMaps: "Openen in Google Maps",
  onlyAvailableUsers: "Beschikbare gebruikers",
  comment: "Opmerking | Opmerkingen",
  amount: "Bedrag",
  quantity: "Aantal",
  add: "Toevoegen",
  remove: "Verwijderen",
  year: "Jaar",
  week: "Week",
  hour: "Uur | Uren",
  pause: "Pauze",
  general: "Algemeen",
  reason: "Reden",
  category: "Categorie",
  bulk: "Bulk",
  type: "Type",
  rowsPerPage: "Rijen per pagina",
  all: "Alle",
  allResults: "Naar alle resultaten",
  or: "Of",
  street: "Straat",
  streetNumber: "Nummer",
  postcode: "Postcode",
  city: "Plaats",
  exactOnlineCostUnit: "Exact Online kostendrager",
  function: "Functie",
  instructions: "Instructies",
  rate: "Tarief",
  rateType: "Tarieftype",
  letteringCost: "Beletteringskosten",
  letteringInternalArticleNumber: "Beletteringskosten - Intern artikelnummer",
  letteringInternalArticle: "Belettering intern artikel",
  programmingAndDataCosts: "Programmeer/datakosten",
  programmingAndDataInternalArticleNumber: "Programmeer/datakosten - Intern artikelnummer",
  programmingAndDataInternalArticle: "Programmeer/datakosten intern artikel",
  meta: "Meta",
  status: "Status",
  statusSince: "Status sinds",
  generatingStartedAt: "Genereren gestart op",
  generatingFailedAt: "Genereren mislukt op",
  generatingCompletedAt: "Genereren voltooid op",
  actions: "Acties",
  from: "Van",
  to: "Tot",
  to2: "Naar",
  nothing: "Niets",
  details: "Kenmerken",
  location: "Locatie | Locaties",
  loaded: "Geladen",
  file: "Bestand | Bestanden",
  duration: "Duur",
  extraWorkers: "Extra werkers",
  workers: "Werkers",
  hoursWorked: "Uren werk",
  confirm: "Weet je zeker?",
  lastUpdated: "Laatst bijgewerkt",
  noItems: "Geen items",
  noDate: "Geen datum",
  at: "Om",
  on: "Op",
  by: "Door",
  deleted: "Verwijderd",
  alphabetic: "Alfabetisch",
  changed: "Aangepast",
  changedRecently: "Recent aangepast",
  rentalStart: "Huurstart",
  rentalEnd: "Huureinde",
  print: "Print",
  now: "Nu",
  confirmDeleteItem: "Wil je echt dit item verwijderen?",
  cantUndo: "Deze actie kan niet ongedaan gemaakt worden.",
  yes: "Ja",
  no: "Nee",
  unknown: "Onbekend",
  duplicate: "Dupliceer",
  duplicatingNotPossibleBecauseOfLocationWithoutCoordinates:
    "Dupliceren is niet mogelijk vanwege locatie zonder coördinaten",
  useTabToContinue: 'Gebruik de "TAB"-toets om naar het volgende veld te gaan.',
  info: "Info",
  locationDataThatWillBeSaved: "Deze locatiegegevens worden opgeslagen:",
  additionalExplanation: "Extra toelichting",
  addLocation: "Voeg locatie toe",
  latitude: "Breedtegraad",
  longitude: "Lengtegraad",
  streetOrRoad: "Straat (of weg)",
  pleaseFillInAValidIntersection:
    "Vul een geldig kruispunt in (twee straten of wegen gescheiden door een &-teken)",
  pleaseFillInCity: "Vul een plaats in",
  pleaseFillInLatitudeAndLongitude: "Vul een breedtegraad en lengtegraad in",
  pleaseFillInAStreet: "Vul een straat (of weg) in",
  pleaseFillInANameOrStreet: "Vul een naam of een straat (of weg) in",
  pleaseFillInAName: "Vul een naam in",
  similarExistingLocationsOfThisClient:
    "Vergelijkbare bestaande locaties van deze opdrachtgever:",
  notLinkedToWorkOrder: "Niet gekoppeld aan een werkorder",
  clickOnExistingLocationToLinkItToWorkOrder:
    "Klik op een bestaande locatie om deze te koppelen aan de werkorder.",
  changeTypeToIntersection: 'Wil je het type wijzigen naar "Kruispunt"?',
  createdAt: "Aangemaakt op",
  userId: "Gebruiker id",
  userName: "Naam gebruiker",
  inTransitSince: "Onderweg sinds",
  inTransitUntil: "Onderweg tot",
  createdBy: "Aangemaakt door",
  showAs: "Weergave",
  grouped: "Gegroepeerd",
  individual: "Individueel",
  unique: "Uniek",
  composite: "Samengesteld",
  restrictionsBecauseEquipmentIsAlreadyInEquipmentItems:
    "Bewerkingen zijn beperkt omdat het materiaal al is toegevoegd aan de voorraad items of onderdeel is van een samengesteld materiaal.",
  currentVariantInDatabase: "Huidige variant in database",
  consumable: "Verbruiksmateriaal",
  notApplicableAbbr: "n.v.t.",
  amountOfMonthsBack: "Aantal maanden terug",
  month: "maand",
  months: "maanden",
  approve: "Goedkeuren",
  reject: "Afkeuren",
  loading: "Laden",
  endDateBeforeStartDate:
    "De einddatum moet gelijk zijn aan of na de startdatum",

  Equipment: "Materiaal",
  PlaceEquipment: "Materiaal plaatsen",
  PlaceEquipmentAtLocation: "Plaats op locatie",
  PlacedEquipmentAt: "Geplaatst op",
  CollectedEquipmentAt: "Opgehaald op",
  PlacedByWorkOrder: "Geplaatst via werkorder",
  CollectedByWorkOrder: "Opgehaald via werkorder",
  RentEquipmentStart: "Huur start",
  RentEquipmentEnd: "Huur einde",
  RentalSupplierName: "Verhuurleverancier",
  SystemCreated: "Systeem gemaakt",
  CollectEquipment: "Materiaal ophalen",
  CollectEquipmentSingle: "Materiaal ophalen",
  CollectEquipmentGroup: "Materiaal ophalen",
  Amount: "Aantal",
  leaveEmptyForToday: "Laat leeg voor 'Vandaag'",
  dialogRentalStartAndEnd: "Wijzigen huur start en einde",
  RentalStartAndEnd: "Huur",
  MarkAsCollected: "Ophalen",
  dialogBulkRentalStart: "Bulk wijzigen huur start",
  dialogBulkRentalEnd: "Bulk wijzigen huur einde",
  dialogBulk: "Bulk wijzigen",
  warningMixedSupplierTypes:
    "Er is materiaal van meerdere leveranciers geselecteerd. Alleen materiaal dat geschikt is voor de geselecteerde locatie wordt verplaatst. Alle overige materiaal blijft op de huidige locatie.",
  warningSomeItemsAlreadyCollected:
    "Een deel van het materiaal was al opgehaald. Alleen materiaal dat nog niet is opgehaald, wordt verplaatst. Alle overige materiaal blijft op de huidige locatie.",
  warningAllItemsAlreadyCollected: "Alle materiaal was al verzameld.",
  warningOnlyPartialRentalDateUpdatePossible:
    "Sommige materialen kunnen niet op de geselecteerde datum worden ingesteld.",
  warningMixedSupplierTypesMove: "Verplaats geschikte materiaal",
  warningPartialUpdate: "Datum instellen voor geschikte materialen.",
  validationErrorRentDate:
    "De datum is niet geldig voor de geselecteerde materialen.",
  modify: "Wijzigen",
  pleaseFillInMaterial: "Vul het materiaal in",
  pleaseFillInLocation: "Vul de locatie in",
  pleaseFillInAmount: "Vul het bedrag in",
  pleaseFillInQuantity: "Vul het aantal in",
  pleaseFillInWorkOrder: "Vul het werkorder in",
  amountIsMoreThanAvailable:
    "Het door u ingevoerde aantal is groter dan wat beschikbaar is",
  amountIsMoreThanAllowed: "Je kunt slechts {max} items tegelijk plaatsen",
  pleaseFillInRentalStart: "U moet een startdatum voor de huur instellen.\n",
  pleaseFillInRentalEnd: "U moet een einddatum voor de huur instellen.\n",
  warningStockWillBeAddedToLocation:
    "Er zal extra materiaal aan de voorraad worden toegevoegd.",
  dialogMessagePlaced: "Geplaatst op locatie",
  filterByWorkOrder: "Filter op werkorder",
  items: "Items",
  close: "Sluiten",
  locationOrWorkOrder: "Locatie / Werkorder",
  overviewGeneratedAt: "Overzicht gegenereerd op",
  checkoutPeriod: "Afrekenperiode",
  activities: "Werkzaamheden",
  fullPeriod: "Volledige periode",
  internalArticleNumber: "Intern artikelnummer",
  primaryInternalArticle: "Primair intern artikel",
  secondaryInternalArticle: "Secundair intern artikel",
  primaryInternalArticleNumber: "Primair intern artikelnummer",
  secondaryInternalArticleNumber: "Secundair intern artikelnummer",
  period: "Periode",
  number: "Nummer",
  emailStatus: "E-mail status",
  sentTo: "Aan",
  cc: "CC",
  bcc: "BCC",
  replyTo: "Beantwoorden naar",
  sentAt: "Verzonden op",
  attachments: "Bijlage(n)",
  sentVia: "Verzonden via",
  messageId: "Bericht ID",
  noEmailsFound: "Geen e-mails gevonden.",
  seeDetailedStatusHistoryBelowEmail:
    "Zie gedetailleerde status geschiedenis onder de e-mail",
  statusHistory: "Status geschiedenis",
  htmlVersion: "HTML versie",
  plainTextVersion: "Plain text versie",
  sendEmail: "E-mail verzenden",
  resendEmail: "E-mail opnieuw verzenden",
  sendEmailFor: "E-mail verzenden voor",
  resendEmailFor: "E-mail opnieuw verzenden voor",
  subject: "Onderwerp",
  send: "Verzenden",
  emailSent: "E-mail verzonden",
  emailIsBeingSent: "E-mail wordt verzonden",
  resfreshData: "Ververs data",
  getEventsFromMailgun: "Status ophalen vanuit Mailgun (handmatig)",
  eventsUpdated: "Status geschiedenis bijgewerkt",
  isUpdatingEvents: "Status geschiedenis wordt opgehaald en bijgewerkt",
  eventsOnlyAvailableForMailgun:
    "Status geschiedenis is alleen beschikbaar voor e-mails die via Mailgun zijn verzonden.",
  openedStatusesDependsOnRecipientsEmailClient:
    "De status 'GEOPEND' is afhankelijk van de e-mailclient van de ontvanger. Deze status is niet altijd betrouwbaar.",
  noEmailAddressAvailable: "Geen e-mailadres beschikbaar",
  legendStatuses: "Legenda - Statussen",
  downloadExcelExport: "Download Excel export",
  updateInBulk: "Bulk wijzigen",

  EquipmentSuppliedBy: "Geleverd door",
  OwnEquipment: "VAF-Infra",

  SingleItems: "Individueel",
  GroupedItems: "Gegroepeerd",

  allEquipmentAtLocation: "Alle materialen zijn op locatie",
  equipmentPartiallyCollected: "Materialen zijn deels opgehaald",
  allEquipmentCollected: "Alle materialen zijn opgehaald",

  // Validation
  validationNameRequired: "Naam is verplicht",
  validationPostcodeRequired: "Postcode is verplicht",
  validationNameShorter: "De naam is te lang",
  validationEmailRequired: "E-mail is verplicht",
  validationEmailValid: "E-mail is ongeldig",
  validationPasswordRequired: "Wachtwoord is verplicht",
  validationPasswordMinLength: "Wachtwoord moet minstens 6 karakters lang zijn",
  validationPincodeExactlyFour: "Pincode moet uit vier cijfers bestaan",
  validationPincodeNumbersOnly: "Pincode mag alleen cijfers bevatten",
  validationPhoneRequired: "Telefoonnummer is verplicht",
  validationPhoneShorter: "Telefoonnummer is te lang",
  validationPhoneNumbersOnly: "Telefoonnummer mag alleen uit cijfers bestaan",
  validationRequired: "Dit veld is verplicht",
  validationShorter: "Dit veld is te lang",
  equipmentCardWarningNotEnoughStock:
    "Er is niet genoeg voorraad voor dit materiaal",
  // consider: 'Overweeg alstublieft {0}, met beschikbaarheid: {1}',
  consider: "Overweeg alstublieft {0}",
  orSiblings: "of {0}",
  fieldRequired: "Invoer is verplicht",
  concept: "concept",
  open: "Openen",
  download: "Downloaden",
  openPdf: "Open PDF",
  downloadPdf: "Download PDF",
  emailWillBeSentToLoggedInUser:
    "E-mail wordt verzonden naar de ingelogde gebruiker ({emailAddress}), ondanks de geselecteerde ontvanger(s).",

  // Navigation
  allJobs: "Alle Projecten",
  allWorkOrders: "Alle Werkorders",
  allBranches: "Alle Vestigingen",
  allClients: "Alle Opdrachtgevers",
  allEmployees: "Alle Medewerkers",
  allArchivedEmployees: "Ge-archiveerde Medewerkers",
  allEquipments: "Alle Materiaal",
  allUsers: "Alle Gebruikers",
};
