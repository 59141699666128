<template>
  <v-row wrap>
    <v-col sm="6" >
      <v-card flat>
        <v-card-text class="px-0">
          <table class="table">
            <thead>
              <tr>
                <th class="custom_header_table custom-bg" style="width: 65px;">Id</th>
                <th class="custom_header_table custom-bg">{{ $t('name') }}</th>
                <th class="custom_header_table custom-bg"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="role in rolesList" :key="`${role.id}`">
                <td>
                  <router-link class="custom-a" :to="{ name: 'RolesEdit', params: { id: role.id }}">
                    {{ role.id }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'RolesEdit', params: { id: role.id }}">
                    {{ role.name }}
                  </router-link>
                </td>
                <td class="text-end">
                  <v-btn variant="text" :to="{ name: 'RolesEdit', params: { id: role.id }}" icon small flat >
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed,  onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex'

const { t } = useI18n()
const store = useStore()

// Computed
const rolesList = computed(() => store.getters['rolesList'])

// Created Hook
onBeforeMount(() => {
  store.dispatch('roles.fetch')

  // UI
  store.commit('app.setPageTitle', t('role', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('user', 2), routeName: 'Users' },
    { text: t('role', 2) }
  ])
})
</script>

<style scoped>
  table.table {
    width: 100%;
    border-spacing: 0;
    background-color: #fff;
    font-size: 10.5px;

    tr {
      transition-duration: 0.28s;
      transition-property: 'box-shadow', 'opacity', 'background', 'height';
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      &:nth-child(even) {
        background: #f5f5f5;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
      }

      td, th {
        text-align: left;
      }
      td {
        padding: 0 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        &:first-child {
          border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
        &:last-child {
          border-right: 1px solid rgba(0, 0, 0, 0.12);
        }
      }

      th {
        padding: 12px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        &:first-child {
          border-top-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
        }
      }
      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-bottom-right-radius: 4px;
          }
        }
      }

    }
  }

</style>
