import axios from "axios";

export function fetchWorkInProgressReports() {
  return axios.get("/reports/work-in-progress");
}

export function fetchWorkInProgressReport(id: number | string, params: any) {
  return axios.get("/reports/work-in-progress/" + id, { params });
}

export function downloadExcelExportOfWorkInProgressReport(id: number | string) {
  return axios.get("/reports/work-in-progress/" + id + "/excel", {
    responseType: "blob",
  });
}
