<script setup lang="ts">
import { useI18n } from "vue-i18n";
import {
  getProForma,
  deleteProForma,
  finalizeProForma,
  getProFormaAsPdf,
  deleteProFormaLine,
  createProFormaLine,
  updateProFormaLine,
  bulkUpdateProFormaLines as bulkUpdateLines,
  getProFormaAsHtml
} from '@/api/proFormas';
import { inject, ref, onBeforeMount, computed } from 'vue';
import SayErrorResponse from '@/mixins/sayErrorResponse';
import Say from '@/utils/Say';
import Datepicker from "@/components/UI/Datepicker.vue";
import branchStore from "@/store/branches";
import { fetchInternalArticles } from '@/api/internalArticles';

const props = defineProps({
    proFormaId: {
        type: Number,
        required: true
    },
})

const {t} = useI18n()
const emit = defineEmits([
    'cancelEditingProForma',
    'deletedProForma',
    'finalizedProForma',
    'updatedProForma',
])

const job = inject('job', null)

const proForma = ref([])
const proFormaLine = ref([])
const formSubmitted = ref(false)

const bulkUpdateSelectedItems = ref([])
const bulkUpdateDialogVisible = ref(false)

const isFinalizeProFormaDialogVisible = ref(false)
const isDeleteProFormaDialogVisible = ref(false)

const isProFormaLineDialogVisible = ref(false)
const isDeleteProFormaLineDialogVisible = ref(false)

const emailsAreSentToLoggedInUser = inject('emailsAreSentToLoggedInUser', false)
const userEmailAddress = inject('userEmailAddress', false)

const internalArticles = ref([])

const headers: any = computed(() => {
    return [
        { title: t('primaryInternalArticle'), key: 'primary_internal_article_number', sortable:false },
        { title: t('secondaryInternalArticle'), key: 'secondary_internal_article_number', sortable:false },
        { title: t('description'), key: 'description', sortable:false },
        { title: t('dateOrPeriod'), key: 'date_or_period', sortable:false },
        { title: t('duration'), key: 'duration', sortable:false },
        { title: t('exactOnlineArticle'), key: 'exact_online_item', sortable:false, },
        { title: t('exactOnlineCostUnit'), key: 'cost_unit', sortable:false, },
        { title: t('exactOnlineVatCode'), key: 'vat_code', sortable:false },
        { title: t('quantity'), key: 'quantity', sortable:false, align: 'end' },
        { title: t('pricePerUnitExclVat'), key: 'price_per_unit_excl_vat', sortable:false, align: 'end' },
        { title: t('subTotalBeforeDiscount'), key: 'total_before_discount_excl_vat', sortable:false, align: 'end' },
        { title: t('discountPercentage'), key: 'discount_percentage', sortable:false, align: 'end' },
        { title: t('discount'), key: 'discount_excl_vat', sortable:false, align: 'end' },
        { title: t('totalExclVat'), key: 'total_excl_vat', sortable:false, align: 'end' },
        { title: '', key: 'actions', sortable:false, width: '90px' },
    ]
})

onBeforeMount(async () => {
    await branchStore.dispatch('fetchBranches')
    loadProForma()
    loadInternalArticles()
})

const loadInternalArticles = async () => {
  try {
    const response = await fetchInternalArticles();
    if(!response.data) {
      SayErrorResponse(response);
    } else {
        internalArticles.value = response.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const loadProForma = async () => {
  try {
    const response = await getProForma(props.proFormaId);
    if(!response.data.data.id) {
      SayErrorResponse(response);
    } else {
        proForma.value = response.data.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const previewHtml = ref();
const hasPreviewHtml = ref(false);
const openProForma = async () => {
  if (proForma.value.status === 'CONCEPT') {
    try {
      previewHtml.value = await getProFormaAsHtml(proForma.value.id);
      hasPreviewHtml.value = true;
    } catch (error) {
      SayErrorResponse(error);
    }
    return;
  }

  const response = await getProFormaAsPdf(proForma.value.id);
  try {
    // Create a URL from the Blob (binary PDF data)
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  } catch (error) {
    SayErrorResponse(error);
  }
}

const formatDate = (date: string) => {
  if (!date) {return ''}
  const [year, month, day] = date.split('-');
  return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
}

const formatNumber = (value: number, decimals: number) => {
  if (value === null) {
    return '';
  }
  value = Number(value)
  // check if value is a rounded number
  if (value % 1 === 0) {
    return value
  } else {
    return value.toFixed(decimals).replace('.', ',')
  }
}


const openFinalizeProFormaDialog = async (item: any) => {
    isFinalizeProFormaDialogVisible.value = true;
}
const closeFinalizeProFormaDialog = () => {
    isFinalizeProFormaDialogVisible.value = false;
}
const finalizeProFormaAction = async () => {
    if(formSubmitted.value) {
        Say('warning', t('pleaseWait'));
        return;
    }
    formSubmitted.value = true
    try {
        await finalizeProForma(props.proFormaId);
        setTimeout(() => {
            Say('success', t('finalizedProFormaSuccesfully'));
            emit('finalizedProForma');
            formSubmitted.value = false
        }, 2000);
    } catch (error) {
        formSubmitted.value = false
        SayErrorResponse(error);
    }
}


const openDeleteProFormaDialog = async (item: any) => {
    isDeleteProFormaDialogVisible.value = true;
}
const closeDeleteProFormaDialog = () => {
    isDeleteProFormaDialogVisible.value = false;
}
const deleteProFormaAction = async () => {
    if(formSubmitted.value) {
        Say('warning', t('pleaseWait'));
        return;
    }
    formSubmitted.value = true
    try {
        await deleteProForma(props.proFormaId);
        Say('success', t('deletedProFormaSuccesfully'));
        emit('deletedProForma');
        formSubmitted.value = false
    } catch (error) {
        formSubmitted.value = false
        SayErrorResponse(error);
    }
}


const openDeleteProFormaLineDialog = async (item: any) => {
    proFormaLine.value = item;
    isDeleteProFormaLineDialogVisible.value = true;
}
const closeDeleteProFormaLineDialog = () => {
    proFormaLine.value = [];
    isDeleteProFormaLineDialogVisible.value = false;
}
const deleteProFormaLineAction = () => {
    if(formSubmitted.value) {
        Say('warning', t('pleaseWait'));
        return;
    }
    formSubmitted.value = true
    deleteProFormaLine(proForma.value.id, proFormaLine.value.id)
        .then((response: { data: { error: any; }; }) => {
            if (response.data.error) {
                SayErrorResponse(response);
            } else {
                closeDeleteProFormaLineDialog();
                loadProForma();
                emit('updatedProForma');
                Say('success', t('deletedProFormaLineSuccessfully'))
            }
        })
        .catch((error: any) => {
            SayErrorResponse(error);
        })
        .finally(() => {
            formSubmitted.value = false
        });
}
const openProFormaItemsBulkUpdateDialog = async () => {
  bulkUpdateDialogVisible.value = true
}
const closeProFormaItemsBulkUpdateDialog = async () => {
  bulkUpdateDialogVisible.value = false
}
const bulkUpdateProFormaLines = () => {
  bulkForm.value.validate();
  const formData = Object.fromEntries(
    Object.entries(bulkFormValues.value).filter(([, value]) => value !== null)
  );
  if (Object.keys(formData).length === 0) {
    return;
  }

  formSubmitted.value = true
  bulkUpdateLines(proForma.value.id, bulkUpdateSelectedItems.value, formData)
    .then(() => {
      bulkUpdateDialogVisible.value = false
      formSubmitted.value = false
      bulkForm.value.reset();
      loadProForma();
      emit('updatedProForma');
      Say('success', t('deletedProFormaLineSuccessfully'))
    })
    .catch(err => {
      formSubmitted.value = false
      SayErrorResponse(err)
    });
}
const openProFormaLineDialog = async (item: any) => {
    proFormaLine.value = item;
    startDateInputValidation.value = null
    endDateInputValidation.value = null

    formValues.value = {
        primary_internal_article_number: item?.primary_internal_article_number,
        secondary_internal_article_number: item?.secondary_internal_article_number,
        cost_unit: item?.cost_unit,
        vat_code: item?.vat_code,
        description: item?.description,
        start_date: item?.start_date,
        end_date: item?.end_date,
        quantity: item.quantity ? formatNumber(item?.quantity, 2) : 0,
        discount_percentage: item.discount_percentage ? formatNumber(item?.discount_percentage, 2) : 0,
        duration: item?.duration,
        price_per_unit_excl_vat: item?.price_per_unit_excl_vat,
    }
    if (!item.id) {
        formValues.value.cost_unit = dropDownOptions.value.cost_unit.find(
            costUnit => costUnit.value == branchStore.getters.branches.find(branch => branch.id == job.value.branch_id).cost_unit
        ).value
        formValues.value.vat_code = dropDownOptions.value.vat_code[0].value
    } else {
        formValues.value.cost_unit = dropDownOptions.value.cost_unit.find(costUnit => costUnit.value == item.cost_unit)?.value
        formValues.value.vat_code = dropDownOptions.value.vat_code.find(vat => vat.value == item.vat_code)?.value
    }
    isProFormaLineDialogVisible.value = true;
}
const closeProFormaLineDialog = () => {
    proFormaLine.value = [];
    isProFormaLineDialogVisible.value = false;
}
const createOrUpdateProFormaLine = () => {
    if(formSubmitted.value) {
        Say('warning', t('pleaseWait'));
        return;
    }
    formSubmitted.value = true

    form.value.validate();

    if (!isValidDate(formValues.value.start_date) || formValues.value.start_date == 'Invalid date') {
        startDateInputValidation.value = true
    } else {
        startDateInputValidation.value = null
    }

    if (!isValidDate(formValues.value.end_date) || formValues.value.end_date == 'Invalid date') {
        endDateInputValidation.value = true
    } else {
        endDateInputValidation.value = null
    }

    if ((startDateInputValidation.value) || endDateInputValidation.value || !formValid.value) {
        formSubmitted.value = false
        return
    }

    // Check if end date is the same as, or after, start date
    if (new Date(formValues.value.end_date) < new Date(formValues.value.start_date)) {
        Say('error', t('endDateBeforeStartDate'));
        formSubmitted.value = false
        return
    }

    if (!proFormaLine.value.id) {
        createProFormaLine(proForma.value.id, formValues.value)
            .then((response: { data: { error: any; }; }) => {
                if (response.data.error) {
                    SayErrorResponse(response);
                } else {
                    closeProFormaLineDialog();
                    loadProForma();
                    emit('updatedProForma');
                    Say('success', t('createdProFormaLineSuccessfully'))
                }
            })
            .catch((error: any) => {
                SayErrorResponse(error);
            })
            .finally(() => {
                formSubmitted.value = false
            });
    } else {
        updateProFormaLine(proForma.value.id, proFormaLine.value.id, formValues.value)
            .then((response: { data: { error: any; }; }) => {
                if (response.data.error) {
                    SayErrorResponse(response);
                } else {
                    closeProFormaLineDialog();
                    loadProForma();
                    emit('updatedProForma');
                    Say('success', t('updatedProFormaLineSuccessfully'))
                }
            })
            .catch((error: any) => {
                SayErrorResponse(error);
            })
            .finally(() => {
                formSubmitted.value = false
            });
    }
}


const formValues = ref({
    primary_internal_article_number: null,
    secondary_internal_article_number: null,
    cost_unit: null,
    vat_code: null,
    description: null,
    start_date: null,
    end_date: null,
    quantity: null,
    discount_percentage: null,
    duration: null,
    price_per_unit_excl_vat: null,
})

const bulkFormValues = ref({
    cost_unit: null,
    vat_code: null,
    discount_percentage: null,
})

const form = ref(null as HTMLFormElement | null)
const bulkForm = ref(null as HTMLFormElement | null)
const formValid = ref(false)
const bulkFormValid = ref(false)

const startDateInputValidation = ref(null)
const endDateInputValidation = ref(null)

const costUnits = computed(() => {
    let returnValues = []
    for (const branch of branchStore.getters.branches) {
        returnValues.push({value:branch.cost_unit, title: `${branch.cost_unit} (${branch.city})`})
    }
    return returnValues
})

const formRuleRequired = (value) => {
    if (value == null) return t('fieldRequired')
    if (value.length == 0) return t('fieldRequired')
    return true
}
const formRuleRequiredIfOtherFieldsEmpty = (value) => {
  if (value) return true

  if (!Object.values(bulkFormValues.value).every(i => i === null)) {
    return true
  }
  return t('bulkProFormLinesOneRequired')
}

const formRules = ref({
    primary_internal_article_number: [formRuleRequired],
    secondary_internal_article_number: [],
    cost_unit: [formRuleRequired],
    vat_code: [formRuleRequired],
    description: [formRuleRequired],
    quantity: [formRuleRequired],
    price_per_unit_excl_vat: [formRuleRequired],
    discount_percentage: [],
    duration: [],
    start_date: [formRuleRequired],
    end_date: [formRuleRequired],
})
const bulkFormRules = ref({
    cost_unit: [formRuleRequiredIfOtherFieldsEmpty],
    vat_code: [formRuleRequiredIfOtherFieldsEmpty],
    discount_percentage: [formRuleRequiredIfOtherFieldsEmpty],
})

const dropDownOptions = computed(() => {
  const selectedPrimaryInternalArticleType = internalArticles.value.find(article => article.internal_article_number == formValues.value.primary_internal_article_number)?.type ?? null;
  return {
    primary_internal_article_number: [
        ...internalArticles.value.filter(article => article.internal_article_number && article.is_primary && !article.deleted_at).map(article => {
            return {value: article.internal_article_number, title: `${article.internal_article_number} (${article.internal_article_number_description})`}
        }),
    ],
    secondary_internal_article_number: [
        ...internalArticles.value.filter(article => article.internal_article_number && article.is_secondary && !article.deleted_at && article.is_secondary_for &&
                article.is_secondary_for.length > 0 && article.is_secondary_for.includes(selectedPrimaryInternalArticleType)).map(article => {
            return {value: article.internal_article_number, title: `${article.internal_article_number} (${article.internal_article_number_description})`}
        }),
    ],
    cost_unit: costUnits.value,
    vat_code: [
      {value: '1', title: `1 (${t('vatHighExcl')})`},
      {value: '2', title: `2 (${t('vatLowExcl')})`},
      {value: '5', title: `5 (${t('vatReverseCharged')})`},
    ],
  }
})

function isValidDate(dateString: string) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const costUnitDescription = (costUnit: string) => {
  return dropDownOptions.value.cost_unit.find(branch => branch.value == costUnit)?.title ?? ''
}

const vatCodeDescription = (vatCode: string) => {
  return dropDownOptions.value.vat_code.find(vat => vat.value == vatCode)?.title ?? ''
}

const primaryInternalArticleNumberChanged = () => {
    // Check if the new primary internal article number has the currently selected secondary internal article number
    const selectedPrimaryInternalArticleType = internalArticles.value.find(article => article.internal_article_number == formValues.value.primary_internal_article_number)?.type ?? null;
    if (!internalArticles.value.find(article => article.internal_article_number == formValues.value.secondary_internal_article_number && article.is_secondary_for && article.is_secondary_for.length > 0 && article.is_secondary_for.includes(selectedPrimaryInternalArticleType))) {
        formValues.value.secondary_internal_article_number = null;
    }
}

const exactOnlineArticleDescription = (primaryInternalArticleNumber: string, secondaryInternalArticleNumber: string) => {
    if(!internalArticles.value || primaryInternalArticleNumber == null) {
        return '';
    }
    let article;
    if(secondaryInternalArticleNumber == null) {
        article = internalArticles.value.find(article => article.internal_article_number == primaryInternalArticleNumber);
    } else {
        article = internalArticles.value.find(article => article.internal_article_number == secondaryInternalArticleNumber);
    }
    return article ? `${article.exact_online_item_code} (${article.exact_online_item_description})` : '';
}
</script>

<template>
    <v-dialog
        v-model="hasPreviewHtml"
        class="preview-dialog"
        @close="previewHtml=null;"
    >
        <v-card>
            <div class="preview-dialog-iframe-outer">
                <iframe :srcdoc="previewHtml.data"></iframe>
            </div>
        </v-card>
    </v-dialog>

    <div v-if="proForma.id" class="pro-forma-wrapper">
        <template v-if="!job.client_responsible_1 || !job.client_responsible_1.email">
            <v-alert type="warning" class="mb-5" density="compact">
                {{ t('clientResponsibleNotSetOrEmailMissing') }}
                <router-link
                    :to="{ name: 'JobsEdit', params: { id: job_id } }"
                    class="text-white"
                >
                    {{ t('editJob') }}
                </router-link>
            </v-alert>
        </template>

        <div class="pro-forma-wrapper-left">

            <table class="pro-forma-table custom-table">
                <thead>
                    <tr>
                        <th colspan="2" class="custom_header_table custom-bg label py-3">{{ proForma.number }} <span class="uppercase">({{ t('concept') }})</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="label">{{ t('period') }}:</td>
                        <td>{{ formatDate(proForma.start_date) }} {{ t('upToAndIncludingAbbreviation') }} {{ formatDate(proForma.end_date) }}</td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('addressedTo') }}:</td>
                        <td>
                            {{ job.client.name }}
                            <template v-if="job.client_responsible_1 && job.client_responsible_1.name">
                                <br v-if="job.client_responsible_1 && job.client_responsible_1.name" />
                                t.a.v. {{ job.client_responsible_1.name }}
                            </template>
                            <br/>
                            {{ job.client.address }}
                            <br>
                            {{ job.client.postcode }} {{ job.client.city }}
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('emailAddress') }}:</td>
                        <td>
                            <template v-if="job.client_responsible_1 && job.client_responsible_1.email">
                                {{ job.client_responsible_1.email }}
                            </template>
                            <template v-else>
                                &ndash;
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('jobDescription') }}:</td>
                        <td>
                            <template v-if="job.job_description">
                                {{ job.job_description }}
                            </template>
                            <template v-else>
                                &ndash;
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('location') }}:</td>
                        <td>
                            <template v-if="job.location">
                                {{ job.location.location }}
                            </template>
                            <template v-else>
                                &ndash;
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('yourReference') }}:</td>
                        <td>
                            <template v-if="job.client_ref">
                                {{ job.client_ref }}
                            </template>
                            <template v-else>
                                &ndash;
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">{{ t('totalExclVat') }}:</td>
                        <td>
                            &euro; {{ formatCurrency(proForma.total_excl_vat) }}
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>


        <div class="pro-forma-wrapper-right pt-4">

            <span class="btn btn-blue" @click="emit('cancelEditingProForma')">{{ t('backToOverview') }}</span>
            <v-btn flat @click="openProForma()" class="ml-4 btn-border"><span class="mdi mdi-file-find-outline icon-in-btn"></span> {{ t('openPreview') }}</v-btn>
            <v-btn flat @click="openFinalizeProFormaDialog" class="btnOrange ml-4">{{ t('finalizeProForma') }}</v-btn>
        </div>

        <div class="clearfix"></div>

        <v-btn flat @click="openProFormaLineDialog" class="float-right btnAdd btn-border">{{ t('createProFormaLine') }}</v-btn>

        <v-data-table-server
            id="pro-forma-lines-table"
            class="my-6 custom-table table-with-custom-bg"
            :headers="headers"
            :items="proForma.lines"
            v-model="bulkUpdateSelectedItems"
            item-value="id"
            :show-select="true"
            item-selectable="selectable"
            :items-length="proForma.lines.length"
            :items-per-page="-1"
            :show-pagination="false"
            :hide-default-footer="true"
            >
            <template #[`item.primary_internal_article_number`]="{item}">
                <div class="no-wrap">{{ item.primary_internal_article_number ? item.primary_internal_article_number : '-' }}</div>
            </template>
            <template #[`item.description`]="{item}">
                {{ item.description }}
            </template>
            <template #[`item.date_or_period`]="{item}">
                <div class="no-wrap">{{ item.start_date === item.end_date ? formatDate(item.start_date) : formatDate(item.start_date) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(item.end_date) }}</div>
            </template>
            <template #[`item.duration`]="{item}">
                <div class="no-wrap">{{ item.duration ? item.duration : '-' }}</div>
            </template>
            <template #[`item.exact_online_item`]="{item}">
                <div class="no-wrap">{{ exactOnlineArticleDescription(item.primary_internal_article_number, item.secondary_internal_article_number) }}</div>
            </template>
            <template #[`item.cost_unit`]="{item}">
                <div class="no-wrap">{{ costUnitDescription(item.cost_unit) }}</div>
            </template>
            <template #[`item.vat_code`]="{item}">
                <div class="no-wrap">{{ vatCodeDescription(item.vat_code) }}</div>
            </template>
            <template #[`item.quantity`]="{item}">
                <div class="no-wrap">{{ formatNumber(item.quantity, 2) }}</div>
            </template>
            <template #[`item.price_per_unit_excl_vat`]="{item}">
                <div class="no-wrap">&euro; {{ formatCurrency(item.price_per_unit_excl_vat) }}</div>
            </template>
            <template #[`item.total_before_discount_excl_vat`]="{item}">
                <div class="no-wrap">&euro; {{ formatCurrency(item.total_before_discount_excl_vat) }}</div>
            </template>
            <template #[`item.discount_percentage`]="{item}">
                <div class="no-wrap">{{ item.discount_percentage > 0 ? formatNumber(item.discount_percentage, 2) + '%' : '-' }}</div>
            </template>
            <template #[`item.discount_excl_vat`]="{item}">
                <div class="no-wrap">{{ item.discount_percentage > 0 ? '&euro; -' + formatCurrency(item.discount_excl_vat) : '-' }}</div>
            </template>
            <template #[`item.total_excl_vat`]="{item}">
                <div class="no-wrap">&euro; {{ formatCurrency(item.total_excl_vat) }}</div>
            </template>
            <template #[`item.actions`]="{item}">
                <div class="no-wrap">
                    <v-btn variant="text" @click="openProFormaLineDialog(item)" icon flat class="bg-transparent">
                        <v-icon color="grey">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="openDeleteProFormaLineDialog(item)" icon flat class="bg-transparent">
                        <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table-server>
        <v-btn
          :text="t('updateInBulk')"
          :disabled="!bulkUpdateSelectedItems.length"
          class="mt-0 mb-0"
          variant="tonal"
          @click="openProFormaItemsBulkUpdateDialog()"
        />
        <v-btn flat @click="openDeleteProFormaDialog" color="red" class="my-6 float-right">{{ t('deleteProForma') }}</v-btn>

    </div>


    <v-dialog
        v-model="isFinalizeProFormaDialogVisible"
        width="400"
        @afterLeave="closeFinalizeProFormaDialog"
        >
        <v-card
            prepend-icon="mdi-file-sign"
            :title="t('finalizeProForma')"
            card-text-class="pa-0"
            >
            <template v-slot:text>
                <template v-if="!job.client_responsible_1 || !job.client_responsible_1.email">
                    <v-alert type="warning" class="mb-5" density="compact">
                        {{ t('clientResponsibleNotSetOrEmailMissing') }}
                        <router-link
                            :to="{ name: 'JobsEdit', params: { id: job_id } }"
                            class="text-white"
                        >
                            {{ t('editJob') }}
                        </router-link>
                    </v-alert>
                </template>
                <template v-else>
                    <p class="mb-3">{{ t('confirmFinalizeProForma') }}</p>
                    <p class="mb-3">{{ t('proFormaWillBeEmailedTo') }}:</p>
                    <p>{{ t('name') }}:</p>
                    <p class="mb-3"><b>
                        <template v-if="job.client_responsible_1.name">
                            {{ job.client_responsible_1.name }}
                        </template>
                        <template v-else>
                            &ndash;
                        </template>
                    </b></p>
                    <p>{{ t('email') }}:</p>
                    <p><b>{{ job.client_responsible_1.email }}</b></p>
                    <v-alert v-if="emailsAreSentToLoggedInUser" type="info" density="compact" class="mt-5">
                        {{ t('emailWillBeSentToLoggedInUser', {'emailAddress':userEmailAddress}) }}
                    </v-alert>
                </template>
            </template>
            <template v-slot:actions>
                <v-btn
                    :text="t('finalize')"
                    :loading="formSubmitted"
                    @click="finalizeProFormaAction"
                    :disabled="!job.client_responsible_1 || !job.client_responsible_1.email"
                />
                <v-btn
                    :text="t('cancel')"
                    :disabled="formSubmitted"
                    @click="closeFinalizeProFormaDialog"
                />
            </template>
        </v-card>
    </v-dialog>


    <v-dialog
        v-model="isDeleteProFormaDialogVisible"
        width="400"
        @afterLeave="closeDeleteProFormaDialog"
        >
        <v-card
            prepend-icon="mdi-delete"
            :title="t('deleteProForma')"
            card-text-class="pa-0"
            >
            <template v-slot:text>
                <p>{{ t('confirmDeleteProForma') }}</p>
            </template>
            <template v-slot:actions>
                <v-btn
                    :text="t('remove')"
                    :loading="formSubmitted"
                    @click="deleteProFormaAction"
                />
                <v-btn
                    :text="t('cancel')"
                    :disabled="formSubmitted"
                    @click="closeDeleteProFormaDialog"
                />
            </template>
        </v-card>
    </v-dialog>


    <v-dialog
        v-model="isDeleteProFormaLineDialogVisible"
        width="400"
        @afterLeave="closeDeleteProFormaLineDialog"
        >
        <v-card
            prepend-icon="mdi-delete"
            :title="t('deleteProFormaLine')"
            card-text-class="pa-0"
            >
            <template v-slot:text>
                <p>{{ t('confirmDeleteProFormaLine') }}</p>
            </template>
            <template v-slot:actions>
                <v-btn
                    :text="t('remove')"
                    :loading="formSubmitted"
                    @click="deleteProFormaLineAction"
                />
                <v-btn
                    :text="t('cancel')"
                    :disabled="formSubmitted"
                    @click="closeDeleteProFormaLineDialog"
                />
            </template>
        </v-card>
    </v-dialog>


    <v-dialog
        v-model="isProFormaLineDialogVisible"
        width="800"
        @afterLeave="closeProFormaLineDialog"
        >
        <v-card
            prepend-icon="mdi-pencil"
            :title="proFormaLine.id ? t('updateProFormaLine') : t('createProFormaLine')"
            card-text-class="pa-0"
            >
            <template v-slot:text>
                <v-form
                    v-model="formValid"
                    ref="form"
                >
                <div class="input-group">
                <v-row>
                    <v-col md="6">
                        <div class="datepickerWrapper">
                            <div class="datepickerLabel">{{ t('fromDate') }}</div>
                            <Datepicker
                            v-model="formValues.start_date"
                            :placeholder="t('date')"
                            v-on:update:value-datetime="formValues.start_date = $event"
                            :teleport="true"
                            :min-date="proForma.start_date"
                            :max-date="proForma.end_date"
                            >
                            <template #below>
                                <div v-if="startDateInputValidation" class="text-dark-red">
                                {{ t('fieldRequired') }}
                                </div>
                            </template>
                            </Datepicker>
                        </div>
                    </v-col>
                    <v-col md="6">
                        <div class="datepickerWrapper">
                            <div class="datepickerLabel">{{ t('upToAndIncludingDate') }}</div>
                            <Datepicker
                            v-model="formValues.end_date"
                            :placeholder="t('date')"
                            v-on:update:value-datetime="formValues.end_date = $event"
                            :teleport="true"
                            :min-date="proForma.start_date"
                            :max-date="proForma.end_date"
                            >
                            <template #below>
                                <div v-if="endDateInputValidation" class="text-dark-red">
                                {{ t('fieldRequired') }}
                                </div>
                            </template>
                            </Datepicker>
                        </div>
                    </v-col>
                </v-row>
                </div>
                <div class="input-group">
                <v-row>
                    <v-col md="8">
                    <v-text-field
                        v-model="formValues.description"
                        :rules="formRules.description"
                        :label="t('description')"
                        variant="underlined"
                        />
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        v-model="formValues.duration"
                        :rules="formRules.duration"
                        :label="t('duration')"
                        variant="underlined"
                        />
                    </v-col>
                </v-row>
                </div>
                <div class="input-group">
                <v-row>
                    <v-col md="4">
                    <v-text-field
                        v-model="formValues.quantity"
                        :rules="formRules.quantity"
                        :label="t('quantity')"
                        type="number"
                        step="0.01"
                        min="0"
                        variant="underlined"
                    />
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        v-model="formValues.price_per_unit_excl_vat"
                        prepend-icon="mdi-currency-eur"
                        :rules="formRules.price_per_unit_excl_vat"
                        :label="t('pricePerUnitExclVat')"
                        type="number"
                        step="0.01"
                        min="0"
                        variant="underlined"
                    />
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        v-model="formValues.discount_percentage"
                        prepend-icon="mdi-percent-outline"
                        :rules="formRules.discount_percentage"
                        :label="t('discountPercentage')"
                        type="number"
                        step="0.01"
                        min="0"
                        variant="underlined"
                    />
                    </v-col>
                </v-row>
                </div>
                <div class="input-group">
                <v-row>
                    <v-col md="6">
                        <v-autocomplete
                            v-model="formValues.primary_internal_article_number"
                            :rules="formRules.primary_internal_article_number"
                            :items="dropDownOptions.primary_internal_article_number"
                            :label="t('primaryInternalArticleNumber')"
                            :item-props="true"
                            variant="underlined"
                            @update:model-value="primaryInternalArticleNumberChanged"
                        />
                    </v-col>
                    <v-col md="6">
                        <v-autocomplete
                            v-model="formValues.secondary_internal_article_number"
                            :rules="formRules.secondary_internal_article_number"
                            :items="dropDownOptions.secondary_internal_article_number"
                            :label="t('secondaryInternalArticleNumber')"
                            :item-props="true"
                            variant="underlined"
                            clearable
                        />
                    </v-col>
                    <v-col md="6">
                        <v-text-field
                            :model-value="exactOnlineArticleDescription(formValues.primary_internal_article_number, formValues.secondary_internal_article_number)"
                            :label="t('exactOnlineArticle')"
                            variant="underlined"
                            :placeholder="t('basedOnSelectedInternalArticles')"
                            readonly
                        />
                    </v-col>
                    <v-col md="3">
                        <v-select
                            v-model="formValues.cost_unit"
                            :rules="formRules.cost_unit"
                            :items="dropDownOptions.cost_unit"
                            :label="t('exactOnlineCostUnit')"
                            :item-props="true"
                            variant="underlined"
                        />
                    </v-col>
                    <v-col md="3">
                        <v-select
                            v-model="formValues.vat_code"
                            :rules="formRules.vat_code"
                            :items="dropDownOptions.vat_code"
                            :label="t('exactOnlineVatCode')"
                            :item-props="true"
                            variant="underlined"
                        />
                    </v-col>
                </v-row>
                </div>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn
                    :text="t('save')"
                    :loading="formSubmitted"
                    @click="createOrUpdateProFormaLine"
                />
                <v-btn
                    :text="t('cancel')"
                    :disabled="formSubmitted"
                    @click="closeProFormaLineDialog"
                />
            </template>
        </v-card>
    </v-dialog>

    <v-dialog
      v-model="bulkUpdateDialogVisible"
      width="640"
    >
      <v-form
        v-model="bulkFormValid"
        ref="bulkForm"
        @submit.prevent="bulkUpdateProFormaLines"
    >
        <v-card
          prepend-icon="mdi-pencil"
          :title="t('dialogBulk')"
          card-text-class="pa-0"
        >
          <template v-slot:text>
            <p class="mb-3">
                {{ t('leaveFieldsEmptyToKeepTheCurrentValue') }}
            </p>
            <div class="input-group">
              <v-row>
                  <v-col md="4">
                    <v-text-field
                      v-model="bulkFormValues.discount_percentage"
                      prepend-icon="mdi-percent-outline"
                      :rules="bulkFormRules.discount_percentage"
                      :label="t('discountPercentage')"
                      type="number"
                      step="0.01"
                      min="0"
                      variant="underlined"
                      clearable
                  />
                  </v-col>
                  <v-col md="4">
                    <v-select
                      v-model="bulkFormValues.cost_unit"
                      :rules="bulkFormRules.cost_unit"
                      :items="dropDownOptions.cost_unit"
                      :label="t('exactOnlineCostUnit')"
                      :item-props="true"
                      variant="underlined"
                      clearable
                  />
                  </v-col>
                  <v-col md="4">
                    <v-select
                        v-model="bulkFormValues.vat_code"
                        :rules="bulkFormRules.vat_code"
                        :items="dropDownOptions.vat_code"
                        :label="t('vat')"
                        :item-props="true"
                        variant="underlined"
                        clearable
                    />
                  </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:actions>
              <v-btn
                  type="submit"
                  :text="t('save')"
                  :loading="formSubmitted"
              />
              <v-btn
                  :text="t('cancel')"
                  :disabled="formSubmitted"
                  @click="closeProFormaItemsBulkUpdateDialog"
              />
          </template>
        </v-card>
      </v-form>
    </v-dialog>
</template>

<style scoped lang="scss">
.pro-forma-wrapper {
    min-width: 1100px;
}
.pro-forma-wrapper-left {
    width: 50%;
    float: left;
}
.pro-forma-wrapper-right {
    width: 50%;
    float: right;
    text-align: right;
}
.pro-forma-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    font-size: 10.5px;
}

.pro-forma-table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.pro-forma-table th, .pro-forma-table td {
    vertical-align: top;
}

.pro-forma-table th {
    padding: 12px 16px;
    text-align: left;
    background-color: #fff;
    font-weight: 500;
}

.pro-forma-table td {
    padding: 8px 16px;
}

#pro-forma-lines-table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.no-wrap {
    white-space: nowrap;
}

.label {
    font-weight: 500;
}

.clearfix {
    clear: both;
}

.btnOrange {
  background-color: #f57c00;
  color: #fff;
}

.btn {
  height: auto;
  cursor: pointer;
  color: rgb(245, 120, 40);
  font-weight: 500;
  text-decoration: underline;
  user-select: none;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}

.btn-red {
  color: rgb(255, 0, 0);
}

.btn-blue {
  color: rgb(30, 136, 229);
}

.btn-icon {
    font-size: 16px;
    text-decoration: none;
}

.icon-in-btn {
    font-size: 16px;
    margin-left: -5px;
}

.btnAdd {
    margin-top: -20px;
}

.text-dark-red {
    color: rgb(176, 0, 32);
}

.btn-border {
    border: 1px solid #ddd;
}

.uppercase {
    text-transform: uppercase;
}

.custom-table tr th:first-child {
    border-top-left-radius: 4px;
}
.custom-table tr th:last-child {
    border-top-right-radius: 4px;
}
.preview-dialog {
  width: 70rem;
  .preview-dialog-iframe-outer {
    width: auto;
    height: 90rem;
    display: flex;
    padding: 15px;
    background-color: white;
    iframe {
      width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      height: 100%;
    }
  }
  @media print {
    display: none;
  }
}
</style>
