<script setup lang="ts">
import {computed, inject, onBeforeMount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import Datepicker from "@/components/UI/Datepicker.vue";
import {jobAdditionalCostsAPI as API} from "@/api/jobs/JobAdditionalCosts";
import SayErrorResponse from "@/mixins/sayErrorResponse";
import Say from "@/utils/Say";
import branchStore from "@/store/branches";
import {capitalcase} from "@/utils/filters";
import { fetchInternalArticles } from '@/api/internalArticles';

const {t} = useI18n()

const jobId = inject('jobId', null)
API.setJobId(jobId.value)
const job = inject('job', null)

const headers: any = ref([
  {title: t('primaryInternalArticle'), key: 'primary_internal_article_number'},
  {title: t('secondaryInternalArticle'), key: 'secondary_internal_article_number'},
  {title: t('description'), key: 'description'},
  {title: t('date'), key: 'date'},
  {title: t('exactOnlineArticle'), key: 'exact_online_item'},
  {title: t('exactOnlineCostUnit'), key: 'cost_unit'},
  {title: t('exactOnlineVatCode'), key: 'vat_code'},
  {title: t('quantity'), key: 'quantity', align: 'end'},
  {title: t('pricePerUnitExclVat'), key: 'price_per_unit_excl_vat', align: 'end'},
  {title: t('amountExclVat'), key: 'total_excl_vat', align: 'end'},
  {width: 100, align: 'end', key: ''},
]);
const items = ref([]);

const dialogOpen = ref(null)
const dialogEditing = ref(null)
const form = ref(null as HTMLFormElement | null)
const formValid = ref(false)
const formSubmitted = ref(false)

const internalArticles = ref([])

const formValues = ref({
  primary_internal_article_number: null,
  secondary_internal_article_number: null,
  cost_unit: null,
  vat_code: null,
  description: null,
  date: null,
  quantity: null,
  price_per_unit_excl_vat: null,
})

const dateInputValidation = ref(null)

const costUnits = computed(() => {
  let returnValues = []
  for (const branch of branchStore.getters.branches) {
    returnValues.push({value:branch.cost_unit, title: `${branch.cost_unit} (${branch.city})`})
  }
  return returnValues
})

const formRuleRequired = (value) => {
  if (value == null) return t('fieldRequired')
  if (value.length == 0) return t('fieldRequired')
  return true
}

const formRules = ref({
  primary_internal_article_number: [formRuleRequired],
  secondary_internal_article_number: [],
  cost_unit: [formRuleRequired],
  vat_code: [formRuleRequired],
  description: [formRuleRequired],
  quantity: [formRuleRequired],
  price_per_unit_excl_vat: [formRuleRequired],
  date: [formRuleRequired],
})
const dropDownOptions = computed(() => {
  const selectedPrimaryInternalArticleType = internalArticles.value.find(article => article.internal_article_number == formValues.value.primary_internal_article_number)?.type ?? null;
  return {
    primary_internal_article_number: [
        ...internalArticles.value.filter(article => article.is_option_for_additional_costs && article.internal_article_number && article.is_primary && !article.deleted_at).map(article => {
            return {value: article.internal_article_number, title: `${article.internal_article_number} (${article.internal_article_number_description})`}
        }),
    ],
    secondary_internal_article_number: [
        ...internalArticles.value.filter(article => article.is_option_for_additional_costs && article.internal_article_number && article.is_secondary && !article.deleted_at && 
            article.is_secondary_for && article.is_secondary_for.length > 0 && article.is_secondary_for.includes(selectedPrimaryInternalArticleType)).map(article => {
          return {value: article.internal_article_number, title: `${article.internal_article_number} (${article.internal_article_number_description})`}
        }),
    ],
    cost_unit: costUnits.value,
    vat_code: [
      {value: '1', title: `1 (${t('vatHighExcl')})`},
      {value: '2', title: `2 (${t('vatLowExcl')})`},
      {value: '5', title: `5 (${t('vatReverseCharged')})`},
    ],
  }
})

const openDialog = (editing = null) => {
  dateInputValidation.value = null
  dialogEditing.value = editing
  formValues.value = {
    primary_internal_article_number: editing?.primary_internal_article_number,
    secondary_internal_article_number: editing?.secondary_internal_article_number,
    cost_unit: editing?.cost_unit,
    vat_code: editing?.vat_code,
    description: editing?.description,
    date: editing?.date,
    quantity: editing?.quantity,
    price_per_unit_excl_vat: editing?.price_per_unit_excl_vat,
  }
  if (!dialogEditing.value ) {
    formValues.value.cost_unit = dropDownOptions.value.cost_unit.find(
        costUnit => costUnit.value == branchStore.getters.branches.find(branch => branch.id == job.value.branch_id).cost_unit
    ).value
    formValues.value.vat_code = dropDownOptions.value.vat_code[0].value
  } else {
    formValues.value.cost_unit = dropDownOptions.value.cost_unit.find(costUnit => costUnit.value == editing.cost_unit)?.value
    formValues.value.vat_code = dropDownOptions.value.vat_code.find(vat => vat.value == editing.vat_code)?.value
  }
  dialogOpen.value = true
}
const closeDialog = () => {
  dialogOpen.value = false
  dialogEditing.value = null

  dateInputValidation.value = null
  formValues.value = {
    primary_internal_article_number: null,
    secondary_internal_article_number: null,
    cost_unit: null,
    vat_code: null,
    description: null,
    date: null,
    quantity: null,
    price_per_unit_excl_vat: null,
  }

}
onBeforeMount(async() => {
  await branchStore.dispatch('fetchBranches')
  loadInternalArticles()
})
onMounted(() => {
  loadIndex()
})

const loadInternalArticles = async () => {
  try {
    const response = await fetchInternalArticles();
    if(!response.data) {
      SayErrorResponse(response);
    } else {
        internalArticles.value = response.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const loadIndex = () => {
  API.index().then(
    (data) => {
      items.value = data.data
    },
    (error) => {
      SayErrorResponse(error)
    },
  )
}
const submit = () => {
  if (formSubmitted.value) {
    return
  }

  form.value.validate();

  formSubmitted.value = true

  if (!formValues.value.date || formValues.value.date == 'Invalid date') {
    dateInputValidation.value = true
  } else {
    dateInputValidation.value = null
  }

  if ((dateInputValidation.value) || !formValid.value) {
    formSubmitted.value = false
    return
  }

  if (!dialogEditing.value) {
    API.store(formValues.value).then(
      (data) => {
        loadIndex()
        closeDialog()
        Say('success', t('costCreated'))
        formSubmitted.value = false
      },
      (error) => {
        SayErrorResponse(error)
        formSubmitted.value = false
      },
    )
  } else {
    API.update(dialogEditing.value.id, formValues.value).then(
      (data) => {
        loadIndex()
        closeDialog()
        Say('success', t('costUpdated'))
        formSubmitted.value = false
      },
      (error) => {
        SayErrorResponse(error)
        formSubmitted.value = false
      },
    )
  }
}
const deleteRow = (id) => {
  if (!confirm(t('confirm'))) {
    return
  }
  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true

  API.delete(id).then(
    (data) => {
      loadIndex()
      Say('success', t('costDeleted'))
      formSubmitted.value = false
    },
    (error) => {
      SayErrorResponse(error)
      formSubmitted.value = false
    },
  )
}

const formatNumber = (value: number, decimals: number) => {
  if (value === null) {
    return '';
  }
  value = Number(value)
  // check if value is a rounded number
  if (value % 1 === 0) {
    return value
  } else {
    return value.toFixed(decimals).replace('.', ',')
  }
}

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const costUnitDescription = (costUnit: string) => {
  return dropDownOptions.value.cost_unit.find(branch => branch.value == costUnit)?.title ?? ''
}

const vatCodeDescription = (vatCode: string) => {
  return dropDownOptions.value.vat_code.find(vat => vat.value == vatCode)?.title ?? ''
}

const primaryInternalArticleNumberChanged = () => {
  // Check if the new primary internal article number has the currently selected secondary internal article number
  const selectedPrimaryInternalArticleType = internalArticles.value.find(article => article.internal_article_number == formValues.value.primary_internal_article_number)?.type ?? null;
  if (!internalArticles.value.find(article => article.internal_article_number == formValues.value.secondary_internal_article_number && article.is_secondary_for && article.is_secondary_for.length > 0 && article.is_secondary_for.includes(selectedPrimaryInternalArticleType))) {
      formValues.value.secondary_internal_article_number = null;
  }
}

const exactOnlineArticleDescription = (primaryInternalArticleNumber: string, secondaryInternalArticleNumber: string) => {
    if(!internalArticles.value || primaryInternalArticleNumber == null) {
        return '';
    }
    let article;
    if(secondaryInternalArticleNumber == null) {
        article = internalArticles.value.find(article => article.internal_article_number == primaryInternalArticleNumber);
    } else {
        article = internalArticles.value.find(article => article.internal_article_number == secondaryInternalArticleNumber);
    }
    return article ? `${article.exact_online_item_code} (${article.exact_online_item_description})` : '';
}
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          :text="t('addAdditionalCost')"
          @click="openDialog(null)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat rounded border>
          <v-data-table-server
            id="additional-costs-table"
            :headers="headers"
            :items="items"
            :items-length="items.length"
            :items-per-page="items.length"
            :hide-default-footer="true"
            disable-sort
          >
            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span :class="header.sortable ? 'pointer' : ''" class="font-weight-bold">{{ capitalcase(header.title) }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small" style="margin: 5px"></v-icon>
                      </template>
                      <template v-if="header.sortable && !isSorted(header)">
                        <v-icon icon="mdi-sort" size="small" style="margin: 5px"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>
            <template #[`item.secondary_internal_article_number`]="rowData">
              <template v-if="rowData.item.secondary_internal_article_number">
                <span>{{ rowData.item.secondary_internal_article_number }}</span>
              </template>
              <template v-else>
                &ndash;
              </template>
            </template>
            <template #[`item.description`]="rowData">
              <span class="allow-white-space">{{ rowData.item.description }}</span>
            </template>
            <template #[`item.vat_code`]="rowData">
              {{ vatCodeDescription(rowData.item.vat_code) }}
            </template>
            <template #[`item.exact_online_item`]="rowData">
              {{ exactOnlineArticleDescription(rowData.item.primary_internal_article_number, rowData.item.secondary_internal_article_number) }}
            </template>
            <template #[`item.cost_unit`]="rowData">
              {{ costUnitDescription(rowData.item.cost_unit) }}
            </template>
            <template #[`item.quantity`]="rowData">
              {{ formatNumber(rowData.item.quantity, 2) }}
            </template>
            <template #[`item.price_per_unit_excl_vat`]="rowData">
              &euro; {{ formatCurrency(rowData.item.price_per_unit_excl_vat) }}
            </template>
            <template #[`item.total_excl_vat`]="rowData">
              &euro; {{ formatCurrency(rowData.item.total_excl_vat) }}
            </template>
            <template #[`item.`]="rowData">
              <template v-if="rowData.item.editable">
                  <v-btn variant="text" @click="openDialog(rowData.item)" icon flat class="bg-transparent">
                      <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn @click="deleteRow(rowData.item.id)" icon flat class="bg-transparent">
                      <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
              </template>
              <template v-else>
                  <v-btn v-tooltip="t('additionalCostCannotBeEdited')" icon flat class="bg-transparent btn-disabled">
                      <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn  v-tooltip="t('additionalCostCannotBeDeleted')" icon flat class="bg-transparent btn-disabled">
                      <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
              </template>
            </template>
          </v-data-table-server>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogOpen"
      width="800"
    >
      <v-card
        prepend-icon="mdi-pencil"
        :title="dialogEditing ?  t('dialogEditCost') : t('dialogAddCost')"
      >
        <template v-slot:text>

          <v-form
            v-model="formValid"
            ref="form"
          >
            <div class="input-group">
              <v-row>
                <v-col md="6">
                  <div class="datepickerWrapper">
                <div class="datepickerLabel">{{ t('date') }}</div>
                <Datepicker
                  v-model="formValues.date"
                  :placeholder="t('date')"
                  v-on:update:value-datetime="formValues.date = $event"
                  :teleport="false"
                >
                  <template #below>
                    <div v-if="dateInputValidation" class="text-dark-red">
                      {{ t('fieldRequired') }}
                    </div>
                  </template>
                </Datepicker>
              </div>
                </v-col>
              </v-row>
            </div>
            <div class="input-group">
              <v-row>
                <v-col>
                <v-text-field
                  v-model="formValues.description"
                  :rules="formRules.description"
                  :label="t('description')"
                  variant="underlined"
                />
                </v-col>
              </v-row>

            </div>
            <div class="input-group">
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="formValues.quantity"
                    :rules="formRules.quantity"
                    :label="t('quantity')"
                    type="number"
                    step="0.01"
                    min="0"
                    variant="underlined"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="formValues.price_per_unit_excl_vat"
                    prepend-icon="mdi-currency-eur"
                    :rules="formRules.price_per_unit_excl_vat"
                    :label="t('pricePerUnitExclVat')"
                    type="number"
                    step="0.01"
                    min="0"
                    variant="underlined"
                  />
                </v-col>
              </v-row>
            </div>
            <div class="input-group">
              <v-row>
                <v-col md="6">
                  <v-autocomplete
                      v-model="formValues.primary_internal_article_number"
                      :rules="formRules.primary_internal_article_number"
                      :items="dropDownOptions.primary_internal_article_number"
                      :label="t('primaryInternalArticleNumber')"
                      :item-props="true"
                      variant="underlined"
                      @update:model-value="primaryInternalArticleNumberChanged"
                  />
                </v-col>
                <v-col md="6">
                  <v-autocomplete
                      v-model="formValues.secondary_internal_article_number"
                      :rules="formRules.secondary_internal_article_number"
                      :items="dropDownOptions.secondary_internal_article_number"
                      :label="t('secondaryInternalArticleNumber')"
                      :item-props="true"
                      variant="underlined"
                      clearable
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                      :model-value="exactOnlineArticleDescription(formValues.primary_internal_article_number, formValues.secondary_internal_article_number)"
                      :label="t('exactOnlineArticle')"
                      variant="underlined"
                      :placeholder="t('basedOnSelectedInternalArticles')"
                      readonly
                  />
                </v-col>
                <v-col md="3">
                  <v-select
                    v-model="formValues.cost_unit"
                    :rules="formRules.cost_unit"
                    :items="dropDownOptions.cost_unit"
                    :label="t('exactOnlineCostUnit')"
                    :item-props="true"
                    variant="underlined"
                  />
                </v-col>
                <v-col md="3">
                  <v-select
                    v-model="formValues.vat_code"
                    :rules="formRules.vat_code"
                    :items="dropDownOptions.vat_code"
                    :label="t('exactOnlineVatCode')"
                    :item-props="true"
                    variant="underlined"
                  />
                </v-col>
              </v-row>
            </div>
          </v-form>
        </template>
        <template v-slot:actions>
          <v-btn
            :text="t('save')"
            @click="submit"
          />
          <v-btn
            :text="t('cancel')"
            @click="closeDialog"
          />
        </template>
      </v-card>
    </v-dialog>


  </div>
</template>

<style scoped lang="scss">
::v-deep .v-table#additional-costs-table td {
  white-space: nowrap;
}
.allow-white-space {
  white-space: normal;
}
.btn {
  height: auto;
  margin-right: 16px;
  cursor: pointer;
  color: rgb(245, 120, 40);
  font-weight: 500;
  text-decoration: underline;
  user-select: none;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}

.btn-red {
  color: rgb(255, 0, 0);
}

.btn[disabled] {
  color: rgba(245, 120, 40, 0.5);
  cursor: default !important;
}

.btn.btn-red[disabled] {
  color: rgba(255, 0, 0, 0.5);
  cursor: default !important;
}

.btn-disabled {
  opacity: 0.5;
  cursor: default;
}

.text-dark-red {
    color: rgb(176, 0, 32);
}
</style>
