<template>
  <p class="pb-6">
    {{ t('costOverviewDescription') }}
  </p>
  <template v-if="costOverview.generated_at && (Object.keys(costOverview.equipment).length || Object.keys(costOverview.lettering).length || Object.keys(costOverview.progr_data).length || Object.keys(costOverview.hours).length || Object.keys(costOverview.additionalCosts).length)">
    <template v-if="costOverview.first_date !== costOverview.first_date_with_costs">
      <v-alert type="warning" class="mb-5" density="compact">
        {{ t('firstDatesAreNotEqual') }} ({{ formatDate(costOverview.first_date_with_costs) }})
      </v-alert>
    </template>
    <template v-if="costOverview.last_date !== costOverview.last_date_with_costs">
      <v-alert type="warning" class="mb-5" density="compact">
        {{ t('lastDatesAreNotEqual') }} ({{ formatDate(costOverview.last_date_with_costs) }})
      </v-alert>
    </template>
  </template>
  <div class="clearfix"></div>
  <div id="wrapper">
    <div class="table-container">
      <template v-if="costOverview.generated_at && (Object.keys(costOverview.equipment).length || Object.keys(costOverview.lettering).length || Object.keys(costOverview.progr_data).length || Object.keys(costOverview.hours).length || Object.keys(costOverview.additionalCosts).length)">
        <div class="table-left-wrapper">
        <table class="table-left">
          <thead>
            <tr>
              <th colspan="5" class="text-normal"><i>{{ t('overviewGeneratedAt') }}: {{ formatDateTime(costOverview.generated_at) }}</i></th>
            </tr>
            <tr>
              <th style="height:40.5px;">{{ t('quantity') }}</th>
              <th>{{ t('description') }}</th>
              <th>{{ t('locationOrWorkOrder') }}</th>
              <th>{{ t('rate') }}</th>
              <th>{{ t('rateType') }}</th>
            </tr>
          </thead>
          <tbody>
            <!-- Equipment -->
            <template v-if="Object.keys(costOverview.equipment).length">
              <tr>
                <th colspan="5" class="text-normal"><i>{{ t('equipment') }}</i></th>
              </tr>
              <tr v-for="(equipment, index) in costOverview.equipment" :key="index" @mouseover="onMouseOver('equipment_' + index, 'left')" @mouseleave="onMouseLeave('equipment_' + index, 'left')" :id="'equipment_' + index">
                <td>{{ equipment.quantity }}</td>
                <td class="description">{{ equipment.equipment_name }} <span v-if="equipment.unique_feature">| {{ equipment.unique_feature }}</span></td>
                <td class="location">{{ equipment.location }}</td>
                <td>&euro; {{ formatCurrency(equipment.rate) }}</td>
                <td>{{ equipment.rate_type }}</td>
              </tr>
            </template>
            <!-- Lettering -->
            <template v-if="Object.keys(costOverview.lettering).length">
              <tr>
                <th colspan="5" class="text-normal"><i>{{ t('lettering') }}</i></th>
              </tr>
              <tr v-for="(lettering, index) in costOverview.lettering" :key="index" @mouseover="onMouseOver('lettering_' + index, 'left')" @mouseleave="onMouseLeave('lettering_' + index, 'left')" :id="'lettering_' + index">
                <td>{{ lettering.quantity }}</td>
                <td class="description">{{ lettering.equipment_name }} <span v-if="lettering.unique_feature">| {{ lettering.unique_feature }}</span></td>
                <td class="location">{{ lettering.location }}</td>
                <td>&euro; {{ formatCurrency(lettering.rate) }}</td>
                <td>{{ lettering.rate_type }}</td>
              </tr>
            </template>
            <!-- Programming and data -->
            <template v-if="Object.keys(costOverview.progr_data).length">
              <tr>
                <th colspan="5" class="text-normal"><i>{{ t('programmingAndData') }}</i></th>
              </tr>
              <tr v-for="(progr_data, index) in costOverview.progr_data" :key="index" @mouseover="onMouseOver('progr_data_' + index, 'left')" @mouseleave="onMouseLeave('progr_data_' + index, 'left')" :id="'progr_data_' + index">
                <td>{{ progr_data.quantity }}</td>
                <td class="description">{{ progr_data.equipment_name }} <span v-if="progr_data.unique_feature">| {{ progr_data.unique_feature }}</span></td>
                <td class="location">{{ progr_data.location }}</td>
                <td>&euro; {{ formatCurrency(progr_data.rate) }}</td>
                <td>{{ progr_data.rate_type }}</td>
              </tr>
            </template>
            <!-- Hours -->
            <template v-if="Object.keys(costOverview.hours).length">
              <tr>
                <th colspan="5" class="text-normal"><i>{{ t('activities') }}</i></th>
              </tr>
              <tr v-for="(hour, index) in costOverview.hours" :key="index" @mouseover="onMouseOver('hours_' + index, 'left')" @mouseleave="onMouseLeave('hours_' + index, 'left')" :id="'hours_' + index">
                <td>{{ formatNumber(hour.hours, 2) }}</td>
                <td class="description">{{ hour.description }}</td>
                <td>{{ hour.work_order_name }}</td>
                <td>&euro; {{ formatCurrency(hour.rate) }}</td>
                <td>{{ hour.rate_type }}</td>
              </tr>
            </template>
            <!-- Additional Costs -->
            <template v-if="Object.keys(costOverview.additionalCosts).length">
              <tr>
                <th colspan="5" class="text-normal"><i>{{ t('additionalCosts') }}</i></th>
              </tr>
              <tr v-for="(additional_cost, index) in costOverview.additionalCosts" :key="index" @mouseover="onMouseOver('additional_costs_' + index, 'left')" @mouseleave="onMouseLeave('additional_costs_' + index, 'left')" :id="'additional_costs_' + index">
                <td>{{ formatNumber(additional_cost.quantity, 2) }}</td>
                <td class="description">{{ additional_cost.description }}</td>
                <td>-</td>
                <td>&euro; {{ formatCurrency(additional_cost.rate) }}</td>
                <td>{{ additional_cost.rate_type }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        </div>
        <div class="table-right-wrapper" id="scrollable-container">
        <table class="table-right">
          <thead>
            <tr>
              <th v-for="item in costOverview.checkout_periods" :key="item.id" :colspan="item.total_days" :class="['border-left', item.is_future ? 'future-date' : '']">
                <span @click="openCheckoutPeriod(item.id, item.is_future)" class="cursor-pointer link">{{ item.id > 0 ? t('checkoutPeriod') + ' ' + formatDate(item.start_date) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(item.end_date) : t('conceptCheckoutPeriod') + ' ' + formatDate(item.start_date) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(item.end_date) }}</span>
                <template v-if="item.id > 0">
                  <template v-if="isFirstCheckoutPeriod(item)">
                    <template v-if="costOverview.first_date !== costOverview.first_date_with_costs && item.pro_forma_count === 0">
                      <span @click="openStartDateDialog(item)" class="ml-4 cursor-pointer link link-orange">{{ t('updateStartDate') }}</span>
                    </template>
                    <template v-else-if="costOverview.first_date === costOverview.first_date_with_costs && item.pro_forma_count > 0">
                      <span v-tooltip="t('tooltipFirstDatesAreEqualAndProForma')" disabled class="ml-4 cursor-pointer link link-orange">{{ t('updateStartDate') }}</span>
                    </template>
                    <template v-else-if="item.pro_forma_count > 0">
                      <span v-tooltip="t('tooltipProForma')" disabled class="ml-4 cursor-pointer link link-orange">{{ t('updateStartDate') }}</span>
                    </template>
                    <template v-else>
                      <span v-tooltip="t('tooltipFirstDatesAreEqual')" disabled class="ml-4 cursor-pointer link link-orange">{{ t('updateStartDate') }}</span>
                    </template>
                  </template>
                  <template v-if="!hasNextCheckoutPeriod(item) && item.pro_forma_count === 0">
                    <span @click="openEndDateDialog(item)" class="ml-4 cursor-pointer link link-orange">{{ t('updateEndDate') }}</span>
                    <span @click="openRemoveCheckoutPeriodDialog(item)" class="ml-4 cursor-pointer link link-red">{{ t('removeCheckoutPeriod') }}</span>
                  </template>
                  <template v-else-if="hasNextCheckoutPeriod(item) && item.pro_forma_count > 0">
                    <!-- Tooltip for having a following checkout period and pro formas -->
                    <span v-tooltip="t('tooltipFollowingCheckoutAndProForma')" disabled class="ml-4 cursor-pointer link link-orange">{{ t('updateEndDate') }}</span>
                    <span v-tooltip="t('tooltipFollowingCheckoutAndProForma')" disabled class="ml-4 cursor-pointer link link-red">{{ t('removeCheckoutPeriod') }}</span>
                  </template>
                  <template v-else-if="hasNextCheckoutPeriod(item)">
                    <!-- Tooltip for having a following checkout period -->
                    <span v-tooltip="t('tooltipFollowingCheckoutPeriod')" disabled class="ml-4 cursor-pointer link link-orange">{{ t('updateEndDate') }}</span>
                    <span v-tooltip="t('tooltipFollowingCheckoutPeriod')" disabled class="ml-4 cursor-pointer link link-red">{{ t('removeCheckoutPeriod') }}</span>
                  </template>
                  <template v-else-if="item.pro_forma_count > 0">
                    <!-- Tooltip for having pro formas -->
                    <span v-tooltip="t('tooltipProForma')" disabled class="ml-4 cursor-pointer link link-orange">{{ t('updateEndDate') }}</span>
                    <span v-tooltip="t('tooltipProForma')" disabled class="ml-4 cursor-pointer link link-red">{{ t('removeCheckoutPeriod') }}</span>
                  </template>
                </template>
                <template v-else>
                  <template v-if="item.start_date < new Date().toISOString().split('T')[0]">
                    <span @click="openEndDateDialog(item.id)" class="ml-4 cursor-pointer link link-orange">{{ t('createCheckoutPeriod') }}</span>
                  </template>
                  <template v-else>
                    <span v-tooltip="t('tooltipStartAndEndDateCannotBeInTheFuture')" disabled class="ml-4 cursor-pointer link link-orange">{{ t('createCheckoutPeriod') }}</span>
                  </template>
                </template>
              </th>
            </tr>
            <tr>
              <th v-for="date in costOverview.all_dates" :key="date" :class="[checkIfIsStartOfCheckoutPeriod(date) ? 'border-left' : '', isFutureDate(date) ? 'future-date' : '']">
                {{ showDay(date) }}<br>{{ formatDate(date) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Equipment -->
            <template v-if="Object.keys(costOverview.equipment).length">
              <tr>
                <th v-for="item in costOverview.checkout_periods" :key="item.id" :colspan="item.total_days" :class="['border-left', item.is_future ? 'future-date' : '']">&nbsp;</th>
              </tr>
              <tr v-for="(equipment, index) in costOverview.equipment" :key="index" @mouseover="onMouseOver('equipment_' + index, 'right')" @mouseleave="onMouseLeave('equipment_' + index, 'right')" :id="'equipment_' + index">
                <td v-for="date in costOverview.all_dates" :key="date" :class="[getActiveClass(equipment, date), checkIfIsStartOfCheckoutPeriod(date) ? 'border-left' : '', isFutureDate(date) ? 'future-date' : '']">
                  <span v-if="equipment.dates[date]">&euro; {{ formatCurrency(equipment.dates[date]) }}</span>
                  <span v-else>&nbsp;</span>
                  <span v-if="date === costOverview.last_date && !equipment.original_rent_end" class="continues-after-wrapper"><span class="continues-after"><span class="mdi mdi-arrow-right-thin"></span></span></span>
                </td>
              </tr>
            </template>
            <!-- Lettering -->
            <template v-if="Object.keys(costOverview.lettering).length">
              <tr>
                <th v-for="item in costOverview.checkout_periods" :key="item.id" :colspan="item.total_days" :class="['border-left', item.is_future ? 'future-date' : '']">&nbsp;</th>
              </tr>
              <tr v-for="(lettering, index) in costOverview.lettering" :key="index" @mouseover="onMouseOver('lettering_' + index, 'right')" @mouseleave="onMouseLeave('lettering_' + index, 'right')" :id="'lettering_' + index">
                <td v-for="date in costOverview.all_dates" :key="date" :class="[checkIfIsStartOfCheckoutPeriod(date) ? 'border-left' : '', isFutureDate(date) ? 'future-date' : '']">
                  <span v-if="lettering.date === date">&euro; {{ formatCurrency(lettering.total_excl_vat) }}</span>
                  <span v-else>&nbsp;</span>
                </td>
              </tr>
            </template>
            <!-- Programming and data -->
            <template v-if="Object.keys(costOverview.progr_data).length">
              <tr>
                <th v-for="item in costOverview.checkout_periods" :key="item.id" :colspan="item.total_days" :class="['border-left', item.is_future ? 'future-date' : '']">&nbsp;</th>
              </tr>
              <tr v-for="(progr_data, index) in costOverview.progr_data" :key="index" @mouseover="onMouseOver('progr_data_' + index, 'right')" @mouseleave="onMouseLeave('progr_data_' + index, 'right')" :id="'progr_data_' + index">
                <td v-for="date in costOverview.all_dates" :key="date" :class="[checkIfIsStartOfCheckoutPeriod(date) ? 'border-left' : '', isFutureDate(date) ? 'future-date' : '']">
                  <span v-if="progr_data.dates[date]">&euro; {{ formatCurrency(progr_data.dates[date]) }}</span>
                  <span v-else>&nbsp;</span>
                </td>
              </tr>
            </template>
            <!-- Hours -->
            <template v-if="Object.keys(costOverview.hours).length">
              <tr>
                <th v-for="item in costOverview.checkout_periods" :key="item.id" :colspan="item.total_days" :class="['border-left', item.is_future ? 'future-date' : '']">&nbsp;</th>
              </tr>
              <tr v-for="(hour, index) in costOverview.hours" :key="index" @mouseover="onMouseOver('hours_' + index, 'right')" @mouseleave="onMouseLeave('hours_' + index, 'right')" :id="'hours_' + index">
                <td v-for="date in costOverview.all_dates" :key="date" :class="[checkIfIsStartOfCheckoutPeriod(date) ? 'border-left' : '', isFutureDate(date) ? 'future-date' : '']">
                  <span v-if="hour.date === date">&euro; {{ formatCurrency(hour.total_excl_vat) }}</span>
                  <span v-else>&nbsp;</span>
                </td>
              </tr>
            </template>
            <!-- Additional Costs -->
            <template v-if="Object.keys(costOverview.additionalCosts).length">
              <tr>
                <th v-for="item in costOverview.checkout_periods" :key="item.id" :colspan="item.total_days" :class="['border-left', item.is_future ? 'future-date' : '']">&nbsp;</th>
              </tr>
              <tr v-for="(additional_cost, index) in costOverview.additionalCosts" :key="index" @mouseover="onMouseOver('additional_costs_' + index, 'right')" @mouseleave="onMouseLeave('additional_costs_' + index, 'right')" :id="'additional_costs_' + index">
                <td v-for="date in costOverview.all_dates" :key="date" :class="[checkIfIsStartOfCheckoutPeriod(date) ? 'border-left' : '', isFutureDate(date) ? 'future-date' : '']">
                  <span v-if="additional_cost.date === date">&euro; {{ formatCurrency(additional_cost.total_excl_vat) }}</span>
                  <span v-else>&nbsp;</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        </div>
      </template>
      <div v-else-if="costOverview.generated_at">
        <p><i>{{ t('noDataAvailable') }}</i></p>
      </div>
      <div v-else>
        <p>
          <v-progress-circular indeterminate color="primary" :size="16" width="3" class="mr-2"></v-progress-circular>
          <i>{{ t('overviewIsBeingLoaded') }}</i>
        </p>
      </div>
    </div>
  </div>

  <v-dialog
    v-model="isCheckoutPeriodDialogVisible"
    :width="checkoutPeriod.generated_at ? 1360 : 400"
    @afterLeave="closeCheckoutPeriodDialog"
    >
    <v-card>
      <template v-slot:text>
        <div id="dialog-wrapper">
          <template v-if="checkoutPeriod.generated_at">
            <table width="100%" class="mb-8">
              <thead>
                <tr>
                  <th colspan="6" class="text-normal"><i>{{ t('overviewGeneratedAt') }}: {{ formatDateTime(checkoutPeriod.generated_at) }}</i></th>
                  <th colspan="4" class="border-left">{{ checkoutPeriod.checkout_period_id > 0 ? t('checkoutPeriod') + ' ' + formatDate(checkoutPeriod.first_date) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(checkoutPeriod.last_date) : t('conceptCheckoutPeriod') + ' ' + formatDate(checkoutPeriod.first_date) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(checkoutPeriod.last_date) }}</th>
                </tr>
                <tr>
                  <th>{{ t('quantity') }}</th>
                  <th>{{ t('description') }}</th>
                  <th>{{ t('locationOrWorkOrder') }}</th>
                  <th>{{ t('rate') }}</th>
                  <th>{{ t('rateType') }}</th>
                  <th>{{ t('fullPeriod') }}</th>
                  <th class="border-left">{{ t('dateOrPeriod') }}</th>
                  <th>{{ t('duration') }}</th>
                  <th class="text-right">{{ t('amountExclVat') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!Object.keys(checkoutPeriod.equipment).length && !Object.keys(checkoutPeriod.lettering).length && !Object.keys(checkoutPeriod.progr_data).length && !Object.keys(checkoutPeriod.hours).length && !Object.keys(checkoutPeriod.additionalCosts).length">
                  <th colspan="9" class="text-normal"><i>{{ t('noDataAvailable') }}</i></th>
                </tr>
                <!-- Equipment -->
                <template v-if="checkoutPeriod.equipment && Object.keys(checkoutPeriod.equipment).length">
                  <tr>
                    <th colspan="6" class="text-normal"><i>{{ t('equipment') }}</i></th>
                    <th colspan="3" class="border-left"></th>
                  </tr>
                  <tr v-for="(equipment, index) in checkoutPeriod.equipment" :key="index">
                    <td>{{ equipment.quantity }}</td>
                    <td>{{ equipment.equipment_name }} <span v-if="equipment.unique_feature">| {{ equipment.unique_feature }}</span></td>
                    <td class="location">{{ equipment.location }}</td>
                    <td>&euro; {{ formatCurrency(equipment.rate) }}</td>
                    <td>{{ equipment.rate_type }}</td>
                    <td>{{ equipment.rent_start === equipment.rent_end ? formatDate(equipment.rent_start) : formatDate(equipment.rent_start) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(equipment.rent_end) }}</td>
                    <td class="border-left">{{ equipment.date_start === equipment.date_end ? formatDate(equipment.date_start) : formatDate(equipment.date_start) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(equipment.date_end) }}</td>
                    <td>{{ equipment.duration ? equipment.duration : '&ndash;' }}</td>
                    <td class="text-right">&euro; {{ formatCurrency(equipment.total_excl_vat) }}</td>
                  </tr>
                </template>
                <!-- Lettering -->
                <template v-if="checkoutPeriod.lettering && Object.keys(checkoutPeriod.lettering).length">
                  <tr>
                    <th colspan="6" class="text-normal"><i>{{ t('lettering') }}</i></th>
                    <th colspan="3" class="border-left"></th>
                  </tr>
                  <tr v-for="(lettering, index) in checkoutPeriod.lettering" :key="index">
                    <td>{{ lettering.quantity }}</td>
                    <td>{{ lettering.equipment_name }} <span v-if="lettering.unique_feature">| {{ lettering.unique_feature }}</span></td>
                    <td class="location">{{ lettering.location }}</td>
                    <td>&euro; {{ formatCurrency(lettering.rate) }}</td>
                    <td>{{ lettering.rate_type }}</td>
                    <td>{{ formatDate(lettering.date) }}</td>
                    <td class="border-left">{{ formatDate(lettering.date) }}</td>
                    <td>{{ lettering.duration ? lettering.duration : '&ndash;' }}</td>
                    <td class="text-right">&euro; {{ formatCurrency(lettering.total_excl_vat) }}</td>
                  </tr>
                </template>
                <!-- Programming and data -->
                <template v-if="checkoutPeriod.progr_data && Object.keys(checkoutPeriod.progr_data).length">
                  <tr>
                    <th colspan="6" class="text-normal"><i>{{ t('programmingAndData') }}</i></th>
                    <th colspan="3" class="border-left"></th>
                  </tr>
                  <tr v-for="(progr_data, index) in checkoutPeriod.progr_data" :key="index">
                    <td>{{ progr_data.quantity }}</td>
                    <td>{{ progr_data.equipment_name }} <span v-if="progr_data.unique_feature">| {{ progr_data.unique_feature }}</span></td>
                    <td class="location">{{ progr_data.location }}</td>
                    <td>&euro; {{ formatCurrency(progr_data.rate) }}</td>
                    <td>{{ progr_data.rate_type }}</td>
                    <td>{{ progr_data.rent_start === progr_data.rent_end ? formatDate(progr_data.rent_start) : formatDate(progr_data.rent_start) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(progr_data.rent_end) }}</td>
                    <td class="border-left">{{ progr_data.date_start === progr_data.date_end ? formatDate(progr_data.date_start) : formatDate(progr_data.date_start) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(progr_data.date_end) }}</td>
                    <td>{{ progr_data.duration ? progr_data.duration : '&ndash;' }}</td>
                    <td class="text-right">&euro; {{ formatCurrency(progr_data.total_excl_vat) }}</td>
                  </tr>
                </template>
                <!-- Hours -->
                <template v-if="checkoutPeriod.hours && Object.keys(checkoutPeriod.hours).length">
                  <tr>
                    <th colspan="6" class="text-normal"><i>{{ t('activities') }}</i></th>
                    <th colspan="3" class="border-left"></th>
                  </tr>
                  <tr v-for="(hour, index) in checkoutPeriod.hours" :key="index">
                    <td>{{ formatNumber(hour.hours, 2) }}</td>
                    <td>{{ hour.description }}</td>
                    <td>{{ hour.work_order_name }}</td>
                    <td>&euro; {{ formatCurrency(hour.rate) }}</td>
                    <td>{{ hour.rate_type }}</td>
                    <td>{{ formatDate(hour.date) }}</td>
                    <td class="border-left">{{ formatDate(hour.date) }}</td>
                    <td>{{ hour.duration ? hour.duration : '&ndash;' }}</td>
                    <td class="text-right">&euro; {{ formatCurrency(hour.total_excl_vat) }}</td>
                  </tr>
                </template>
                <!-- Additional Costs -->
                <template v-if="checkoutPeriod.additionalCosts && Object.keys(checkoutPeriod.additionalCosts).length">
                  <tr>
                    <th colspan="6" class="text-normal"><i>{{ t('additionalCosts') }}</i></th>
                    <th colspan="3" class="border-left"></th>
                  </tr>
                  <tr v-for="(additional_cost, index) in checkoutPeriod.additionalCosts" :key="index">
                    <td>{{ formatNumber(additional_cost.quantity, 2) }}</td>
                    <td>{{ additional_cost.description }}</td>
                    <td>-</td>
                    <td>&euro; {{ formatCurrency(additional_cost.rate) }}</td>
                    <td>{{ additional_cost.rate_type }}</td>
                    <td>{{ formatDate(additional_cost.date) }}</td>
                    <td class="border-left">{{ formatDate(additional_cost.date) }}</td>
                    <td>{{ additional_cost.duration ? additional_cost.duration : '&ndash;' }}</td>
                    <td class="text-right">&euro; {{ formatCurrency(additional_cost.total_excl_vat) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <h3 class="mb-5">
              {{ t('proposedProFormaLines') }}
            </h3>
            <table width="100%">
              <thead>
                <tr>
                  <th colspan="10">
                    <span class="text-normal"><i>{{ t('overviewGeneratedAt') }}: {{ formatDateTime(checkoutPeriod.generated_at) }}</i></span>
                    <span class="float-right ml-6">{{ checkoutPeriod.checkout_period_id > 0 ? t('checkoutPeriod') + ' ' + formatDate(checkoutPeriod.first_date) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(checkoutPeriod.last_date) : t('conceptCheckoutPeriod') + ' ' + formatDate(checkoutPeriod.first_date) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(checkoutPeriod.last_date) }}</span>
                  </th>
                </tr>
                <tr class="thead-cells-whitespace-normal">
                  <th class="text-right">{{ t('quantity') }}</th>
                  <th>{{ t('description') }}</th>
                  <th>{{ t('dateOrPeriod') }}</th>
                  <th>{{ t('duration') }}</th>
                  <th class="text-right">{{ t('pricePerUnitExclVat') }}</th>
                  <th>{{ t('primaryInternalArticle') }}</th>
                  <th>{{ t('secondaryInternalArticle') }}</th>
                  <th>{{ t('exactOnlineCostUnit') }}</th>
                  <th>{{ t('exactOnlineVatCode') }}</th>
                  <th class="text-right">{{ t('amountExclVat') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!Object.keys(checkoutPeriod.proposed_invoice_lines).length">
                  <th colspan="10" class="text-normal"><i>{{ t('noDataAvailable') }}</i></th>
                </tr>
                <tr v-for="(line, index) in checkoutPeriod.proposed_invoice_lines" :key="index">
                  <td>{{ formatNumber(line.quantity, 2) }}</td>
                  <td>{{ line.description }}</td>
                  <td>{{ line.date_start === line.date_end ? formatDate(line.date_start) : formatDate(line.date_start) + ' ' + t('upToAndIncludingAbbreviation') + ' ' + formatDate(line.date_end) }}</td>
                  <td>{{ line.duration ? line.duration : '&ndash;' }}</td>
                  <td class="text-right">&euro; {{ formatCurrency(line.price_per_unit_excl_vat) }}</td>
                  <td>{{ line.primary_internal_article_number }}</td>
                  <td>
                    <template v-if="line.secondary_internal_article_number">
                      {{ line.secondary_internal_article_number }}
                    </template>
                    <template v-else>
                      &ndash;
                    </template>
                  </td>
                  <td>
                    {{ 
                      costUnits.find(costUnit => costUnit.id === line.cost_unit_id) ?
                      costUnits.find(costUnit => costUnit.id === line.cost_unit_id).title :
                      '&ndash;'
                    }}
                  </td>
                  <td>
                    {{ 
                      vatCodes.find(vatCode => vatCode.id === line.vat_code_id) ?
                      vatCodes.find(vatCode => vatCode.id === line.vat_code_id).title :
                      '&ndash;'
                    }}
                    </td>
                  <td class="text-right">&euro; {{ formatCurrency(line.total_excl_vat) }}</td>
                </tr>
                <tr v-if="Object.keys(checkoutPeriod.proposed_invoice_lines).length">
                  <th colspan="9">{{ t('total') }}</th>
                  <th class="text-right">&euro; {{ formatCurrency(checkoutPeriod.total_excl_vat) }}</th>
                </tr>
              </tbody>
            </table>
          </template>
          <div v-else>
            <p>
              <v-progress-circular indeterminate color="primary" :size="16" width="3" class="mr-2"></v-progress-circular>
              <i>{{ t('overviewIsBeingLoaded') }}</i>
            </p>
          </div>
        </div>
      </template>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="isStartDateDialogVisible"
    width="400"
    @afterLeave="closeStartDateDialog"
    >
    <v-card
      prepend-icon="mdi-pencil"
      :title="t('updateStartDate')"
      card-text-class="pa-0"
    >
      <template v-slot:text>
        <v-row>
          <v-col sm="6">
            <div class="datepickerWrapper">
              <div class="datepickerLabel">{{ t('fromDate') }}</div>
              <div>
                <Datepicker
                  v-model="startDate"
                  :placeholder="t('dateStart')"
                  v-on:update:value-datetime="startDate = $event"
                  :teleport="true"
                  :min-date="costOverview.first_date_with_costs"
                  :max-date="costOverview.first_date_with_costs"
                  />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <div class="datepickerWrapper">
              <div class="datepickerLabel">{{ t('upToAndIncludingDate') }}</div>
              <div class="font-weight-bold">
                <template v-if="updateStartDateForCheckoutPeriod.end_date">
                  {{ formatDate(updateStartDateForCheckoutPeriod.end_date) }}
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
          <v-btn
              :text="t('save')"
              @click="updateStartDate"
          />
          <v-btn
              :text="t('cancel')"
              @click="closeStartDateDialog"
          />
      </template>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="isEndDateDialogVisible"
    width="400"
    @afterLeave="closeEndDateDialog"
    >
    <v-card
      prepend-icon="mdi-pencil"
      :title="updateEndDateForCheckoutPeriod !== null ? t('updateEndDate') : t('createCheckoutPeriod')"
      card-text-class="pa-0"
    >
      <template v-slot:text>
        <v-row>
          <v-col sm="6">
            <div class="datepickerWrapper">
              <div class="datepickerLabel">{{ t('fromDate') }}</div>
              <div class="font-weight-bold">{{ formatDate(updateEndDateForCheckoutPeriodStartDate) }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <div class="datepickerWrapper">
              <div class="datepickerLabel">{{ t('upToAndIncludingDate') }}</div>
              <div>
                <Datepicker
                  v-model="endDate"
                  :placeholder="t('dateEnd')"
                  v-on:update:value-datetime="endDate = $event"
                  :teleport="true"
                  :min-date="updateEndDateForCheckoutPeriodStartDate"
                  :max-date="getLastDate(costOverview.last_date, costOverview.last_date_with_costs)"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
          <v-btn
              :text="t('save')"
              @click="updateEndDate"
          />
          <v-btn
              :text="t('cancel')"
              @click="closeEndDateDialog"
          />
      </template>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="isRemoveCheckoutPeriodDialogVisible"
    width="400"
    @afterLeave="closeRemoveCheckoutPeriodDialog"
    >
    <v-card
      prepend-icon="mdi-delete"
      :title="t('removeCheckoutPeriod')"
      card-text-class="pa-0"
    >
      <template v-slot:text>
        <p class="mb-5" v-if="removeCheckoutPeriodForCheckoutPeriod.id">
          {{ t('period') }}:<br>
          <b>
            {{ formatDate(removeCheckoutPeriodForCheckoutPeriod.start_date) }}
            {{ t('upToAndIncludingAbbreviation') }}
            {{ formatDate(removeCheckoutPeriodForCheckoutPeriod.end_date) }}
          </b>
        </p>
        <p class="mb-5">{{ t('removeCheckoutPeriodExplanation') }}</p>
        <p>{{ t('areYouSureYouWantToRemoveTheCheckoutPeriod') }}</p>
      </template>
      <template v-slot:actions>
          <v-btn
              :text="t('remove')"
              @click="removeCheckoutPeriod"
          />
          <v-btn
              :text="t('cancel')"
              @click="closeRemoveCheckoutPeriodDialog"
          />
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, inject, watch, nextTick, computed } from 'vue';
import { fetchCheckoutPeriodsCostOverview, fetchCheckoutPeriod, updateCheckoutPeriodStartDate, updateCheckoutPeriodEndDate, removeCheckoutPeriodEndDate } from '@/api/checkoutPeriods';
import { useI18n } from 'vue-i18n'
import SayErrorResponse from '@/mixins/sayErrorResponse';
import Datepicker from '@/components/UI/Datepicker.vue'
import Say from '@/utils/Say'
import branchStore from "@/store/branches";

const { t } = useI18n()

const jobId = inject('jobId', 0)
const costOverview = ref<any>({});
const checkoutPeriod = ref<any>({});
const isCheckoutPeriodDialogVisible = ref(false);
const isEndDateDialogVisible = ref(false);
const endDate = ref(null);
const startDate = ref(null);
const updateEndDateForCheckoutPeriod = ref<any>({});
const updateEndDateForCheckoutPeriodStartDate = ref<any>(null);
const updateStartDateForCheckoutPeriod = ref<any>({});
const isStartDateDialogVisible = ref(false);
const isRemoveCheckoutPeriodDialogVisible = ref(false);
const removeCheckoutPeriodForCheckoutPeriod = ref<any>({});
const formSubmitted = ref(false);

onBeforeMount(async () => {
  await branchStore.dispatch('fetchBranches')
  loadCheckoutPeriods()
});

const loadCheckoutPeriods = async () => {
  try {
    const response = await fetchCheckoutPeriodsCostOverview(jobId.value);
    if(!response.data.generated_at) {
      SayErrorResponse(response);
    } else {
      costOverview.value = response.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const openCheckoutPeriod = async (checkoutPeriodId: number, isFuture: boolean) => {
  isCheckoutPeriodDialogVisible.value = true;
  try {
    const response = await fetchCheckoutPeriod(jobId.value, checkoutPeriodId, isFuture);
    if(!response.data.generated_at) {
      SayErrorResponse(response);
    } else {
      checkoutPeriod.value = response.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const closeCheckoutPeriodDialog = () => {
  isCheckoutPeriodDialogVisible.value = false;
  checkoutPeriod.value = {};
}

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const formatNumber = (value: number, decimals: number) => {
  if (value === null) {
    return '';
  }
  value = Number(value)
  // check if value is a rounded number
  if (value % 1 === 0) {
    return value
  } else {
    return value.toFixed(decimals).replace('.', ',')
  }
}

const showDay = (date: string) => new Date(date).toLocaleDateString('nl-NL', { weekday: 'long' })

const getActiveClass = (equipment: any, date: string) => {
  if (date >= equipment.rent_start && date <= equipment.rent_end) {
    return "active-cell";
  }
  return "";
}

const checkIfIsStartOfCheckoutPeriod = (date: string) => {
  if (costOverview.value.checkout_periods) {
    for (const item of costOverview.value.checkout_periods) {
      if (date === item.start_date) {
        return true;
      }
    }
  }
  return false;
}

const formatDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
}

const formatDateTime = (dateTime: string) => {
  const [date, time] = dateTime.split(' ');
  return `${formatDate(date)} ${t('at').toLowerCase()} ${time}`;
}

const hasNextCheckoutPeriod = (currentItem: any) => {
  const currentIndex = costOverview.value.checkout_periods.findIndex(item => item.id === currentItem.id);
  if (currentIndex === -1 || currentIndex === costOverview.value.checkout_periods.length - 1) {
    return false;
  }
  const nextItem = costOverview.value.checkout_periods[currentIndex + 1];
  return nextItem.id > 0;
}

const isFirstCheckoutPeriod = (currentItem: any) => {
  // check if the current item is the first checkout period
  return costOverview.value.checkout_periods[0].id === currentItem.id;
}

function isValidDate(dateString: string) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

const updateStartDate = () => {
  if(formSubmitted.value) {
    Say('warning', t('pleaseWait'));
    return;
  }
  formSubmitted.value = true
  if (!startDate.value || !isValidDate(startDate.value)) {
    Say('error', t('pleaseEnterAValidStartDate'));
    formSubmitted.value = false
    return;
  }
  updateCheckoutPeriodStartDate(jobId.value, updateStartDateForCheckoutPeriod.value.id, startDate.value)
    .then(response => {
      if (response.data.error) {
        SayErrorResponse(response);
      } else {
        closeStartDateDialog();
        loadCheckoutPeriods();
        Say('success', t('startDateUpdatedSuccessfully'))
      }
    })
    .catch(error => {
      SayErrorResponse(error);
    })
    .finally(() => {
      formSubmitted.value = false
    });
}

const updateEndDate = () => {
  if(formSubmitted.value) {
    Say('warning', t('pleaseWait'));
    return;
  }
  formSubmitted.value = true
  if (!endDate.value || !isValidDate(endDate.value)) {
    Say('error', t('pleaseEnterAValidEndDate'));
    formSubmitted.value = false
    return;
  }
  const id = updateEndDateForCheckoutPeriod.value ? updateEndDateForCheckoutPeriod.value.id : null;
  updateCheckoutPeriodEndDate(jobId.value, id, endDate.value)
    .then(response => {
      if (response.data.error) {
        SayErrorResponse(response);
      } else {
        closeEndDateDialog();
        loadCheckoutPeriods();
        Say('success', t('endDateUpdatedSuccessfully'))
      }
    })
    .catch(error => {
      SayErrorResponse(error);
    })
    .finally(() => {
      formSubmitted.value = false
    });
}

const openStartDateDialog = async (checkoutPeriod: any) => {
  updateStartDateForCheckoutPeriod.value = checkoutPeriod;
  isStartDateDialogVisible.value = true;
}

const closeStartDateDialog = () => {
  updateStartDateForCheckoutPeriod.value = {};
  startDate.value = null;
  isStartDateDialogVisible.value = false;
}

const openEndDateDialog = async (checkoutPeriod: any) => {
  if(checkoutPeriod) {
    updateEndDateForCheckoutPeriod.value = checkoutPeriod;
    updateEndDateForCheckoutPeriodStartDate.value = checkoutPeriod.start_date;
  } else {
    updateEndDateForCheckoutPeriod.value = null;
    // Find first date of first concept checkout period (id = NULL) if exists
    updateEndDateForCheckoutPeriodStartDate.value = costOverview.value.checkout_periods.find(item => item.id === null).start_date;
  }
  isEndDateDialogVisible.value = true;
}

const closeEndDateDialog = () => {
  updateEndDateForCheckoutPeriod.value = {};
  endDate.value = null;
  isEndDateDialogVisible.value = false;
}

const removeCheckoutPeriod = () => {
  if(formSubmitted.value) {
    Say('warning', t('pleaseWait'));
    return;
  }
  formSubmitted.value = true
  removeCheckoutPeriodEndDate(jobId.value, removeCheckoutPeriodForCheckoutPeriod.value.id)
    .then(response => {
      if (response.data.error) {
        SayErrorResponse(response);
      } else {
        closeRemoveCheckoutPeriodDialog();
        loadCheckoutPeriods();
        Say('success', t('checkoutPeriodRemovedSuccessfully'))
      }
    })
    .catch(error => {
      SayErrorResponse(error);
    })
    .finally(() => {
      formSubmitted.value = false
    });
}

const openRemoveCheckoutPeriodDialog = async (checkoutPeriod: any) => {
  removeCheckoutPeriodForCheckoutPeriod.value = checkoutPeriod;
  isRemoveCheckoutPeriodDialogVisible.value = true;
}

const closeRemoveCheckoutPeriodDialog = () => {
  removeCheckoutPeriodForCheckoutPeriod.value = {};
  isRemoveCheckoutPeriodDialogVisible.value = false;
}

const getLastDate = (lastDate: string, lastDateWithCosts: string) => {
  let date = '';
  if (lastDate > lastDateWithCosts) {
    date = lastDateWithCosts;
  } else {
    date = lastDate;
  }

  // Create a new date object for the current date
  const today = new Date();

  // Subtract one day from the current date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Convert yesterday's date to ISO format and get the date part (YYYY-MM-DD)
  const yesterdayISO = yesterday.toISOString().split('T')[0];

  // Example of how you would use it
  if (date > yesterdayISO) {
    date = yesterdayISO;
  }

  return date;
}

const isFutureDate = (date: string) => {
  // Create a new date object for the current date
  const today = new Date();

  // Subtract one day from the current date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Convert yesterday's date to ISO format and get the date part (YYYY-MM-DD)
  const yesterdayISO = yesterday.toISOString().split('T')[0];

  return date > yesterdayISO
}


const onMouseOver = (rowIndex, table) => {
  // const rowIndex = getRowIndex(event);
  if (table === 'left') {
    addHoverClass(rowIndex, 'right');
  } else {
    addHoverClass(rowIndex, 'left');
  }
}
const onMouseLeave = (rowIndex, table) => {
  // const rowIndex = getRowIndex(event);
  if (table === 'left') {
    removeHoverClass(rowIndex, 'right');
  } else {
    removeHoverClass(rowIndex, 'left');
  }
}
const addHoverClass = (rowIndex, table) => {
  const targetTable = table === 'left' ? '.table-left' : '.table-right';
  const row = document.querySelector(`${targetTable} tr#${rowIndex}`);
  if (row) {
    row.classList.add('hover');
  }
}
const removeHoverClass = (rowIndex, table) => {
  const targetTable = table === 'left' ? '.table-left' : '.table-right';
  const row = document.querySelector(`${targetTable} tr#${rowIndex}`);
  if (row) {
    row.classList.remove('hover');
  }
}

const checkScroll = () => {
  if(!document.getElementById('scrollable-container')) {
    return;
  }

  // Get the scrollable element
  const scrollableContainer = document.getElementById('scrollable-container');

  // Add an event listener for the 'scroll' event
  scrollableContainer.addEventListener('scroll', () => {
    const scrollLeft = scrollableContainer.scrollLeft;
    const scrollWidth = scrollableContainer.scrollWidth;
    const clientWidth = scrollableContainer.clientWidth;

    let tableLeft = document.querySelector(`.table-left`);
    
    // Check if it's scrolled horizontally
    if (scrollLeft > 0 && scrollLeft < (scrollWidth - clientWidth)) {
      tableLeft.classList.add('side-shadow');
    } else if (scrollLeft === 0) {
      tableLeft.classList.remove('side-shadow');
    } else if (scrollLeft === (scrollWidth - clientWidth)) {
      tableLeft.classList.add('side-shadow');
    }
  });
}

watch(() => costOverview.value, () => {
  nextTick(() => {
    checkScroll();
  });
});

const costUnits = computed(() => {
  let returnValues = []
  for (const branch of branchStore.getters.branches) {
    returnValues.push({value:branch.cost_unit, title: `${branch.cost_unit} (${branch.city})`})
  }
  return returnValues
})

// Vat codes
const vatCodes = [
  {value: '1', title: `1 (${t('vatHighExcl')})`},
  {value: '2', title: `2 (${t('vatLowExcl')})`},
  {value: '5', title: `5 (${t('vatReverseCharged')})`},
]
</script>

<style scoped>
#wrapper {
  display: block;
  height: calc(100vh - 225px);
  padding: 0 10px 25px 0;
}

#dialog-wrapper {
  overflow: auto;
}

table {
  border-collapse: collapse;
  background-color: #fff;
}

.table-container {
  position: relative;
}

.table-left-wrapper {
  position: relative;
  float: left;
  width: 700px;
  z-index: 2;
}
.table-left {
  width: 700px;
  border-right: 2px solid #808080;
}

.table-right-wrapper {
  position: relative;
  float: left;
  width: calc(100% - 700px);
  overflow: scroll;
  z-index: 1;
}

.table-right {
  margin-left: -2px;
}

.side-shadow::after {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  width: 20px;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  pointer-events: none;
}

table, td, th {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

th {
  text-align: left;
}

th, td {
  font-size: 10.5px;
  padding: 4px 8px;
  white-space: nowrap;
  position: relative;
}
.thead-cells-whitespace-normal th {
  white-space: normal;
}

tr:hover td,
tr.hover td {
  background-color: rgba(0, 0, 0, 0.2);
}

.active-cell {
  background-color: rgba(0, 0, 0, 0.07);
}

tr:hover td.active-cell,
tr.hover td.active-cell {
  background-color: rgba(0, 0, 0, 0.32);
}

.future-date {
  background: repeating-linear-gradient(
    45deg,
    #fff 0px,
    #fff 4px,
    #5BB7E3 6px,
    #5BB7E3 6px
  );
}

tr:hover td.future-date,
tr.hover td.future-date {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.2) 0px,
    rgba(0, 0, 0, 0.2) 4px,
    #5BB7E3 6px,
    #5BB7E3 6px
  );
}

.active-cell.future-date {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.07) 0px,
    rgba(0, 0, 0, 0.07) 4px,
    #5BB7E3 6px,
    #5BB7E3 6px
  );
}

tr:hover td.active-cell.future-date,
tr.hover td.active-cell.future-date {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.32) 0px,
    rgba(0, 0, 0, 0.32) 4px,
    #5BB7E3 6px,
    #5BB7E3 6px
  );
}

.description {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location {
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-normal {
  font-weight: normal;
}

.border-left {
  border-left: 2px solid rgba(0, 0, 0, 0.5);
}

.link {
  color: rgb(30, 136, 229);
  text-decoration: underline;
  font-weight: 500;
}

.link-orange {
  color: rgb(245, 120, 40);
  text-decoration: underline;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}

.link-orange[disabled] {
  color: rgba(245, 120, 40, 0.5);
  cursor: default !important;
}

.link-red {
  color: rgb(255, 0, 0);
  text-decoration: underline;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}

.link-red[disabled] {
  color: rgba(255, 0, 0, 0.5);
  cursor: default !important;
}

.continues-after-wrapper {
  position: absolute;
  top: -0.5px;
  right: -26px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 0 none;
  height: 25px;
  line-height: 1.5;
  background-color: #fff;
  overflow: hidden;
}

.continues-after {
  display: block;
  position: relative;
  height: 23px;
  background-color: rgba(0, 0, 0, 0.07);
  padding: 0 2px;
}

tr:hover .continues-after {
  background-color: rgba(0, 0, 0, 0.32);
}

.continues-after .mdi {
  position: relative;
  font-size: 20px;
  line-height: 1.2;
}

.clearfix {
  clear: both;
}
</style>
