<template>
  <v-row wrap>
    <v-col sm="12">
      <v-card flat>
        <v-card-title>
          <v-text-field
            append-icon="search"
            :label="t('search')"
            single-line
            hide-details
            v-model="search"
            variant="underlined"
          />
        </v-card-title>
      </v-card>
      <v-card flat border>
        <v-card-text class="d-block px-0 py-0">
          <v-data-table-server
            v-if="branchesList"
            :headers="headers"
            :items="branchesList"
            :items-length="branchesListTotalCount"
            :loading="branchesListLoading"
            :search="search"
            :items-per-page-text="rowsPerPageText"
            :items-per-page-options="rowsPerPageItems"
            v-model:items-per-page="pagination.rowsPerPage"
            v-model:page="pagination.page"
            @update:options="getBranches"
            @update:sort-by="sortEvent"
          >
            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span class="cursor-pointer">{{ capitalcase(header.title) }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>

            <template v-slot:item="{ item } : {item : any}">
              <tr>
                <td>
                  <router-link class="custom-a" :to="{ name: 'BranchesEdit', params: { id: item.id }}">
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'BranchesEdit', params: { id: item.id }}">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'BranchesEdit', params: { id: item.id }}">
                    {{ item.city }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'BranchesEdit', params: { id: item.id }}">
                    {{ item.cost_unit }}
                  </router-link>
                </td>
                <td class="text-right">
                  <v-btn variant="text" :to="{ name: 'BranchesEdit', params: { id: item.id }}" icon flat>
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn variant="text" v-on:click="deleteBranch(item.id)" icon flat>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { datatableOptions } from '@/mixins/datatableOptions'
import Say from '@/utils/Say'
import { ref, computed, onBeforeMount } from 'vue'
import store from '@/store'
import { branchStore } from '@/store/branches'
import { useI18n } from 'vue-i18n'
import { capitalcase } from '@/utils/filters'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const {t} = useI18n()

const search= ref('')
const headers: any = ref([
  { title: 'Id', key: 'id', sortable: true, width: '150' },
  { title: t('name', 1), key: 'name', sortable: true, width: 300 },
  { title: t('city', 1), key: 'city', width: '250', sortable: true },
  { title: t('exactOnlineCostUnit', 1), key: 'cost_unit', sortable: false },
  { key: '', sortable: false, width: 100 }
])
const branchesList = computed(() => branchStore.getters.branches)
const branchesListLoading = computed(() => branchStore.getters.branchListLoading)
const branchesListTotalCount = computed(() => branchStore.getters.branchListTotal)

const { rowsPerPageItems, rowsPerPageText, pagination } = datatableOptions()

onBeforeMount(() => {
  store.commit('app.setPageTitle', t('branch', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('branch', 2) }
  ])
})

const getBranches = () => {
  branchStore.dispatch('fetchBranches', Object.assign(
    pagination.value,
    {
      search: search.value,
    }
  ))
}

const deleteBranch = (id: any) => {
  let confirmAction = confirm(t('branchDeleteConfirm'))
  if (confirmAction) {
    branchStore.dispatch('deleteBranch', id).then(() => {
      Say('success', t('branchDeleteSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}

const sortEvent = (value: any) => {
  if(value.length === 0) {
    return
  }
  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false
}
</script>
