<script setup lang="ts">
import { approveProForma } from '@/api/proFormas';
import SayErrorResponse from '@/mixins/sayErrorResponse';
import Say from '@/utils/Say'
import {inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import { jobFilesIndex } from '@/api/jobs'

const {t} = useI18n()

const emit = defineEmits(['approved', 'cancelForm', 'loading'])

const props = defineProps({
  invoice: {
    type: Object,
    required: true
  },
  job: {
    type: Object,
    required: true
  }
})
const form = ref(null as HTMLFormElement | null)
const formValid = ref(false)
const formRules = ref({
  confirmationCheck: [
    value => {
      if (value) return true
      return t('pleaseConfirm')
    },
  ],
})
const confirmationCheck = ref(false)
const jobFiles = ref([])
const clientReference = ref('')
const attachments = ref([])
const jobId = inject('jobId', 0)

const dialogTitle = inject('dialogTitle', null)
onMounted(() => {
  dialogTitle.value = t('approveProforma')

  try {
    jobFilesIndex(jobId.value).then(response => {
      let files = response.data.uploadedFiles;
      files = files.filter(file => file.extension === 'pdf');
      // Loop through files and set a custom ID for the checkbox (if file has a workOrderId, make it wo-<workOrderId>, otherwise make it j-<fileId>)
      files.forEach(file => {
        file.custom_id = ( file.workOrderId ? `wo-` : `j-` ) + file.id;
      });

      jobFiles.value = files;
      formSubmitted.value = false
    })
  } catch (error) {
    SayErrorResponse(error);
  }

})

const formSubmitted = ref(true)
const submit = () => {
  if (form.value.validate() && !formValid.value) {
    return
  }
  
  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true
  emit('loading', true)

  approveProForma(props.invoice.id, {
    clientReference: clientReference.value,
    attachments: attachments.value
  })
    .then(response => {
      if (response.data.error) {
        SayErrorResponse(response);
        emit('loading', false)
        formSubmitted.value = false
      } else {
        setTimeout(() => {
          Say('success', t('proFormaApprovedSuccessfully'));
          emit('approved')
          formSubmitted.value = false
        }, 2000)
      }
    })
    .catch(error => {
      SayErrorResponse(error);
      formSubmitted.value = false
      emit('loading', false)
    });
}
const cancel = () => {
  emit('cancelForm')
}
defineExpose({submit, cancel})

const formatDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
}
</script>

<template>
  <div>
    <v-form
      v-model="formValid"
      ref="form"
      class="approve-proforma-form overflow-auto"
    >
      <p class="mb-5" v-if="invoice.id">
        {{ t('period') }}:<br>
        <b>
          {{ formatDate(invoice.start_date) }}
          {{ t('upToAndIncludingAbbreviation') }}
          {{ formatDate(invoice.end_date) }}
        </b>
      </p>
      <p class="mb-5" v-if="invoice.id">
        {{ t('number') }}:<br>
        <b>
          {{ invoice.number }}
        </b>
      </p>
      {{ t('confirmApproveProForma') }}

      <p class="mt-4">{{$t('toConfirm')}}:</p>

      <v-checkbox
        v-model="confirmationCheck"
        :label="t('proFormaApprovalLabel')"
        :rules="formRules.confirmationCheck"
        hide-details
      />
    </v-form>
  </div>
</template>
