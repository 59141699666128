<!--

@todo
- check creating a client from the modal
- make sure cached clients is made dirty in every case (adding location, adding client) do this mainly from store

-->
<template>
  <v-card flat>
    <v-card-text>

      <!-- Branch -->
      <v-text-field
        v-model="currentVaLocation"
        :label="$t('branch')"
        variant="underlined"
        :value="props.workOrder ? props.workOrder.location_va.city : currentVaLocation.city"
        :readonly="true"
      ></v-text-field>

      <!-- Equipment movement -->
      <v-checkbox
        v-model="isForEquipmentMovement"
        :label="$t('workOrderForEquipmentMovementBetweenBranchesAndRentalSuppliers')"
        :readonly="workOrder.id > 0 || (workOrder.job && workOrder.job.id > 0)"
        :disabled="workOrder.id > 0 || (workOrder.job && workOrder.job.id > 0)"
      ></v-checkbox>

      <!-- Category -->
      <v-autocomplete
        :label="$t('category')"
        :items="categoryOptions"
        v-model="category"
        variant="underlined"
        @change="$nextTick(() => $emit('change', { category: category }))"
        :readonly="workOrder.id > 0 && workOrder.is_for_equipment_movement && ( workOrder.equipment_placed?.length > 0 || workOrder.equipment_collected?.length > 0 )"
      ></v-autocomplete>

      <!-- Client -->
      <v-autocomplete
        v-if="!isForEquipmentMovement"
        v-model="clientId"
        :label="$t('client')"
        :loading="loadingClient"
        :items="clients"
        :search-input.sync="clientSearch"
        item-value="id"
        item-title="name"
        :prepend-icon="isClientReadonly ? '' : 'mdi-plus'"
        @click:prepend="() => { $emit('show-client-dialog') }"
        :clearable="!isClientReadonly"
        variant="underlined"
        ref="nameInput"
        :readonly="isClientReadonly"
      />

      <!-- Location to -->
      <div class="location-wrapper">
        <v-autocomplete
          v-model="locationToId"
          :items="availableLocationToItems"
          :clearable="!isLocationReadonly"
          :disabled="isLocationReadonly || (!client && !isForEquipmentMovement)"
          :label="!isForEquipmentMovement || !category ? $t('location') : ( category === 'Materiaal ophalen bij verhuur leverancier' || category === 'Materiaal ophalen bij vestiging' ? $t('pickupFromLocation') : $t('bringToLocation') )"
          :loading="loadingClient"
          item-title="location"
          item-value="id"
          :prepend-icon="isLocationReadonly || isForEquipmentMovement ? '' : 'mdi-plus'"
          variant="underlined"
          @click:prepend="() => { $emit('show-location-dialog') }"
          @change="$nextTick(() => $emit('change', { locationToId }))"
          :readonly="workOrder.id > 0 && ((workOrder.equipment_placed && workOrder.equipment_placed.length > 0) || (workOrder.equipment_collected && workOrder.equipment_collected.length > 0) )"
        />
        <MapsButton
          v-if="locationTo && !isLocationReadonly"
          :address="getMapsButtonUrl()"
          class="google-maps-button"
        />
      </div>

      <!-- Client reference -->
      <v-text-field
        v-if="!workOrder.is_for_equipment_movement"
        v-model="clientRef"
        :label="$t('clientReference')"
        :clearable="!workOrder.id && !workOrder.job_id"
        :readonly="!!(workOrder.id || workOrder.job_id)"
        ref="clientReferenceInput"
        @change="$nextTick(() => $emit('change', { clientRef }))"
        variant="underlined"
      />

    </v-card-text>

  </v-card>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash-es'
import { fetchClient } from '@/api/clients'
import Card from '@/components/UI/Card.vue'
import MapsButton from '@/components/UI/MapsButton.vue'
import { watch, computed, onMounted, onBeforeUnmount, defineComponent, ref, nextTick, readonly } from 'vue'
import store from '@/store'
import emitArray from '@/utils/emit'
import { useI18n } from 'vue-i18n'
import useBranchSelector from '@/composable/useBranchSelector'
import { useEventBus } from '@vueuse/core'
import router from '@/router'
import SayErrorResponse from "@/mixins/sayErrorResponse";

const { branchSelected } = useBranchSelector()

const createdBus = useEventBus('created');

const { t } = useI18n()
const emit = defineEmits(emitArray.value)
defineComponent({
  Card,
  MapsButton
})

const props = defineProps({
  workOrder: {
    type: Object,
    required: true
  },
  vanAmerongenLocations: {
    type: Array,
    required: true
  },
  rentalSuppliersLocations: {
    type: Array,
    required: true
  }
})

const jobName = ref(null as any)
const vaLocations = computed(() => store.getters['cache.vanAmerongenLocations'])
const currentVaLocation = computed(() => {
  return vaLocations.value.find((location: any) => location.branch_id === branchSelected.value)
})

const isForEquipmentMovement = ref(false)
// The clientId is set by the Form Input
const clientId = ref(null as any)
const clientSearch= ref('')
const clientRef= ref(null)
const locationToId= ref(null)
const locationTo= ref()
const category= ref(null as any)
const originalWorkOrder = ref(null as any)
const locationToItems = ref([] as any)
const categoryItems= ref([
  'Plaatsen verkeersmaatregelen',
  'Retour verkeersmaatregelen',
  'Verkeer regelen',
  'Opdraaien verkeersmaatregelen',
  'Afdraaien verkeersmaatregelen',
  'Wegdekreiniging',
  'Afhalen',
  'Monteur(s) + trekkend voertuig',
  'Diverse',
])
let categoryOptions = categoryItems.value

// dialog and loading states
const showClientDialog= ref(false)
const showLocationDialog= ref(false)
const loadingClient= ref(true)
const isLocationReadonly= ref(false) // waarom deze?

const isClientReadonly = computed(() => {
  return !!originalWorkOrder.value?.job?.id
})

// The list of clients in the store
const clients = computed(() => {
  const clients = store.getters['cache.clients']

  // If the client of the workOrder is not in the list of clients, then add it
  if(originalWorkOrder.value?.client && !clients.find((client: any) => client.id === originalWorkOrder.value.client.id)) {
    clients.push(originalWorkOrder.value.client)
  }

  return clients
})

// The store client that matches the set clientID
const client = computed(() => {
  // If no Client ID then there can be no client
  if(!clientId.value) return null

  // Filter from the clients in the store
  return clients.value.find((client: any) => client.id === clientId.value)
})

// Location Items that is available in the currently selected client
const clientLocationToItems = computed(() => {
  return client.value?.locations ?? [];
})

const setLocationToItems = () => {
  if(isForEquipmentMovement.value) {
    let currentCategory = category.value
    if(currentCategory === null) {
      currentCategory = props.workOrder.category
    }
    if(currentCategory) {
      if(currentCategory === 'Materiaal ophalen bij verhuur leverancier' || currentCategory === 'Materiaal terugbrengen naar verhuur leverancier') {
        locationToItems.value = ref(props.rentalSuppliersLocations)
      } else if(currentCategory === 'Materiaal ophalen bij vestiging' || currentCategory === 'Materiaal brengen naar vestiging') {
        locationToItems.value = ref(props.vanAmerongenLocations)
      }
    }
  } else {
    locationToItems.value = clientLocationToItems
  }
  // Check if the locationToId is in the new list of locations
  if(locationToItems.value.value?.length > 0) {
    // Remove location where the branch_id is the same as the selected branch
    locationToItems.value.value = locationToItems.value.value.filter((location: any) => location.branch_id !== branchSelected.value)

    const locationToIdExists = locationToItems.value.value.find((location: any) => location.id === locationToId.value)
    if(!locationToIdExists) {
      locationToId.value = null
    }
  }
}

if(branchSelected) {
  watch(branchSelected, () => {
    setLocationToItems()
  })
}

if(props.workOrder) {
  isForEquipmentMovement.value = props.workOrder.is_for_equipment_movement
  if(isForEquipmentMovement.value) {
    categoryOptions = [
      'Materiaal ophalen bij verhuur leverancier',
      'Materiaal terugbrengen naar verhuur leverancier',
      'Materiaal ophalen bij vestiging',
      'Materiaal brengen naar vestiging',
    ];
  } else {
    categoryOptions = categoryItems.value
  }
  setLocationToItems()
}

// Location Items that is available in the currently selected client
const availableLocationToItems = computed(() => {
  return locationToItems.value.value.filter((element: { id: number; location: string | string[]; latitude: any; longitude: any }) => {
    return !((element.location.includes('MISSING_LAT_LNG') || !element.latitude || !element.longitude) && (!originalWorkOrder.value.location || element.id != originalWorkOrder.value.location.id));
  })
})

createdBus.on(({ type, data }: any) => {
  // newClient is emitted  from Template . createClientLocal which in turn is called from Client . Form . submitFom
  // There is also a type === client which is emitted from Client . Form . submitFom,
  // but the new client data from there  do not have the client.id
  if (type === 'newClient') {
    clientId.value = data.id
  }
  if (type === 'location') {
    locationToId.value = data.id
  }
})

onBeforeUnmount(() => {
  createdBus.reset()
})

// set client from id (which is set by the dropdown)
if(clientId) {
  watch(clientId, (newClientId) => {
    if (newClientId) {
      refreshClientFromServer()
    } else {
      locationToId.value = null
    }
    emit('change', { clientId: clientId.value})
  }, { deep: true })
}

// set locationTo from id (which is set by the dropdown), also used by MapsButton component
if(locationToId) {
  watch(locationToId, (location) => {
    if (location) {
      locationTo.value = cloneDeep(locationToItems.value.value.find((location: any) => location.id === locationToId.value))
    } else {
      locationTo.value = null
    }

    emit('change', {locationTo: locationTo.value})
  }, { deep: true })
}

// check isForEquipmentMovement changes
if(isForEquipmentMovement) {
  watch(isForEquipmentMovement, () => {
    emit('change', { isForEquipmentMovement: isForEquipmentMovement.value })
    if(isForEquipmentMovement.value) {
      categoryOptions = [
        'Materiaal ophalen bij verhuur leverancier',
        'Materiaal terugbrengen naar verhuur leverancier',
        'Materiaal ophalen bij vestiging',
        'Materiaal brengen naar vestiging',
      ];
      category.value = null
      clientId.value = null
      locationToId.value = null
    } else {
      categoryOptions = categoryItems.value
      category.value = null
      locationToId.value = null
    }
    setLocationToItems()
  }, { deep: true })
}

// check category changes
if(category) {
  watch(category, () => {
    setLocationToItems()
    emit('change', { category: category.value })
  }, { deep: true })
}

onMounted(async() => {
  showLocationDialog.value = false
  showClientDialog.value = false
  originalWorkOrder.value = cloneDeep(props.workOrder)

  await initializeInputs()

  loadingClient.value = false
})

const initializeInputs = async() => {
  // See if there is a clients list
  // If not the reload it
  if(clients.value.length === 0) {

    // set the clients list as dirty and the re-fetch
    store.commit('cache.clients.setDirty')
    await store.dispatch('cache.clients.fetch')

    // If still no clients then there is likely an issue in the API
    if(clients.value.length === 0) {

      alert(t('workOrderEditError'))
      if(originalWorkOrder.value?.id) {
        await router.push({ name: 'WorkOrdersView', params: { id: originalWorkOrder.value.id }})
      } else {
        await router.push({ name: 'WorkOrdersList'})
      }
      return;
    }
  }

  // set the clientId if this is an edit form
  clientId.value = originalWorkOrder.value.client ? originalWorkOrder.value.client.id : null

  // Set the remainder of the form fields
  jobName.value = originalWorkOrder.value.job ? originalWorkOrder.value.job.job_name : null
  clientRef.value = originalWorkOrder.value.client_ref ? originalWorkOrder.value.client_ref : null
  category.value = originalWorkOrder.value.category ? originalWorkOrder.value.category : null

  locationToId.value = null
  if (originalWorkOrder.value.location && Number.isInteger(originalWorkOrder.value.location)) {
    locationToId.value = originalWorkOrder.value.location
  } else if (originalWorkOrder.value.location && Number.isInteger(originalWorkOrder.value.location.id)) {
    locationToId.value = originalWorkOrder.value.location.id
  }
  locationTo.value = originalWorkOrder.value.location ? originalWorkOrder.value.location : null
}

// we want to re-fetch the client locations for this client
// and update the cache, since another user could have added
// a location since we cached the clients (we do this only once)
const refreshClientFromServer = async() => {
  if (!clientId.value) return

  const response = await fetchClient(clientId.value).catch((err) => {
    SayErrorResponse(err?.response?.data?.message ?? err.message )
  })

  if (!response) { return; }

  if (response.data?.locations) {
    response.data.locations = response.data.locations.sort((a: any, b: any) => {
      const locationA = a.location.toLowerCase()
      const locationB = b.location.toLowerCase()
      if (locationA < locationB) return -1
      if (locationA > locationB) return 1
      return 0
    })
  }

  // if this is a new workOrder, without a job, we can reset the locationToId
  if(!originalWorkOrder.value.id && !originalWorkOrder.value.location) {
    locationToId.value = null
  }

  store.commit('cache.clients.update', response.data)
}

const getMapsButtonUrl = ()  => {
  return locationTo.value.latitude && locationTo.value.longitude ? locationTo.value.latitude + ',' + locationTo.value.longitude : locationTo.value.location
}

defineExpose({
  initializeInputs
});
</script>
<style scoped>
.google-maps-button {
 margin-top: 18px !important;
}
</style>
