<template>
  <v-form v-on:submit.prevent="onSubmit()" v-model="valid" ref="form" enctype="multipart/form-data">
    <div class="mb-6" v-if="equipment.id && !equipment.deletable">
      <v-alert>
        <v-icon class="opacity-50 mr-2">mdi-information</v-icon>
        {{ $t('restrictionsBecauseEquipmentIsAlreadyInEquipmentItems') }}
      </v-alert>
    </div>

    <v-row wrap>
      <v-col sm="6" >
        <v-card flat border>
          <v-card-text>

            <!-- Type -->
            <v-radio-group
              v-model="type"
              row
              :disabled="equipment.id > 0 && !equipment.deletable"
              >
              <v-radio
                :label="$t('bulk')"
                value="bulk"
              ></v-radio>
              <v-radio
                :label="$t('unique')"
                value="unique"
              ></v-radio>
              <v-radio
                :label="$t('composite')"
                value="composite"
              ></v-radio>
            </v-radio-group>

            <!-- Is consumable -->
            <v-checkbox
              v-if="type === 'bulk'"
              :label="$t('consumable')"
              v-model="is_consumable"
              @change="onInput('is_consumable', is_consumable ? 1 : 0)"
              hide-details
              variant="underlined"
              class="mb-4"
              :disabled="equipment.id > 0 && !equipment.deletable"
            ></v-checkbox>

            <!-- Name -->
            <v-text-field
              :label="$t('name', 1)"
              v-model="equipment.name"
              required
              @input="onInput('name', $event.target.value)"
              variant="underlined"
            ></v-text-field>

            <!-- Category -->
            <v-autocomplete
              :items="categories"
              v-model="equipment.category_id"
              :label="$t('category')"
              item-value="id"
              item-title="name"
              @input="onInput('category_id', $event.target.value)"
              variant="underlined"
            ></v-autocomplete>

            <!-- Parent / Base item-->
            <v-autocomplete
              v-if="type === 'bulk'"
              :items="parents"
              v-model="equipment.parent_id"
              :label="$t('equipmentParent')"
              item-value="id"
              item-title="name"
              @input="onInput('parent_id', $event.target.value)"
              variant="underlined"
              :disabled="equipment.children_count > 0 || (equipment.id > 0 && !equipment.deletable)"
              clearable
              @change="parentIdUpdated()"
            >
              <template v-slot:selection="{ item }">
                <v-list-item
                  :prepend-avatar="getBaseItemImage(item.raw.image_href)"
                  :title="item.raw.name"
                ></v-list-item>
              </template>

              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :prepend-avatar="getBaseItemImage(item.raw.image_href)"
                  :title="item.raw.name"
                ></v-list-item>
              </template>
            </v-autocomplete>

            <!-- Description -->
            <v-text-field
              :label="$t('description', 1)"
              v-model="equipment.description"
              multi-line
              auto-grow
              rows="2"
              @input="onInput('description', $event.target.value)"
              variant="underlined"
            ></v-text-field>
          </v-card-text>
        </v-card>
        <composite-equipment-card
            v-if="is_combi"
            :equipment="equipment"
            @change="updateCompositeEquipment"
            class="mt-2"
            border
        />
      </v-col>
      <v-col sm="6" >
        <v-card flat border>
          <v-card-text>
            <v-autocomplete
              :items="rateTypes"
              v-model="equipment.rate_type"
              :label="$t('rateType')"
              @input="onInput('rate_type', $event.target.value)"
              variant="underlined"
              :disabled="equipment.id > 0 && !equipment.deletable"
            ></v-autocomplete>
            <v-text-field
              :label="$t('rate')"
              v-model="equipment.rate"
              prefix="€"
              variant="underlined"
            ></v-text-field>
            <v-text-field
              :label="t('internalArticleNumber')"
              v-model="equipment.internal_article_number"
              @input="onInput('internal_article_number', $event.target.value)"
              variant="underlined"
              :disabled="equipment.id > 0 && !equipment.deletable"
            ></v-text-field>
          </v-card-text>
        </v-card>
        <v-card flat border class="mt-2">
          <v-card-text>
            <v-text-field
              :label="$t('letteringCost')"
              v-model="equipment.lettering_cost"
              prefix="€"
              clearable
              variant="underlined"
              type="number"
              step="0.01"
              min="0"
              :disabled="equipment.id > 0 && !equipment.deletable && !equipment.lettering_internal_article_number"
            ></v-text-field>
            <v-text-field
              :label="t('letteringInternalArticleNumber')"
              v-model="equipment.lettering_internal_article_number"
              @input="onInput('lettering_internal_article_number', $event.target.value)"
              variant="underlined"
              :disabled="equipment.id > 0 && !equipment.deletable"
            ></v-text-field>
          </v-card-text>
        </v-card>
        <v-card flat border class="mt-2">
          <v-card-text>
            <v-text-field
              :label="$t('programmingAndDataCosts')"
              v-model="equipment.progr_data_cost"
              prefix="€"
              clearable
              variant="underlined"
              type="number"
              step="0.01"
              min="0"
              :disabled="equipment.id > 0 && !equipment.deletable && !equipment.progr_data_internal_article_number"
            ></v-text-field>
            <v-text-field
              :label="t('programmingAndDataInternalArticleNumber')"
              v-model="equipment.progr_data_internal_article_number"
              @input="onInput('progr_data_internal_article_number', $event.target.value)"
              variant="underlined"
              :disabled="equipment.id > 0 && !equipment.deletable"
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-card flat border class="mt-2">
          <v-card-text>
            <!-- Image -->
            <v-alert type="warning" v-if="showImageWarning" class="mt-2">
              {{ $t('equipmentImageWarning') }}
            </v-alert>

            <v-file-input
              v-if="equipment.id"
              clearable
              flat
              variant="underlined"
              prepend-icon="mdi-file-image"
              @change="onImageChange"
            />

            <img v-if="equipment.image" :src="fromStorage(getEquipmentPath(equipment.image))" class="preview" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" >
        <v-card flat>
          <v-card-actions>
            <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading" :disabled="!valid">
              {{ buttonText }}
            </v-btn>
            <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import store from '@/store';
import { computed, ref } from 'vue'
import { fromStorage, getEquipmentPath } from '@/utils/filters';
import { watch } from 'vue';
import emitArray from '@/utils/emit';
import CompositeEquipmentCard from './cards/CompositeEquipmentCard.vue'
import {useI18n} from "vue-i18n";

const {t} = useI18n()
import { uploadEquipmentImage } from '@/api/equipment'
import Say from '@/utils/Say'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const props = defineProps({
  equipment: {
    type: Object,
    required: true
  },
  submit: {
    type: Function,
    required: true
  },
  onInput: {
    type: Function,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: 'Save'
  }
})

const emit = defineEmits(emitArray.value)
const valid = ref(false)
const showImageWarning = ref(false)
const rateTypes = ref([
  { value: 'WEEKLY', title: t('equipmentRateType', 0) },
  { value: '123W', title: t('equipmentRateType', 1) },
  { value: 'ONCE', title: t('equipmentRateType', 2) },
])
const is_bulk = ref(props.equipment.is_bulk == 1 ? true : false)
const is_combi = ref(props.equipment.is_combi == 1 ? true : false)
const is_consumable = ref(props.equipment.is_consumable == 1 ? true : false)
const type = ref('')

const categories = computed(() => {
  return store.getters.equipmentCategories
})
const parents = computed(() => {
  let result = store.getters.equipmentBaseItems
  // Remove current equipment from parents
  return result.filter((item: any) => item.id !== props.equipment.id)
})

const formatRate = (event: any) => {
  if (!event.target.value) {
    return
  }
  const newValue = event.target.value.replace(',', '.');
  props.onInput('rate', newValue);
}

watch(
  () => props.equipment.rate,
  (newValue) => {
    formatRate({ target: { value: newValue } });
  }
);

watch(is_bulk, () => {
  if(is_bulk.value) {
    props.equipment.is_bulk = 1
  } else {
    props.equipment.is_bulk = 0
  }
}, {immediate: true, deep: true})

const updateByType = (type: string) => {
  if(type === 'composite') {
    is_combi.value = true
    props.equipment.is_combi = 1
    is_bulk.value = true
    props.equipment.is_bulk = 1
    is_consumable.value = false
    props.equipment.is_consumable = 0
    props.equipment.parent_id = null
  } else if(type === 'bulk') {
    is_combi.value = false
    props.equipment.is_combi = 0
    is_bulk.value = true
    props.equipment.is_bulk = 1
    props.equipment.combinedEquipment = []
  } else if(type === 'unique') {
    is_combi.value = false
    props.equipment.is_combi = 0
    is_bulk.value = false
    props.equipment.is_bulk = 0
    is_consumable.value = false
    props.equipment.is_consumable = 0
    props.equipment.parent_id = null
    props.equipment.combinedEquipment = []
  }
}

if(props.equipment.id) {
  if(props.equipment.is_combi) {
    type.value = 'composite'
  } else if(props.equipment.is_bulk) {
    type.value = 'bulk'
  } else {
    type.value = 'unique'
  }
} else {
  type.value = 'bulk'
}

watch(type, () => {
  updateByType(type.value)
}, {immediate: true, deep: true})

const onImageChange = (event: any) => {
  // get file
  let selectedFile = event.target.files[0]

  var form = new FormData()
  form.append('id', props.equipment.id)
  form.append('name', selectedFile.name)
  form.append('file', selectedFile)

  uploadEquipmentImage(form).then((res) => {
      if (res.data.uploadedFile) {
        if (props.equipment.image) {
          showImageWarning.value = true
        }
        props.equipment.image = res.data.uploadedFile
        let successMessage = 'Files is uploaded.'
        Say('success', successMessage)
      }
    }).catch((err: { code: string; }) => {
      sayErrorResponse(err)
    })
}
const onSubmit = () => {
  // loop through combinedEquipment and remove the items with a quantity of 0
  if (props.equipment.combinedEquipment) {
    props.equipment.combinedEquipment = props.equipment.combinedEquipment.filter((item: any) => item.quantity > 0)
  }
  // Check if lettering_internal_article_number is set and not equal to internal_article_number
  if (props.equipment.lettering_internal_article_number && props.equipment.lettering_internal_article_number === props.equipment.internal_article_number) {
    Say('error', 'Lettering internal article number must be different from internal article number')
    return
  }
  // Check if progr_data_internal_article_number is set and not equal to internal_article_number or internal_article_number
  if (props.equipment.progr_data_internal_article_number && (props.equipment.progr_data_internal_article_number === props.equipment.internal_article_number || props.equipment.progr_data_internal_article_number === props.equipment.lettering_internal_article_number)) {
    Say('error', 'Programming and data internal article number must be different from internal article number and lettering internal article number')
    return
  }
  props.submit()
  showImageWarning.value = false

  emit('loading', false)
}
const getBaseItemImage = (image_href: any) => {
  if (image_href) {
    return fromStorage(image_href)
  }
  return '&nbsp;'
}
const updateCompositeEquipment = (data: any) => {
  props.equipment.combinedEquipment = data.combinedEquipment
}

const parentIdUpdated = () => {
  if (props.equipment.parent_id) {
    props.equipment.is_bulk = 0
    is_bulk.value = false
  }
}
</script>

<style lang="scss" scoped>
  .card {
    margin-bottom: 10px;
    margin: 5px;
  }
  .preview {
    height: 96px;
    width: auto;
    clear: both;
  }
</style>
