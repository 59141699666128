<script setup lang="ts">
import { datatableOptions } from '@/mixins/datatableOptions'
import {computed, inject, nextTick, onMounted, provide, ref, watch} from 'vue';
import useBranchSelector from '@/composable/useBranchSelector';
import {jobEquipmentItemLocationAPI as API} from '@/api/jobs/jobEquipmentItemLocation'
import { getEquipmentItem } from '@/api/equipmentItem'

const { branchSelected } = useBranchSelector()
const { rowsPerPageText, rowsPerPageItems, pagination } = datatableOptions(false)

import Place from '@/components/Jobs/View/Equipment/Place'
import Collect from '@/components/Jobs/View/Equipment/Collect'
import Rental from '@/components/Jobs/View/Equipment/Rental'
import BulkUpdate from '@/components/Jobs/View/Equipment/BulkUpdate'
import {useI18n} from 'vue-i18n'
import {cloneDeep} from 'lodash-es'
const { t } = useI18n()


const props = defineProps({
  onlyShowWorkOrderId: {
    type: Number,
    required: false
  },
  onlyShowPlacedByWorkOrderId: {
    type: Number,
    required: false
  },
  onlyShowCollectedByWorkOrderId: {
    type: Number,
    required: false
  },
  workOrderLocationId: {
    type: Number,
    required: false
  }
})

provide('workOrderId', props.onlyShowWorkOrderId)
provide('workOrderLocationId', props.workOrderLocationId)

const filterByWorkOrderId = ref()
const filterByPlacedByWorkOrderId = ref()
const filterByCollectedByWorkOrderId = ref()
const workOrderOptions = ref({})
const workOrderOptionsArray = computed(() => Object.values(workOrderOptions.value));

const equipmentItemsDialogVisible = ref(false)
const equipmentItemsDialogItems = ref({})

const jobId = inject('jobId', null)
API.setJobId(jobId.value)

const loading = ref(false)

if (props.onlyShowWorkOrderId) {
  filterByWorkOrderId.value = props.onlyShowWorkOrderId;
}
if (props.onlyShowPlacedByWorkOrderId) {
  filterByPlacedByWorkOrderId.value = props.onlyShowPlacedByWorkOrderId;
}
if (props.onlyShowCollectedByWorkOrderId) {
  filterByCollectedByWorkOrderId.value = props.onlyShowCollectedByWorkOrderId;
}

// Table control data
const totalItems = ref(1)
const search = ref('')
const headers: any = computed(() => {
  if(tableFormat.value === 0) {
    return [
      { title: '', key: 'image', width:40, sortable:false },
      { title: t('name'), key: 'name', width:150, sortable:false },
      { title: t('RentalSupplierName'), key: 'rental_supplier_name', sortable:false },
      { title: t('SystemCreated'), key: 'system_created', sortable:false, width:50  },
      { title: t('PlacedEquipmentAt'), key: 'placed_at', sortable:false },
      { title: t('PlacedByWorkOrder'), key: 'placed_by_work_order', width:140, sortable:false },
      { title: t('CollectedEquipmentAt'), key: 'collected_at', sortable:false },
      { title: t('CollectedByWorkOrder'), key: 'collected_by_work_order', width:140, sortable:false },
      { title: t('RentEquipmentStart'), key: 'rent_started_at', sortable:false },
      { title: t('RentEquipmentEnd'), key: 'rent_ended_at', sortable:false },
      { title: t('location'), key: 'location.location', sortable:false },
      { title: '', key: 'modify', width:40, sortable:false },
      { title: '', key: 'material', width:40, sortable:false },
    ]
  }
  if(tableFormat.value === 1) {
    return [
      { title: '', key: 'image', width:40, sortable:false },
      { title: t('quantity'), key: 'amount', width:45, sortable:false },
      { title: t('name'), key: 'name', width:150, sortable:false },
      { title: t('RentalSupplierName'), key: 'rental_supplier_name', sortable:false },
      { title: t('SystemCreated'), key: 'system_created', sortable:false },
      { title: t('PlacedEquipmentAt'), key: 'placed_at', sortable:false },
      { title: t('PlacedByWorkOrder'), key: 'placed_by_work_order', width:140, sortable:false },
      { title: t('CollectedEquipmentAt'), key: 'collected_at', sortable:false },
      { title: t('CollectedByWorkOrder'), key: 'collected_by_work_order', width:140, sortable:false },
      { title: t('RentEquipmentStart'), key: 'rent_started_at', sortable:false },
      { title: t('RentEquipmentEnd'), key: 'rent_ended_at', sortable:false },
      { title: t('location'), key: 'location.location', sortable:false },
      { title: '', key: 'modify', width:50, sortable:false },
      { title: '', key: 'material', width:50, sortable:false },
    ]
  }
  return []
})

const rowProps = ({item}) => {
  let classes = []
  if(item.collected_at) {
    classes.push('is-collected')
  }
  return {
    class: classes.join(' ')
  }
}

const placeDialogVisible = ref(false)
provide('placeDialogVisible', placeDialogVisible)

// Placeholder for loaded items
const items = ref([])
provide('items', items)

const selectedItem = ref({})
provide('selectedItem', selectedItem)

const selectedGroup = ref({})
provide('selectedGroup', selectedGroup)

const rentItem = ref({})
provide('rentItem', rentItem)

const rentGroup = ref({})
provide('rentGroup', rentGroup)

const selectedItems = ref([])
provide('selectedItems', selectedItems)

const bulkUpdateType = ref(null)
provide('updateType', bulkUpdateType)

const tableFormat = ref(1)
provide('tableFormat', tableFormat)

const tableData = computed(() => {
  items.value.forEach((item) => {
    if(item.isFormatted) { return }
    item.isFormatted = true
    item.originalItem = cloneDeep(item)

    item.rent_started_at = item.is_consumable ? t('notApplicableAbbr') : formatDateTime(item.rent_started_at)
    item.rent_ended_at = item.is_consumable ? t('notApplicableAbbr') : formatDateTime(item.rent_ended_at)
    item.placed_at = formatDateTime(item.placed_at)
    item.collected_at = item.is_consumable ? t('notApplicableAbbr') : formatDateTime(item.collected_at)

    if(item.system_created !== 0 && item.system_created !== '0') {
      if(item.system_created === item.total_items && !item.composite_equipment_id) {
        item.system_created = t('yes');
      } else if (item.system_created > 0) {
        item.system_created = `${item.system_created}/${item.total_items}`
      } else {
        item.system_created = '';
      }
    } else {
      item.system_created = '';
    }

    item.amount = tableFormat.value === 0 ? 1 : item.count
  })

  return items.value
})

/**
 * Load the items from the API
 */
const fetchItems = () => {
  selectedItems.value = [];
  API.index(
    {
      rowsPerPage: pagination.value.rowsPerPage,
      page: pagination.value.page,
      sortBy: pagination.value.sortBy,
      descending: pagination.value.descending,
      job_id: jobId.value,
      return_grouped: tableFormat.value,
      work_order_id: filterByWorkOrderId.value,
      placed_by_work_order_id: filterByPlacedByWorkOrderId.value,
      collected_by_work_order_id: filterByCollectedByWorkOrderId.value
    })
    .then((response) => {
      items.value = response.data.data
      totalItems.value = response.data.total
      items.value.forEach((item) => {
        if(item.placed_by_work_order_id) {
          if(!workOrderOptions.value[item.placed_by_work_order_id]) {
            workOrderOptions.value[item.placed_by_work_order_id] = {id: item.placed_by_work_order_id, name: item.placed_by_work_order}
          }
        }
        if(item.collected_by_work_order_id) {
          if(!workOrderOptions.value[item.collected_by_work_order_id]) {
            workOrderOptions.value[item.collected_by_work_order_id] = {id: item.collected_by_work_order_id, name: item.collected_by_work_order}
          }
        }
      })
      loading.value=false
    })
    .catch((error) => {
      alert(error)
    })
}

/**
 * Watch for changes in the table pagination
 */
watch([pagination], (function (){
  nextTick(() => {
    fetchItems()
  })
}), { immediate: true, deep: true})

watch([tableFormat], (function (newValue, oldValue){
  // This is to avoid triggering a refresh on initial page load
  if(oldValue.length === 0) return

  items.value = []
  selectedItems.value = []
  nextTick(() => {
    fetchItems()
  })
}), { immediate: true, deep: true})

watch([filterByWorkOrderId], (function (){
  // This is to avoid triggering a refresh on initial page load
  if(filterByWorkOrderId.value === undefined) return

  items.value = []
  selectedItems.value = []
  nextTick(() => {
    fetchItems()
  })
}), { immediate: true, deep: true})

/**
 * A Sort event updates the pagination, which will then reload the list by means of the pagination watcher
 * @param value
 */
const sortEvent = (value: any) => {
  pagination.value.sortBy = value[0]?.key
  pagination.value.descending = value[0]?.order === 'desc'
}

const setSelectedItem = (item: {}) => {
  if(tableFormat.value === 0) {
    selectedItem.value = item
    return
  }
  selectedGroup.value = item
}
const setRentItem = (item: {}) => {
  if(tableFormat.value === 0) {
    rentItem.value = item
    return
  }
  rentGroup.value = item
}
const formatDate = (dateString: string, timeSplit: string ='T') => {
  if (!dateString) return ''
  dateString = dateString.toString()
  const parts = dateString.split(timeSplit)
  const date = parts[0]
  const dateParts = date.split('-')
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
}
provide('formatDate', formatDate)

const formatDateTime = (dateTimeString: string) => {
  if (! dateTimeString) return ''
  dateTimeString = dateTimeString.toString()
  let parts = []
  if (dateTimeString.includes('T')) {
    parts = dateTimeString.split('T')
  } else {
    parts = dateTimeString.split(' ')
  }
  const date = parts[0]
  const time = parts[1] ?? null

  const dateParts = date.split('-')

  if (!time) {
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
  }

  const timeParts = time.split(':')
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${timeParts[0]}:${timeParts[1]}`
}

const openEquipmentItemsDialog = async (item: {}) => {
  equipmentItemsDialogItems.value = {}
  const ids = item.equipment_item_id.split(',')

  const equipmentItems = await Promise.all(ids.map(async (id: string | number) => {
    const response = await getEquipmentItem(id)
    return response.data.data
  }))

  equipmentItems.forEach((equipmentItem: { id: string | number; }) => {
    equipmentItemsDialogItems.value[equipmentItem.id] = equipmentItem
  })

  equipmentItemsDialogVisible.value = true
}

</script>

<template>
  <div>
    <Place @equipment-placed="fetchItems" />
    <Collect @equipment-collected="fetchItems" :workOrderId="filterByWorkOrderId" />
    <Rental @equipment-updated="fetchItems" />
    <BulkUpdate @equipment-updated="fetchItems" />
    <v-row class="mb-4">
      <v-col class="single-grouped-filter mt-6" cols="3">
        <v-chip-group
          v-model="tableFormat"
          filter
          mandatory
          selected-class="bg-blue-darken-1"
          class="align-center pa-0 mt-0 mb-0"
        >
          <v-chip key="single" size="small" flat class="mt-0 mb-0">{{t('SingleItems')}}</v-chip>
          <v-chip key="grouped" size="small" flat class="mt-0 mb-0">{{t('GroupedItems')}}</v-chip>
        </v-chip-group>
      </v-col>
      <v-col class="mt-6">
        <div v-if="!onlyShowPlacedByWorkOrderId && !onlyShowCollectedByWorkOrderId" class="filterByWorkOrder">
          <v-autocomplete
            v-if="!loading"
            v-model="filterByWorkOrderId"
            :items="workOrderOptionsArray"
            item-value="id"
            item-title="name"
            density="compact"
            :label="t('filterByWorkOrder')"
            hide-details
            clearable
            autocomplete="off"
            :disabled="!!onlyShowWorkOrderId"
            />
        </div>
      </v-col>
    </v-row>

    <div v-if="!onlyShowPlacedByWorkOrderId && !onlyShowCollectedByWorkOrderId" class="mt-3 mb-3">
      <span>{{$t('dialogBulk')}}</span>
      <v-btn :text="t('rentalStart')" :disabled="!selectedItems.length" class="ml-2 mr-2 mt-0 mb-0" variant="tonal"  @click="bulkUpdateType = 'rent_started_at'"/>
      <v-btn :text="t('rentalEnd')" :disabled="!selectedItems.length" class="mr-2 mt-0 mb-0" variant="tonal" @click="bulkUpdateType = 'rent_ended_at'"/>
      <v-btn :text="t('CollectEquipmentGroup')" :disabled="!selectedItems.length" class="mt-0 mb-0" variant="tonal" @click="bulkUpdateType = 'collect'"/>

      <template v-if="!onlyShowPlacedByWorkOrderId && !onlyShowCollectedByWorkOrderId">
        <v-btn :text="t('PlaceEquipment')" class="float-right bg-orange-darken-2 mt-0 mb-0" variant="plain"  @click="placeDialogVisible = true"/>
      </template>
    </div>
    <v-card flat rounded border>
      <v-data-table-server
      :loading="loading"
      :items-per-page-text="rowsPerPageText"
      :items-per-page-options="rowsPerPageItems"
      v-model="selectedItems"
      v-model:items-per-page="pagination.rowsPerPage"
      v-model:page="pagination.page"
      :headers="headers"
      :items="tableData"
      :items-length="totalItems"
      :row-props="rowProps"
      id="job-equipment-tab-table"
      class="table-with-custom-bg"
      :search="search"
      item-value="id"
      :show-select="!onlyShowPlacedByWorkOrderId && !onlyShowCollectedByWorkOrderId"
      item-selectable="is_not_consumable"
      @update:sort-by="sortEvent"
    >
      <template #[`item.rent_started_at`]="{item}">
        <div class="normal-opacity">
          {{ item.rent_started_at }}
        </div>
      </template>
      <template #[`item.rent_ended_at`]="{item}">
        <div class="normal-opacity">
          {{ item.rent_ended_at }}
        </div>
      </template>
      <template #[`item.image`]="{item}">
        <img v-if="item.image_url" :src="item.image_url" class="equipment-img" />
      </template>
      <template #[`item.placed_by_work_order`]="{item}">
        <router-link
          v-if="item.placed_by_work_order_id"
          :class="['custom-a', { 'highlightedWorkOrder': ( item.placed_by_work_order_id === filterByWorkOrderId || item.placed_by_work_order_id === filterByPlacedByWorkOrderId ) }]"
          :to="{ name: 'WorkOrdersView', params: { id: item.placed_by_work_order_id } }"
        >
          {{ item.placed_by_work_order }}
        </router-link>
      </template>
      <template #[`item.collected_by_work_order`]="{item}">
        <template v-if="!item.is_consumable">
          <router-link
            v-if="item.collected_by_work_order_id"
            :class="['custom-a', { 'highlightedWorkOrder': ( item.collected_by_work_order_id === filterByWorkOrderId || item.collected_by_work_order_id === filterByCollectedByWorkOrderId ) }]"
            :to="{ name: 'WorkOrdersView', params: { id: item.collected_by_work_order_id } }"
          >
            {{ item.collected_by_work_order }}
          </router-link>
        </template>
        <template v-else>
          {{ t('notApplicableAbbr') }}
        </template>
      </template>
      <template #[`item.name`]="{item}">
        <router-link
          v-if="!item.composite_equipment_id && tableFormat===0"
          class="custom-a"
          :to="{ name: 'EquipmentItemView', params: { id: item.equipment_item_id } }"
        >
          {{ item.name }}
        </router-link>
        <template v-else>
          <template v-if="tableFormat === 1">
            {{ item.name }}
          </template>
          <template v-else>
            <div
              class="custom-a"
              @click="openEquipmentItemsDialog(item)"
            >
              {{ item.name }}
            </div>
          </template>
        </template>
      </template>
      <template #[`item.modify`]="{item}">
        <v-btn v-if="!onlyShowPlacedByWorkOrderId && !onlyShowCollectedByWorkOrderId && !item.is_consumable" variant="plain" class="text-decoration-underline text-orange-darken-4 pa-0"  @click="setRentItem(item)">
          {{t('RentalStartAndEnd')}}
        </v-btn>
      </template>
      <template #[`item.material`]="{item}">
        <v-btn v-if="!item.collected_at && !onlyShowPlacedByWorkOrderId && !onlyShowCollectedByWorkOrderId && !item.is_consumable" variant="plain" class="text-decoration-underline text-orange-darken-4 pa-0" @click="setSelectedItem(item)">
          {{t('MarkAsCollected')}}
        </v-btn>
      </template>
    </v-data-table-server>
    </v-card>
  </div>
  <div v-if="!onlyShowPlacedByWorkOrderId && !onlyShowCollectedByWorkOrderId" class="mt-6">
    <div class="equipment-table-bottom-actions">
      <span>{{$t('dialogBulk')}}</span>
      <v-btn :text="t('rentalStart')" :disabled="!selectedItems.length" class="ml-2 mr-2 mt-0 mb-0" variant="tonal"  @click="bulkUpdateType = 'rent_started_at'"/>
      <v-btn :text="t('rentalEnd')" :disabled="!selectedItems.length" class="mr-2 mt-0 mb-0" variant="tonal" @click="bulkUpdateType = 'rent_ended_at'"/>
      <v-btn :text="t('CollectEquipmentGroup')" :disabled="!selectedItems.length" class="mt-0 mb-0" variant="tonal" @click="bulkUpdateType = 'collect'"/>
    </div>
  </div>

  <v-dialog
    v-model="equipmentItemsDialogVisible"
    :content-class="''"
    :max-width="'400px'"
  >
    <template v-slot:default>
      <v-card>
        <v-card-text>
          <table>
            <tbody>
              <tr v-for="item in equipmentItemsDialogItems">
                <td>
                  <img v-if="item.image_url" :src="item.image_url" class="equipment-img" />
                </td>
                <td class="text-left pl-3">
                  <router-link
                    v-if="item.id"
                    class="custom-a"
                    :to="{ name: 'EquipmentItemView', params: { id: item.id } }"
                  >
                    {{ item.equipment.name }}
                    {{ item.unique_feature ? ' | ' + item.unique_feature : '' }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="equipmentItemsDialogVisible = false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>

    <template v-slot:activator="{ props }">
      <slot v-bind="props" name="activator"></slot>
    </template>
  </v-dialog>
</template>

<style scoped lang="scss">
  .single-grouped-filter {
    flex: 0 0 200px;
    max-width: 200px;
  }
</style>

<style lang="scss">
.v-table#job-equipment-tab-table th {
  white-space: nowrap;
}
.v-table#job-equipment-tab-table tr.is-collected td {
  color: #888;
}
.v-table#job-equipment-tab-table tr.is-collected td .normal-opacity {
  color: #333 !important;
}

.equipment-img {
  max-width: 30px;
  max-height: 20px;
  display: block;
  margin: 0 auto;
}
.v-table#job-equipment-tab-table tr.is-collected td .equipment-img {
  opacity: 0.5;
}
.filterByWorkOrder {
  display: inline-block;
  margin-top: -6px;
  width: 250px;
}
.highlightedWorkOrder {
  background-color: #ffcf9e;
  padding: 3px 6px;
  border-radius: 4px;
}
.v-table#job-equipment-tab-table tr.is-collected td .highlightedWorkOrder {
  background-color: #ffe2c4;
}
.dateTime {
  min-width: 60px;
}
.custom-a {
  cursor: pointer;
}
.custom-a:hover,
.custom-a:active {
    text-decoration: underline;
    color: #1E88E5;
}
.table-with-custom-bg {
  th {
    background: var(--custom-bg) !important;
  }
}

</style>
